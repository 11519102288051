import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getAuth } from "firebase/auth";
import { useAuthContext } from "../../context/ AuthContext";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { Typography, fabClasses } from "@mui/material";
import DenseAppbar from "../../ Component/DenseAppbar";
import Divider from "@mui/material/Divider";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import star from "../../images/star.png";
import { IconButton } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import w_star from "../../images/w_star.png";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AnswerPage from "./AnswerPage";
import wakaba from "../../images/wakaba.png";
import kuroba from "../../images/kuroba.png";
import churip from "../../images/churip.png";
import sakuranbo from "../../images/sakuranbo.png";
import sakura from "../../images/sakura.png";
import BottomMenu from "../../ Component/Sub/BottomMenu";

//TODO - カテゴリーを選択して章を選択する

export default function SelectPage(props) {
  const navigate = useNavigate();
  console.log("props.magatamacount: " + props.magatama_count);
  const { user } = useAuthContext();

  const quiz_progress = props.quiz_progress;
  console.log("quiz_progress: " + JSON.stringify(quiz_progress));

  const [category_arr, set_category_arr] = useState([
    { index: "1", img: sakura, title: "建物について" },
    // { index: "2", img: sakuranbo, title: "授与品について" },
    // { index: "3", img: churip, title: "作法について" },
    // { index: "4", img: kuroba, title: "行事について" },
  ]);
  const [session_arr, set_session_arr] = useState([
    { index: "5", img: sakura, enabled: false },
    { index: "4", img: sakuranbo, enabled: false },
    { index: "3", img: churip, enabled: false },
    { index: "2", img: kuroba, enabled: false },
    { index: "1", img: wakaba, enabled: true },
  ]);

  const [session_index, SetSession_index] = useState(0);
  const [category_index, SetCategory_index] = useState(0);
  const [category_state, setCategory_State] = useState(false);

  const [state, setState] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const toggleCategory = (open, index) => {
    setCategory_State(open);
    SetCategory_index(index);
  };

  const toggleDrawer = (open, index) => {
    SetSession_index(index);
    setState(open);
  };

  const GoQuizHome = () => {
    navigate("/quiz");
  };

  useEffect(() => {}, [props.quiz_progress]);

  useLayoutEffect(() => {
    if (
      typeof props.quiz_progress !== "undefined" &&
      props.quiz_progress !== null
    ) {
      console.log(props.quiz_progress["session"]);
      let new_arr = session_arr;
      for (let i = 0; i < 4; i++) {
        //session_indexが0, 0と1を表示
        if (Number(new_arr[i]["index"]) - 1 <= props.quiz_progress["session"]) {
          //TODO -既に完了済みのクイズと、その次のクイズを色付ける
          new_arr[i]["enabled"] = true;
        }
      }
      set_session_arr(new_arr);
      console.log("new_arr: " + JSON.stringify(new_arr));
      setLoaded(true);
    } else {
      setLoaded(true);
    }
  }, []);

  if (loaded) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        direction="column"
        textAlign={"left"}
        sx={{ height: "100%" }}
      >
        <Box sx={{ height: 40 }}></Box>
        <Grid container alignItems="center" justifyContent="flex-start" pl={2}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            sx={{
              backgroundColor: "#ece9e9f2",
              height: 44,
              width: 120,
              borderRadius: 2,
            }}
          >
            <IconButton onClick={() => props.toggleDrawer(false, 0)}>
              <ArrowBackIosNewOutlinedIcon
                sx={{ color: "black", fontSize: 24 }}
              ></ArrowBackIosNewOutlinedIcon>
              <Typography sx={{ fontSize: 18, fontWeight: "bold", ml: 1 }}>
                もどる
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
        <Box sx={{ height: 40 }}></Box>

        <Grid sx={{ width: "90%" }}>
          {category_arr.map((v, i) => (
            <Grid key={i}>
              <Card>
                <CardContent
                  sx={{
                    "&:last-child": { pb: 2 },
                    width: "100%",
                  }}
                >
                  <Grid
                    item
                    container
                    spacing={1}
                    alignItems="center"
                    justify="center"
                    textAlign={"center"}
                  >
                    <Grid item xs={3}>
                      <Grid
                        sx={{
                          width: 70,
                          height: 70,
                          boxShadow: 2,
                          borderRadius: "50%",
                        }}
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Avatar src={v["img"]} sx={{ width: 70, height: 70 }} />
                      </Grid>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={8} textAlign={"left"}>
                      <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                        {v["title"]}
                      </Typography>

                      <Button
                        variant="contained"
                        sx={{
                          width: "90%",
                          height: 55,
                          backgroundColor: "#ffa94d",
                          mt: 2,
                        }}
                        onClick={() => toggleCategory(true, i)}
                      >
                        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                          選択する
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Box sx={{ height: 20 }}></Box>
            </Grid>
          ))}
        </Grid>
        {/* <Box sx={{ height: 40 }}></Box>

        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Button
            sx={{ backgroundColor: "#ece9e9f2", height: 44, width: "60%" }}
            // className={classes.bottom_button}
            onClick={() => props.toggleDrawer(false, 0)}
            variant="contained"
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
            >
              もどる
            </Typography>
          </Button>
        </Grid> */}
        <Box sx={{ height: 120 }}></Box>
        <BottomMenu value={2} />

        <Dialog
          fullScreen
          onClose={() => toggleCategory(false, 0)}
          open={category_state}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <DenseAppbar
              text="神社クイズ"
              magatama={props.magatama_count}
            ></DenseAppbar>

            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              direction="column"
              textAlign={"left"}
              mb={4}
            >
              <Box sx={{ height: 20 }}></Box>
              {/* <Box sx={{ height: 40 }}></Box>
            

            <Grid sx={{ width: "90%" }}>
              <Typography sx={{ fontSize: 18, fontWeight: "bold" }} mb={2}>
                建物について
              </Typography>
            </Grid>
            <Divider sx={{ width: "90%", backgroundColor: "black" }}></Divider> */}

              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                pl={2}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{
                    backgroundColor: "#ece9e9f2",
                    height: 44,
                    width: 120,
                    borderRadius: 2,
                  }}
                >
                  <IconButton onClick={() => props.toggleDrawer(false, 0)}>
                    <ArrowBackIosNewOutlinedIcon
                      sx={{ color: "black", fontSize: 24 }}
                    ></ArrowBackIosNewOutlinedIcon>
                    <Typography
                      sx={{ fontSize: 18, fontWeight: "bold", ml: 1 }}
                    >
                      もどる
                    </Typography>
                  </IconButton>
                </Grid>
              </Grid>
              <Box sx={{ height: 40 }}></Box>
              <Grid sx={{ width: "90%" }}>
                {session_arr.map((v, i) => (
                  <Grid key={i}>
                    <Card
                      sx={{
                        backgroundColor: v["enabled"] ? "white" : "#e6e3e3",
                      }}
                    >
                      <CardContent
                        sx={{
                          "&:last-child": { pb: 2 },
                          width: "100%",
                        }}
                      >
                        <Grid
                          item
                          container
                          spacing={0}
                          alignItems="center"
                          justify="center"
                          textAlign={"center"}
                        >
                          <Grid item xs={3}>
                            <Grid
                              sx={{
                                width: 70,
                                height: 70,
                                boxShadow: 2,
                                borderRadius: "50%",
                              }}
                              container
                              spacing={0}
                              direction="column"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Avatar
                                src={v["img"]}
                                sx={{ width: 70, height: 70 }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={1}></Grid>
                          <Grid item xs={8} textAlign={"left"}>
                            <Typography sx={{ fontSize: 18 }}>
                              第{v["index"]}章
                            </Typography>
                            {v["enabled"] && (
                              <Button
                                variant="contained"
                                sx={{
                                  width: "90%",
                                  height: 55,
                                  backgroundColor: "#ffa94d",
                                  mt: 2,
                                }}
                                onClick={() =>
                                  toggleDrawer(true, Number(v["index"]) - 1)
                                }
                              >
                                <Typography
                                  sx={{ fontSize: 16, fontWeight: "bold" }}
                                >
                                  スタート
                                </Typography>
                              </Button>
                            )}
                            {!v["enabled"] && (
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  mt: 1,
                                }}
                              >
                                {4 - i}章クリアで挑戦
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <Box sx={{ height: 20 }}></Box>
                    <BottomMenu value={2} />
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ height: 120 }}></Box>

              <Dialog
                fullScreen
                open={state}
                onClose={() => toggleDrawer(false)}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  sx={{ height: "100%" }}
                >
                  <AnswerPage
                    onClose={() => toggleDrawer(false)}
                    CloseSelectPage={props.toggleDrawer}
                    quiz_deta={props.quiz_deta}
                    category_index={category_index}
                    session_index={session_index}
                    quiz_progress={props.quiz_progress}
                    docid={props.docid}
                    magatama_count={props.magatama_count}
                    GetQuizProgress={() => props.GetQuizProgress()}
                    db_deta={props.db_deta}
                  ></AnswerPage>
                </Grid>
              </Dialog>

              <BottomMenu value={2} />
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
    );
  } else {
    return <Grid></Grid>;
  }
}
