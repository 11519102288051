import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import BottomMenu from "../ Component/Sub/BottomMenu";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import Drawer from "@mui/material/Drawer";
import ShopItems from "./Components/ShopItems";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { motion } from "framer-motion";
import axios from "axios";

//画像
import wallpaper from "../images/Room/wallpaper.png";
import wallpaper2 from "../images/Room/wallpaper2.png";
import wallpaper3 from "../images/Room/wallpaper3.png";

import room_item from "../images/Room/room_item.png";
import hatake from "../images/Room/hatake.png";
import shop from "../images/Room/shop.png";
import makimono from "../images/Room/makimono.png";
import sugegasa from "../images/Room/sugegasa.png";
import kusa from "../images/Room/kusa.png";
import magatama from "../images/magatama.png";
import ex from "../images/ex.png";
import looking_fox from "../images/looking_fox.png";
import happy_fox from "../images/happy_fox.png";
import sleepy_fox from "../images/sleepy_fox.png";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { GetFieldValues } from "../ Component/QueryHandler/DailyHandler.js";
import { useAuthContext } from "../context/ AuthContext";
import sample_wallpaper from "../images/Room/sample_wallpaper.png";

export default function KonroomHome() {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const [img_width, set_img_width] = useState(300);
  const [img_height, set_img_height] = useState(500);
  const [magatamaD, setmagatamaD] = useState(false);
  const [magatama_count, setmagatama_Count] = useState(0);
  const [shopD, set_shopD] = useState(false);
  const [random, set_random] = useState(Math.floor(Math.random() * 50) + 50);
  const [random_comment, set_random_comment] = useState(
    Math.floor(Math.random() * 9)
  );
  const [kusaD, setKusaD] = useState(false);
  const [document_id, setDocumentId] = useState("");
  const [kusa_complete, setKusa_complete] = useState(null); //草を全て除去済みかどうか
  const [kusa_count, setKusa_Count] = useState(null);
  const [pullkusa_count, setPullkusa_Count] = useState(0);
  const [show_comment, setShowComment] = useState(false);
  const [time, setTime] = useState(0);
  const [comment, setComment] = useState("");
  const [db_deta, set_db_deta] = useState(null);
  const [ex_count, set_ex_count] = useState(0);

  const [kon_img, SetKonImg] = useState(happy_fox);

  useLayoutEffect(() => {
    if (user !== null) {
      GetFirebaseDeta();
    }
    const kon_random = Math.floor(Math.random() * 3);
    console.log("kon_random" + kon_random);
    if (kon_random == 0) {
      SetKonImg(happy_fox);
    } else if (kon_random == 1) {
      SetKonImg(looking_fox);
    } else if (kon_random == 2) {
      SetKonImg(sleepy_fox);
    }
  }, []);

  const GetFirebaseDeta = async () => {
    const deta = await GetFieldValues();
    console.log("Firebase Deta" + JSON.stringify(deta));
    set_db_deta(deta);
    set_ex_count(deta["values"][0]["ex"]);
    // console.log("db_deta: " + deta["values"][0]["ex"]);
  };

  useLayoutEffect(() => {
    // console.log(window.parent.screen.width);
    set_img_width(window.innerWidth);
    set_img_height(window.innerHeight - 73);

    const today = new Date();
    const hour = today.getHours();

    if (5 <= hour && hour <= 10) {
      setTime(0);
    }
    if (10 <= hour && hour <= 16) {
      setTime(1);
    }
    if (16 <= hour && hour <= 24) {
      setTime(2);
    }
    if (0 <= hour && hour <= 5) {
      setTime(2);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    const get_baseinfo = async () => {
      // console.log("get_baseinfo was called");
      try {
        const auth = getAuth();
        const current_uid = auth.currentUser.uid;
        // console.log("current_uid: " + current_uid);
        if (isMounted) {
          // setUser_id(current_uid);
        }
        const userRef = collection(db, "account_list");

        let docid = "";
        const q = query(userRef, where("uid", "==", current_uid));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          if (isMounted) {
            setDocumentId(doc.id);
          }
          // console.log("doc id: " + doc.id);
          docid = doc.id;
        });

        TodayLogin(docid);

        const docRef = doc(db, "account_list", docid);
        const docSnap = await getDoc(docRef);
        const values = docSnap.data();

        if (typeof values["dango"] !== "undefined") {
          //マガタマの数
          if (isMounted) {
            setmagatama_Count(values["dango"]);
          }
        }
      } catch (e) {
        // console.log("error: " + e.message);
        // setAlert(true);
      }
    };

    get_baseinfo();
    return () => {
      isMounted = false;
    };
  }, []);

  const url =
    "https://sheets.googleapis.com/v4/spreadsheets/1fodIuTaDJKkFgGCHLpLSuqPFkZw8WrwOVarTgSu7fH0/values/コンの一言?key=AIzaSyAupITr3flkBqDPJk21-Wv9Qb5aP5bJisI";
  useLayoutEffect(() => {
    axios.get(url).then((res) => {
      // console.log(res.data.values);
      const values = res.data.values;
      // console.log("values: " + JSON.stringify(values));
      let arr = [];
      for (let i = 0; i < values.length; i++) {
        if (i == 0) {
          arr = [values[i][0]];
        } else {
          arr.push(values[i][0]);
        }
      }
      // console.log("arr" + JSON.stringify(arr));
      setComment(values);
    });
  }, []);

  const TodayLogin = async (docid) => {
    const today = new Date();

    try {
      const docRef = doc(db, "account_list", docid);
      const docSnap = await getDoc(docRef);
      const values = docSnap.data();

      let diffDays = null;
      //前回のログイン日を取得
      //今日の日付からログイン日を引き、その日数分雑草を生やす
      if (typeof values["login"] !== "undefined") {
        const past_loginday = values["login"].toDate();
        const diffMilliSec = today - past_loginday;
        // console.log("today: " + today);
        // console.log("past_loginday: " + past_loginday);

        diffDays = parseInt(diffMilliSec / 1000 / 60 / 60 / 60 / 24);
        console.log("前回ログインから " + diffDays + " 日経っています");
      } else {
        console.log("login not found");
        setKusa_Count([1]);
      }

      if (
        diffDays !== null &&
        // kusa_complete !== null &&
        diffDays > 0
        // !kusa_complete
      ) {
        //TODO - 前回ログインから1日以上空いているなら、草を日数分生やす
        let kusa_arr = [];
        for (let i = 0; i < diffDays; i++) {
          if (i === 0) {
            kusa_arr = [i];
          } else {
            kusa_arr.push(i);
          }
        }
        if (diffDays < 30) {
          setKusa_Count(kusa_arr);
        }
        console.log(
          "前回ログインから " + diffDays + " 日経っています、草をはやします"
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  //TODO - 雑草を全て引き抜いたらログイン日を更新する
  const UpdateLogin = async () => {
    const today = new Date();
    const docRef = doc(db, "account_list", document_id);
    const deta = { login: today };

    try {
      await updateDoc(docRef, deta);
    } catch (e) {
      console.log("error" + e);
    }
  };

  const useStyles = makeStyles((theme) => ({
    background_img: {
      position: "relative",
    },

    //キャラクターの位置
    kon_img: {
      position: "fixed",
      bottom: 100,
      // left: 20,
      // width: "45%",
      // visibility: "hidden",
    },

    hatake_img: {
      position: "absolute",
      bottom: "40%",
      right: 10,
      width: "60%",
    },
    magatama: {
      position: "fixed",
      top: 15,
      left: 15,
    },
    wall_img: {
      maxHeight: 600,
    },
    menu_container: {
      position: "fixed",
      bottom: 160,
      right: 0,
    },
    drawer_button: {
      position: "fixed",
      bottom: 30,
    },

    comment: {
      position: "fixed",
      bottom: 140,
    },

    kusa: {
      position: "fixed",
      bottom: 200 + random,
      left: 40 + random * 2,
    },

    navigate_quiz: {
      position: "fixed",
      bottom: 70,
      right: 0,
    },
  }));
  const classes = useStyles();

  const OpenShopDHandler = (open) => {
    set_shopD(open);
  };

  const KusaDHandler = (open) => {
    setKusaD(open);
  };

  const goQuiz = () => {
    navigate("/quiz");
  };

  const Kusa = (props) => (
    <Box {...props}>
      <img src={kusa} width="60" height="60" alt="kusa"></img>
    </Box>
  );

  const togglemagatamaD = (open) => {
    setmagatamaD(open);
  };

  const CheckKusaComplete = () => {
    console.log(pullkusa_count);
    console.log("kusa_count: " + kusa_count);
    if (pullkusa_count + 1 == kusa_count) {
      console.log("DBを更新します");
      setKusa_Count(0);
      //DBを更新　する
      UpdateLogin();
    } else {
      setPullkusa_Count(pullkusa_count + 1);
      console.log(pullkusa_count + 1 + "本抜きました");
    }
  };

  const showComment = () => {
    console.log("showcomment");
    setShowComment(true);
    set_random_comment(Math.floor(Math.random() * 9));
    setTimeout(() => {
      setShowComment(false);
    }, 5000);
  };

  return (
    <Grid
      container
      textAlign={"left"}
      alignItems="center"
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {/* <Box sx={{ height: 30 }}></Box> */}
        {/* <Card sx={{ width: "90%" }}>
        <CardMedia sx={{ height: 90 }} image={wall1} />
        <CardActionArea onClick={() => toggleDrawer()}></CardActionArea>
      </Card> */}
        {kusa_count !== null &&
          kusa_count !== 0 &&
          kusa_count.map((v, i) => (
            <Kusa
              onClick={() => {
                console.log("くさ　除去");
                KusaDHandler(true);
                CheckKusaComplete();

                set_random(Math.floor(Math.random() * 50) + 50);
              }}
              className={classes.kusa}
              sx={{ width: 60, height: 60, zIndex: 3 }}
              key={i}
            />
          ))}

        {user !== null && (
          <Grid
            container
            direction={"row"}
            alignItems="center"
            justifyContent={"flex-start"}
            width={200}
            className={classes.magatama}
            sx={{
              zIndex: 3,
              backgroundColor: "white",
              height: 44,
              boxShadow: 2,
              borderRadius: 4,
            }}
            pl={1}
            pr={1}
          >
            <Grid item container sx={{ width: 80 }}>
              <Box onClick={() => togglemagatamaD(true)}>
                <Grid container alignItems="center" justifyContent={"center"}>
                  <img src={magatama} height={"35"}></img>
                  <Typography
                    sx={{ fontSize: 18, color: "black", fontWeight: "bold" }}
                  >
                    {magatama_count}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            <Grid item container sx={{ width: 80 }}>
              <Box onClick={() => togglemagatamaD(true)}>
                <Grid container alignItems="center" justifyContent={"center"}>
                  <img src={ex} height={"28"}></img>
                  <Typography
                    sx={{
                      fontSize: 18,
                      color: "black",
                      fontWeight: "bold",
                      ml: 1,
                    }}
                  >
                    {db_deta !== null && db_deta["values"][0]["ex"]}
                    {db_deta == null && 0}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          justifyContent="center"
          alignItems="flex-end"
          direction={"column"}
          textAlign="center"
          className={classes.menu_container}
          sx={{ zIndex: 3, pr: 2 }}
        >
          {/* <Grid item>
          <Box
            sx={{
              width: 90,
              height: 90,
              backgroundColor: "white",
              boxShadow: 2,
              borderRadius: "50%",
            }}
            onClick={() => OpenShopDHandler(true)}
          >
            <img src={shop} width="80" height="80"></img>
            <Typography sx={{ fontSize: 13, fontWeight: "bold", mt: -2.5 }}>
              おみせ
            </Typography>
          </Box>
        </Grid> */}
          {/* <Grid item mt={3}>
            <Box
              sx={{
                width: 90,
                height: 90,
                backgroundColor: "white",
                boxShadow: 2,
                borderRadius: "50%",
              }}
              onClick={() => goQuiz()}
            >
              <img
                src={sugegasa}
                width="70"
                height="70"
                style={{
                  borderRadius: "50%",
                }}
              ></img>
              <Typography sx={{ fontSize: 13, fontWeight: "bold", mt: -2.5 }}>
                おつかい
              </Typography>
            </Box>
          </Grid> */}
        </Grid>

        <div className={classes.background_img}>
          {/* {time === 0 && (
            <img src={wallpaper} width={img_width} height={img_height} />
          )}
          {time === 1 && (
            <img src={wallpaper2} width={img_width} height={img_height} />
          )}
          {time === 2 && (
            <img src={sample_wallpaper} width={"100%"} height={"100%"} />
          )} */}

          {/* <img
            src={sample_wallpaper}
            alt="Image"
            className="scrollable-image"
          /> */}

          <Box
            className={classes.kon_img}
            onClick={() => showComment()}
            direction={"row"}
            sx={{ backgroundColor: "lightbule", zIndex: 6, width: 200 }}
          >
            <Grid
              container
              alignItems={"flex-start"}
              justifyContent={"center"}
              textAlign={"center"}
              direction={"row"}
            >
              {show_comment && (
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"center"}
                  textAlign={"center"}
                  sx={{
                    backgroundColor: "white",
                    boxShadow: 2,
                    borderRadius: 2,
                    height: 44,
                    width: 130,
                  }}
                  mb={2}
                >
                  <Typography sx={{ fontSize: 15 }}>
                    {comment[random_comment]}
                  </Typography>
                </Grid>
              )}
              <img src={kon_img} width={130} />
            </Grid>
          </Box>

          {/* <img src={hatake} className={classes.hatake_img} /> */}
        </div>

        <Drawer
          onClose={() => OpenShopDHandler(false)}
          open={shopD}
          anchor={"bottom"}
        >
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
            height={500}
          >
            <ShopItems />
            <Button
              sx={{ width: "60%", height: 40, backgroundColor: "#ece9e9f2" }}
              variant="contained"
              onClick={() => OpenShopDHandler(false)}
              className={classes.drawer_button}
            >
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
              >
                もどる
              </Typography>
            </Button>
          </Grid>
        </Drawer>
        <Dialog
          onClose={() => KusaDHandler(false)}
          open={kusaD}
          sx={{ width: "100%" }}
        >
          <Box sx={{ height: 40 }}></Box>
          <Grid p={2}>
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold" }}
              mt={2}
              mr={2}
              ml={2}
            >
              雑草を抜きました
            </Typography>
            <Box sx={{ height: 40 }}></Box>
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              textAlign={"center"}
            >
              <Button
                variant="contained"
                color="inherit"
                onClick={() => KusaDHandler(false)}
                style={{ width: "70%" }}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  もどる
                </Typography>
              </Button>
            </Grid>
            <Box sx={{ height: 40 }}></Box>
          </Grid>
        </Dialog>
        <Dialog
          onClose={() => togglemagatamaD(false)}
          open={magatamaD}
          sx={{ width: "100%" }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="column"
            textAlign={"left"}
            // sx={{ height: 300 }}
            p={1}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              direction="row"
              mt={3}
              p={1}
            >
              <Grid item alignItems="center" justifyContent="center">
                <img src={magatama} height={"35"}></img>
              </Grid>
              <Grid item alignItems="center" justifyContent="center">
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  勾玉(まがたま)を集めるには？
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ width: "100%" }}></Divider>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="flex-start"
              textAlign={"left"}
              direction={"column"}
              p={1}
              mt={2}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold", mt: 3 }}>
                1 参拝日数を記録する
              </Typography>
              <Typography sx={{ fontSize: 14, mt: 1 }}>
                　神社に参拝して、QRコードを読み込む
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: "bold", mt: 3 }}>
                ２神社クイズに挑戦する
              </Typography>
              <Typography sx={{ fontSize: 14, mt: 1 }}>
                　クイズページから神社クイズを解く
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: "bold", mt: 3 }}>
                ３実績を達成する
              </Typography>
              <Typography sx={{ fontSize: 14, mt: 1 }}>
                　実績を確認して、おマガタマを受け取る
              </Typography>

              <Box sx={{ height: 40 }}></Box>
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                direction="row"
              >
                <Grid item alignItems="center" justifyContent="center">
                  <img src={magatama} height={"35"}></img>
                </Grid>
                <Grid item alignItems="center" justifyContent="center">
                  <Typography sx={{ fontSize: 16, fontWeight: "bold", mt: 3 }}>
                    勾玉(まがたま)と引換券
                  </Typography>
                </Grid>
                <Divider sx={{ width: "100%" }}></Divider>

                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  textAlign={"left"}
                  direction={"column"}
                  p={1}
                >
                  <Typography sx={{ fontSize: 16, fontWeight: "bold", mt: 3 }}>
                    様々な特典と引き換え
                  </Typography>
                  <Typography sx={{ fontSize: 14, mt: 1 }}>
                    お名前入り御朱印などの特典と引き換えできます。
                  </Typography>
                </Grid>
              </Grid>

              <Box sx={{ height: 40 }}></Box>

              <Grid
                container
                alignItems="center"
                justifyContent="center"
                textAlign={"left"}
                direction={"column"}
              >
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => togglemagatamaD(false, 0)}
                  sx={{ width: "70%", height: 40 }}
                >
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    もどる
                  </Typography>
                </Button>
              </Grid>
              <Box sx={{ height: 40 }}></Box>
            </Grid>
          </Grid>
        </Dialog>
      </motion.div>
      <BottomMenu value={2} />
    </Grid>
  );
}
