import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import tukinamigohei from "../../images/tukinamigohei.jpg";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import looking_fox from "../../images/looking_fox.png";
import happy_fox from "../../images/happy_fox.png";

import { useNavigate, Navigate, Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import sigemi from "../../images/sigemi.png";
import prologue_wallpaper from "../../images/prologue_wallpaper.png";

export default function TukinamiGohei() {
  const navigate = useNavigate();
  const [prologueD, set_prologueD] = useState(false);
  const [story_index, set_story_index] = useState(1);

  const PrologueHandler = (open) => {
    set_prologueD(open);
    if (!open) {
      set_story_index(1);
    }
  };

  const goSignup = () => {
    navigate("/signup");
  };

  const ContentComponent = () => {
    if (story_index === 0) {
      return (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          textAlign={"center"}
          p={2}
          py={3}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            ・・・
          </Typography>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            mb={3}
          >
            <img src={sigemi} width={200} />
          </Grid>
          <Button
            variant="contained"
            sx={{ height: 55, width: "60%", backgroundColor: "#ffa94d", mb: 2 }}
            onClick={() => set_story_index(2)}
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "white" }}
            >
              近づいてみる
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{ height: 55, width: "60%", backgroundColor: "#ece9e9f2" }}
            onClick={() => {
              PrologueHandler(false);
            }}
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
            >
              離れる
            </Typography>
          </Button>
        </Grid>
      );
    } else if (story_index === 1) {
      return (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          textAlign={"center"}
          p={2}
          py={3}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            ガサガサ
          </Typography>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            mb={3}
          >
            <img src={sigemi} width={200}></img>
          </Grid>
          <Button
            variant="contained"
            sx={{ height: 55, width: "60%", backgroundColor: "#ffa94d", mb: 2 }}
            onClick={() => set_story_index(2)}
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "white" }}
            >
              近づく
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{ height: 55, width: "60%", backgroundColor: "#ece9e9f2" }}
            onClick={() => set_story_index(0)}
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
            >
              無視する
            </Typography>
          </Button>
        </Grid>
      );
    } else if (story_index === 2) {
      return (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          textAlign={"center"}
          p={2}
          py={3}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold", mb: 1 }}>
            元気のない？？がいる
          </Typography>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            mb={3}
          >
            <img src={looking_fox} width={100}></img>
          </Grid>
          <Button
            variant="contained"
            sx={{ height: 55, width: "60%", backgroundColor: "#ffa94d", mb: 2 }}
            onClick={() => set_story_index(3)}
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "white" }}
            >
              どうしたの？
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{ height: 55, width: "60%", backgroundColor: "#ece9e9f2" }}
            onClick={() => set_story_index(0)}
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
            >
              無視する
            </Typography>
          </Button>
        </Grid>
      );
    } else if (story_index === 3) {
      return (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          textAlign={"left"}
          p={2}
          py={3}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            僕はコン、大國魂神社のお使いだよ。
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: "bold", mb: 1 }}>
            でも最近、お使いが上手くいかなくて困っているんだ。
          </Typography>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            mb={3}
          >
            <img src={looking_fox} width={100}></img>
          </Grid>
          <Button
            variant="contained"
            sx={{ height: 55, width: "60%", backgroundColor: "#ffa94d", mb: 2 }}
            onClick={() => set_story_index(4)}
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "white" }}
            >
              手伝おうか？
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{ height: 55, width: "60%", backgroundColor: "#ece9e9f2" }}
            onClick={() => set_story_index(0)}
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
            >
              頑張ってね
            </Typography>
          </Button>
        </Grid>
      );
    } else if (story_index === 4) {
      return (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          textAlign="left"
          p={2}
          py={3}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            やったー！ありがとう。
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: "bold", mb: 1 }}>
            アプリの登録を済ませて、君の名前を教えてほしいな！
          </Typography>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            mb={3}
          >
            <img src={happy_fox} width={100}></img>
          </Grid>
          <Button
            variant="contained"
            sx={{ height: 55, width: "60%", backgroundColor: "#ffa94d", mb: 2 }}
            onClick={() => goSignup()}
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "white" }}
            >
              登録する
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{ height: 55, width: "60%", backgroundColor: "#ece9e9f2" }}
            onClick={() => set_story_index(0)}
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
            >
              やめる
            </Typography>
          </Button>
        </Grid>
      );
    }
  };

  return (
    <Grid
      container
      textAlign={"left"}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        textAlign={"left"}
        alignItems="center"
        justifyContent="center"
      >
        <Card
          sx={{
            width: "90%",
            // height: 260,
            // backgroundImage: `url(${prologue_wallpaper})`,
            // backgroundSize: "cover",
            // backgroundPosition: "center",
          }}
        >
          <CardContent>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{ width: "90%" }}
              >
                <Grid
                  container
                  textAlign={"center"}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    gutterBottom
                    component="div"
                    sx={{ fontSize: 18, fontWeight: "bold" }}
                    m={0.5}
                  >
                    第1章：運命の出会い
                  </Typography>
                </Grid>
                <Typography
                  gutterBottom
                  component="div"
                  sx={{ fontSize: 16, fontWeight: "bold" }}
                  m={1}
                >
                  ある日、あなたが大國魂神社に参拝していると。。。
                </Typography>
                <Grid
                  container
                  textAlign={"left"}
                  alignItems="center"
                  justifyContent="center"
                  mt={2}
                >
                  <Button
                    size="small"
                    onClick={() => PrologueHandler(true)}
                    sx={{
                      backgroundColor: "#ffa94d",
                      height: 55,
                      width: "60%",
                    }}
                    variant="contained"
                  >
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 18 }}
                    >
                      はじめる
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Dialog
        onClose={() => PrologueHandler(false)}
        open={prologueD}
        sx={{ width: "100%" }}
      >
        <ContentComponent />
      </Dialog>
    </Grid>
  );
}
