import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Fab from "@mui/material/Fab";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import { Progress } from "semantic-ui-react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import tabibito_woman from "../images/tabibito_woman.jpg";
import looking_fox from "../images/looking_fox.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AcroFormComboBox } from "jspdf";
import ShareComponent from "../ Component/Sub/ShareComponent";
import { QrReader } from "react-qr-reader";
import Drawer from "@mui/material/Drawer";
import Camera from "./Camera";
import sanpai_poster from "../images/sanpai_poster.png";
import { useAuthContext } from "../context/ AuthContext";

//参拝日数を表示するコンポーネント

export default function SanpaiDeta(props) {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const deta = props.sanpai_deta;
  let count = 0;
  if (deta[0] !== "") {
    count = deta.length;
  }
  const [daycount, Set_daycount] = useState(count);
  const [sanpai_deta, SetSanpaiDeta] = useState(deta);
  const [cameraD, set_cameraD] = useState(false);
  const [openCameraD, set_openCameraD] = useState(false);

  const cameraDHandler = (open) => {
    set_cameraD(open);
  };

  const opencameraDHandler = (open) => {
    if (user == null) {
      props.loginDopen();
      return;
    }
    set_cameraD(false);
    set_openCameraD(open);
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      textAlign={"center"}
    >
      {/* <Box sx={{ width: "90%" }} mb={2}>
        <Typography sx={{ fontSize: 16 }}>
          神社にあるQRコードを読み込んで記録しましょう
        </Typography>
      </Box> */}
      <Card sx={{ width: "90%" }}>
        <CardContent sx={{ "&:last-child": { pb: 2 } }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            textAlign={"left"}
          >
            <Grid item xs={6}>
              <Grid container alignItems="center" justifyContent="center">
                <img src={looking_fox} height="100"></img>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              container
              alignItems="flex-start"
              justifyContent="center"
            >
              <Box sx={{ width: 130, height: 130 }}>
                <CircularProgressbar
                  value={daycount}
                  text={`${daycount}日目`}
                  styles={buildStyles({
                    // Text size
                    textSize: "24px",
                    textColor: "black",
                    trailColor: "#d6d6d6",
                    pathColor: "#ffa94d",
                  })}
                />
              </Box>
            </Grid>
          </Grid>
          <Typography sx={{ fontSize: 17 }} variant="body2" mt={1}>
            お百度参りまであと {100 - daycount}日
          </Typography>
          <Box sx={{ height: 20 }}></Box>
          <Grid container alignItems="center" justifyContent="center">
            <Button
              variant="contained"
              style={{
                backgroundColor: "#ffa94d",
                color: "white",
                width: "60%",
                height: 55,
              }}
              onClick={() => cameraDHandler(true)}
            >
              <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                参拝を記録する
              </Typography>
            </Button>
          </Grid>
          <Box sx={{ height: 5 }}></Box>
        </CardContent>
      </Card>
      <Box sx={{ height: 20 }}></Box>
      {/* <ShareComponent count={daycount} /> */}

      <Dialog
        onClose={() => cameraDHandler(false)}
        open={cameraD}
        sx={{ width: "100%" }}
      >
        <Grid sx={{ height: "90%" }}>
          <Box sx={{ height: 20 }}></Box>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            textAlign={"left"}
          >
            <img src={sanpai_poster} width="80%"></img>

            <Typography sx={{ fontSize: 16, fontWeight: "bold" }} m={2}>
              神社にある、「緑色のポスター」のQRコードを読み込んでください
            </Typography>
          </Grid>

          <Box sx={{ height: 20 }}></Box>

          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Box sx={{ height: 20 }}></Box>

            <Button
              variant="contained"
              onClick={() => opencameraDHandler(true)}
              sx={{ width: "70%", height: 40, backgroundColor: "#ffa94d" }}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                カメラをひらく
              </Typography>
            </Button>
            <Box sx={{ height: 20 }}></Box>

            <Button
              variant="contained"
              color="inherit"
              onClick={() => cameraDHandler(false)}
              sx={{ width: "70%", height: 40 }}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                もどる
              </Typography>
            </Button>
            <Box sx={{ height: 60 }}></Box>
          </Grid>
        </Grid>
      </Dialog>

      <Drawer
        onClose={() => opencameraDHandler(false)}
        open={openCameraD}
        anchor={"bottom"}
      >
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
          height={500}
        >
          <Camera back={() => opencameraDHandler(false)} type="sanpai"></Camera>
        </Grid>
      </Drawer>
    </Grid>
  );
}
