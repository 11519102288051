import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import pic from "../images/login.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate, Navigate, Link } from "react-router-dom";
import {
  collection,
  collectionGroup,
  addDoc,
  doc,
  query,
  getDocs,
  where,
  getDoc,
} from "firebase/firestore";
import { db, app } from "../firebase.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Contact from "./Sub/Contact.js";
import ScrollTop from "../helper/ScrollTop.js";

import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import torii from "../images/torii.png";
import oonusa from "../images/oonusa.png";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import BottomMenu from "../ Component/Sub/BottomMenu";

const Thanks = (props) => {
  const navigate = useNavigate();
  const [number, setNumber] = useState("");

  const onSubmit = (action) => {
    if (action === "back") {
      props.handleBack();
    } else if (action === "next") {
      navigate("/");
    }
  };

  useEffect(() => {
    getinfo();
  }, []);

  const getinfo = async () => {
    let docId = "";
    const auth = getAuth();
    const current_uid = auth.currentUser.uid;
    // console.log("Current userID: " + current_uid);
    const userRef = collection(db, "account_list");
    //コレクションから条件クエリし、refを取得
    const q = query(userRef, where("uid", "==", current_uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // console.log("user doc id: " + doc.id);
      docId = doc.id;
    });

    //電話番号とメアドを取得
    //メアドと電話番号を取得
    const docRef = doc(db, "account_list", docId);
    const docSnap = await getDoc(docRef);
    const values = docSnap.data();
    // console.log("email: " + values["email"]);
    // console.log("phone: " + values["phone"]);
    const phone_str = String(values["phone"]);
    const str_number = phone_str.substring(phone_str.length - 4);
    setNumber(str_number);
  };

  const CustomisedBottomNavigationAction = (props) => (
    <BottomNavigationAction sx={{ minWidth: "70px" }} {...props} />
  );

  return (
    <Grid>
      <ScrollTop />

      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"left"}
        // backgroundColor="#FFD36E"
      >
        <Box sx={{ height: 10 }}></Box>
        <img src={pic} alt="picture" style={{ width: "80%" }} />
        <Box sx={{ height: 10 }}></Box>

        <Card sx={{ width: "80%" }}>
          <CardContent>
            <div>
              <Typography
                sx={{
                  mt: 1.1,
                  fontSize: 18,
                  fontWeight: "bold",
                  backgroundColor: "#ffa94d",
                }}
              >
                受付番号: {number}
              </Typography>
            </div>
            <Typography sx={{ mt: 1.1, fontSize: 16, fontWeight: "bold" }}>
              当日、社務所にて「受付番号」をお伝え下さいませ。
            </Typography>
            <Typography sx={{ mt: 1.1, fontSize: 16, fontWeight: "bold" }}>
              ※電話番号の下4桁になります。
            </Typography>

            <Typography sx={{ mt: 1.1, fontSize: 16, fontWeight: "bold" }}>
              お気を付けてご参拝ください。
            </Typography>
          </CardContent>
        </Card>
        <Box sx={{ height: 10 }}></Box>
        <Card sx={{ width: "80%" }}>
          <CardContent>
            <Typography sx={{ mt: 1.1, fontSize: 16 }}>
              ご連絡先のメールアドレスに予約内容を送信しました。
            </Typography>
            <Typography sx={{ mt: 1.1, fontSize: 16 }}>
              予約日時の変更やキャンセルをする場合は、 0246-34-0092
              (9時〜16時)にお問い合わせ下さい。
            </Typography>
          </CardContent>
        </Card>

        <Contact></Contact>
        <Box sx={{ height: 120 }}></Box>
      </Grid>

      <BottomMenu value={8} />
    </Grid>
  );
};

export default Thanks;
