import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { EasyApplyData } from "./EasyApplyPage.js";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import Negai from "./Sub/Negai.js";
import Ofuda from "./Sub/Ofuda.js";
import { useNavigate, Navigate, Link } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TextField from "@mui/material/TextField";
import OfudaImageList from "./Sub/OfudaImageList.js";

const Easyapply1 = (props) => {
  const { currentState, setCurrentState } = useContext(EasyApplyData);
  const defaultCheckedValues = ["B"];
  const { register, control, handleSubmit, getValues, setValues, setValue } =
    useForm({ defaultValues: { num: "1", biko: "" } });
  const { fields, append, remove, replace, insert, update } = useFieldArray({
    name: "apply",
    control,
  });

  const [num, setNum] = useState("");

  //種類
  const [select_type, setSelect_type] = useState("種目1");
  //料金
  const [select_money, setselect_money] = useState("五千円（普通）");

  const accounts = props.accounts;

  // console.log("EasyApply1 props.accounts: " + JSON.stringify(accounts));
  const [data, setData] = useState([""]);
  // const [phone_value, setPhoneValue] = useState("");
  // const [email_value, setEmailValue] = useState("");
  const [NegaiD, SetnegaiD] = useState(false);
  const [OfudaD, SetofudaD] = useState(false);
  const [retryD, SetretryD] = useState(false);

  const [input_pray1, setInputPray1] = useState("家内安全");
  const [input_pray2, setInputPray2] = useState("身体堅固");

  const [firsttime, setFirsttime] = useState(true);

  let field_index_arr = [];

  // useEffect(() => {
  // }, [input_pray1, input_pray2, select_money]);

  let checked = false;

  const navigate = useNavigate();

  useLayoutEffect(() => {
    setNum(props.accounts.length);
    //代表者の電話番号を取得したい
    // for (let i = 0; i < props.accounts.length; i++) {
    //   if ("phone" in props.accounts[i]) {
    //     setPhoneValue(props.accounts[i].phone);
    //   }
    //   if ("email" in props.accounts[i]) {
    //     setEmailValue(props.accounts[i].email);
    //   }
    // }
    const values = [];
    console.log("num: " + props.accounts.length);
    const form_data = getValues();
    console.log("field value -> " + JSON.stringify(form_data));
    for (let i = 0; i < props.accounts.length; i++) {
      const value = { selected: false, index: i };
      values.push(value);
    }
    setData(values);

    console.log("data: " + JSON.stringify(data));
  }, []);

  console.log("--------------Rendered--------------------");

  useEffect(() => {
    if (props.accounts == "") {
      navigate("/");
    }
  }, []);

  const handleCheck = (e, index) => {
    console.log("index: " + index);

    data[index]["selected"] = e.target.checked;

    //空辞書を削除する
    const unformated_data = getValues();
    console.log("unformated_data: " + JSON.stringify(unformated_data));
    const formated_data = deleteEnpty(unformated_data.apply);
    replace(formated_data);
    console.log("format_data: " + JSON.stringify(formated_data));

    //選択した時
    if (e.target.checked) {
      checked = true;
      const before_data = getValues();
      console.log("before_data: " + JSON.stringify(before_data));

      if (index === 0) {
        insert(0, {
          index: index,
          pray1: input_pray1,
          pray2: input_pray2,
          money: select_money,
        });
      } else {
        insert(index, {
          index: index,
          pray1: input_pray1,
          pray2: input_pray2,
          money: select_money,
        });
      }

      console.log("追加しました");

      const after_data = getValues();
      console.log("after_data: " + JSON.stringify(after_data));
    } else {
      //非選択の時
      console.log("削除します");
      const before_data = getValues();
      console.log("before_data: " + JSON.stringify(before_data));
      checked = false;

      if (index === 0) {
        remove(0);
      } else {
        //indexで検索して、その辞書を削除
        let remove_index;
        //replaceで置き換え
        for (let i = 0; i < before_data.apply.length; i++) {
          if (index === before_data.apply[i].index) {
            console.log("index hit!");
            remove_index = i;
          }
        }
        before_data.apply.splice(remove_index, 1);
        console.log(remove_index + " 番目を削除");
        replace(before_data.apply);
      }
      console.log("削除しました");
      const after_data = getValues();
      console.log("after_data: " + JSON.stringify(after_data));
    }
  };

  const goHome = () => {
    navigate("/kigan");
  };

  const useStyles = makeStyles({
    startIcon: {
      position: "absolute",
      left: "1rem",
    },
  });

  const deleteEnpty = (dic) => {
    let format_data = [];
    // console.log("dic: " + JSON.stringify(dic));
    // console.log("dic: " + JSON.stringify(dic[0]));
    //空の配列を削除する
    if (dic != null && dic != undefined) {
      for (let i = 0; i < dic.length; i++) {
        if (typeof dic[i] === "object" && dic[i] !== null) {
          if (Object.keys(dic[i]).length === 4) {
            format_data.push(dic[i]);
            // console.log("dic[i]: " + JSON.stringify(dic[i]));
            // console.log("空要素がありませんでした");
          } else {
            // console.log("空要素がありました");
          }
        }
      }
    }

    console.log("format_data: " + format_data);

    return format_data;
  };

  function isEmpty(obj) {
    return !Object.keys(obj).length;
  }

  const onSubmit = (action) => {
    //formデータとアカウント情報を紐付ける
    //フォームのデータ
    const num = fields.length;
    console.log("選択した人数-> " + num);
    const deta = getValues();
    const form_data = deleteEnpty(deta.apply);
    console.log("form_data: " + JSON.stringify(form_data));

    //もし選択してなかったら
    if (form_data.length > 0) {
      console.log("ある");
    } else {
      console.log("ない");
      console.log(checked);
      SetretryD(true);
      return;
    }

    console.log("form_data = " + JSON.stringify(form_data));
    const selected_index = [];

    //選択したアカウント分、紐付ける
    console.log("form_data.apply.length: " + form_data.length);
    for (let i = 0; i < form_data.length; i++) {
      selected_index.push(form_data[i].index);
    }

    console.log("selected index: " + selected_index);

    //currentStateに登録する情報
    let state_data;

    for (let i = 0; i < selected_index.length; i++) {
      //選択したアカウントの基本情報
      const account_data = [accounts[selected_index[i]]];
      console.log("account_data->" + JSON.stringify(account_data));
      account_data.push(form_data[i]);
      console.log("form_data: " + JSON.stringify(form_data[i]));
      if (i === 0) {
        state_data = [account_data];
      } else {
        state_data.push(account_data);
      }
    }

    console.log("state_data => " + JSON.stringify(state_data));
    console.log("!!!!!!!!!! deta.biko: " + JSON.stringify(deta.biko));

    let biko_text = "";
    if (deta.biko !== "") {
      biko_text = deta.biko;
    }

    setCurrentState({
      ...currentState,
      num: num,
      biko: biko_text,
      EasyApply1: state_data,
    });
    console.log("currentState =  " + JSON.stringify(currentState));

    if (action === "back") {
      props.goHome();
    } else if (action === "next") {
      props.handleNext();
    }
  };

  const setInputValue = (type, account_index, value) => {
    if (type === "money") {
      const data = getValues();
      let set_index;
      for (let i = 0; i < data.apply.length; i++) {
        //account_indexとフォームデータのindexが同じなら
        if (data.apply[i].index === account_index) {
          set_index = i;
        }
      }
      setValue(`apply.${set_index}.money`, value);
    } else if (type === "pray1") {
    } else if (type === "pray2") {
    }
  };

  const menuItems = [
    {
      label: "選択しない",
      value: "選択しない",
    },

    {
      label: "身体堅固",
      value: "身体堅固",
    },
    {
      label: "家内安全",
      value: "家内安全",
    },
    {
      label: "交通安全",
      value: "交通安全",
    },
    {
      label: "社内安全",
      value: "社内安全",
    },
    {
      label: "商売繁盛",
      value: "商売繁盛",
    },
    {
      label: "當病平癒",
      value: "當病平癒",
    },
    {
      label: "厄災悉除",
      value: "厄災悉除",
    },
    {
      label: "良縁成就",
      value: "良縁成就",
    },
    {
      label: "学芸伸長",
      value: "学芸伸長",
    },
    {
      label: "開運招福",
      value: "開運招福",
    },
  ];

  const money_menu = [
    {
      label: "三千円（普通）",
      value: "三千円（普通）",
    },
    {
      label: "三千円（小）",
      value: "三千円（小）",
    },
    {
      label: "五千円（普通）",
      value: "五千円（普通）",
    },
    {
      label: "五千円（小）",
      value: "五千円（小）",
    },
    {
      label: "壱万円（普通）",
      value: "壱万円（普通）",
    },
    {
      label: "壱万円（小）",
      value: "壱万円（小）",
    },
  ];

  //説明用
  //願いの項目
  const negaiDOpen = () => {
    SetnegaiD(true);
  };
  const negaiDClose = () => {
    SetnegaiD(false);
  };
  //おふだ
  const ofudaDOpen = () => {
    SetofudaD(true);
  };
  const ofudaDClose = () => {
    SetofudaD(false);
  };

  const retryDClose = () => {
    SetretryD(false);
  };

  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"left"}
    >
      <Box sx={{ height: 10 }}></Box>
      <Card sx={{ width: "90%" }} style={{ border: "3px solid #fad4ab" }}>
        <CardContent>
          <Grid>
            <Typography
              sx={{ mt: 1.1, fontSize: 16, fontWeight: "bold" }}
              variant="body2"
            >
              ・願いの項目は2つ選ぶことができ、おふだに名前・住所と共に書かれます。
            </Typography>
            <Typography
              sx={{ mt: 1.1, fontSize: 16, fontWeight: "bold" }}
              variant="body2"
            >
              ・神棚がない方はおふだの(小)、お持ちの方は(普通)サイズがおすすめです。
            </Typography>
          </Grid>
        </CardContent>
      </Card>
      <Box sx={{ height: 15 }}></Box>

      <Button
        color="secondary"
        aria-label="add"
        variant="contained"
        onClick={() => negaiDOpen()}
        style={{
          backgroundColor: "#e4e3e3",
          color: "black",
          minWidth: "200px",
          minHeight: "40px",
          width: "90%",
        }}
        classes={{
          startIcon: classes.startIcon,
        }}
        startIcon={<HelpOutlineIcon color="black" />}
      >
        <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
          願いの項目の読み方・意味
        </Typography>
      </Button>
      <Box sx={{ height: 10 }}></Box>
      <Typography sx={{ fontSize: 14 }} mt={1}>
        ※タップすると拡大できます
      </Typography>
      <OfudaImageList></OfudaImageList>

      <Box sx={{ height: 20 }}></Box>

      <Divider style={{ width: "100%" }} />
      <Box sx={{ height: 20 }}></Box>

      <Grid>
        <Grid container justify="flex-start" alignItems="flex-start">
          <CheckCircleOutlineIcon
            style={{ color: "#ff7043", fontSize: 30 }}
          ></CheckCircleOutlineIcon>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }} ml={1}>
            ご祈祷する人を選択してください
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ height: 20 }}></Box>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"center"}
        pr={3}
      >
        <form>
          {accounts != "" &&
            accounts.map((data, i) => (
              <Grid container key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      name={`apply[${i}]`}
                      onChange={(e) => handleCheck(e, i)}
                      style={{ paddingRight: "10px" }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                      {accounts[i]["name"] + " 様"}
                    </Typography>
                  }
                />
                <Box sx={{ height: 40 }}></Box>
              </Grid>
            ))}
        </form>
      </Grid>

      <Box sx={{ height: 40 }}></Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        {accounts != "" &&
          accounts.map(
            (value, i) =>
              //選択されていた時のみ表示させたい
              data[0] !== "" &&
              data[i]["selected"] == true && (
                <Box key={i}>
                  <Grid>
                    <Box sx={{ backgroundColor: "#f7ad6c" }} p={1} mb={2}>
                      <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                        {value["name"]}様
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: 18 }}>
                      願いの項目1つ目
                    </Typography>
                    <Grid>
                      <Controller
                        {...register(`apply.${i}.pray1`)}
                        control={control}
                        ref={null}
                        render={({ field }) => (
                          <div>
                            <TextField
                              select
                              defaultValue="家内安全"
                              fullWidth
                              sx={{ width: 250 }}
                              onChange={(e) => {
                                // setValue(`apply.${i}.pray1`, e.target.value);
                                const unformated_data = getValues();
                                console.log(
                                  "unformated_data: " +
                                    JSON.stringify(unformated_data)
                                );
                                const formated_data = deleteEnpty(
                                  unformated_data.apply
                                );
                                replace(formated_data);
                                console.log(
                                  "format_data: " +
                                    JSON.stringify(formated_data)
                                );
                                setInputPray1(e.target.value);
                                console.log("i: " + i);
                                const before_data = getValues();

                                checked = false;

                                //indexで検索して、その辞書を更新したい
                                let update_index;
                                //replaceで置き換え
                                for (
                                  let n = 0;
                                  n < before_data.apply.length;
                                  n++
                                ) {
                                  if (i === before_data.apply[n].index) {
                                    console.log(
                                      "index hit!" + n + " 番目を更新"
                                    );
                                    update_index = n;
                                  }
                                }

                                update(update_index, {
                                  index: i,
                                  pray1: e.target.value,
                                  pray2: input_pray2,
                                  money: select_money,
                                });

                                setFirsttime(false);
                                const v = getValues();
                                console.log("v: " + JSON.stringify(v));
                              }}
                            >
                              {menuItems.map(({ label, value }, index) => (
                                <MenuItem key={index} value={value}>
                                  <Typography
                                    sx={{
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    {label}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        )}
                      />
                    </Grid>
                    <Box sx={{ height: 30 }}></Box>
                    <Typography sx={{ fontSize: 18 }}>
                      願いの項目2つ目
                    </Typography>

                    <Grid>
                      <Controller
                        {...register(`apply.${i}.pray2`)}
                        control={control}
                        ref={null}
                        render={({ field }) => (
                          <div>
                            <TextField
                              select
                              defaultValue="身体堅固"
                              fullWidth
                              sx={{ width: 250 }}
                              onChange={(e) => {
                                // setValue(`apply.${i}.pray2`, e.target.value);
                                const unformated_data = getValues();
                                console.log(
                                  "unformated_data: " +
                                    JSON.stringify(unformated_data)
                                );
                                const formated_data = deleteEnpty(
                                  unformated_data.apply
                                );
                                replace(formated_data);
                                console.log(
                                  "format_data: " +
                                    JSON.stringify(formated_data)
                                );

                                setInputPray2(e.target.value);
                                console.log("i: " + i);
                                const before_data = getValues();
                                console.log(
                                  "before_data: " + JSON.stringify(before_data)
                                );
                                checked = false;

                                //indexで検索して、その辞書を更新したい
                                let update_index;
                                //replaceで置き換え
                                for (
                                  let n = 0;
                                  n < before_data.apply.length;
                                  n++
                                ) {
                                  if (i === before_data.apply[n].index) {
                                    console.log(
                                      "index hit!" + n + " 番目を更新"
                                    );
                                    update_index = n;
                                  }
                                }

                                update(update_index, {
                                  index: i,
                                  pray1: input_pray1,
                                  pray2: e.target.value,
                                  money: select_money,
                                });
                                setFirsttime(false);
                                const v = getValues();
                                console.log("v: " + JSON.stringify(v));
                              }}
                            >
                              {menuItems.map(({ label, value }, index) => (
                                <MenuItem key={index} value={value}>
                                  <Typography
                                    sx={{
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    {label}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        )}
                      />
                    </Grid>
                    <Box sx={{ height: 30 }}></Box>
                    <Typography sx={{ fontSize: 18 }}>
                      おふだの種類と大きさ
                    </Typography>
                    <Grid>
                      <Controller
                        {...register(`apply.${i}.money`)}
                        control={control}
                        ref={null}
                        render={({ field }) => (
                          <div>
                            <TextField
                              select
                              defaultValue="五千円（普通）"
                              fullWidth
                              sx={{ width: 250 }}
                              onChange={(e) => {
                                setValue(`apply.${i}.money`, e.target.value);
                                setselect_money(e.target.value);

                                const unformated_data = getValues();
                                const formated_data = deleteEnpty(
                                  unformated_data.apply
                                );
                                replace(formated_data);
                                console.log("i: " + i);
                                const before_data = getValues();
                                checked = false;

                                //indexで検索して、その辞書を更新したい
                                let update_index;
                                //replaceで置き換え
                                for (
                                  let n = 0;
                                  n < before_data.apply.length;
                                  n++
                                ) {
                                  if (i === before_data.apply[n].index) {
                                    console.log(
                                      "index hit!" + n + " 番目を更新"
                                    );
                                    update_index = n;
                                  }
                                }

                                update(update_index, {
                                  index: i,
                                  pray1: input_pray1,
                                  pray2: input_pray2,
                                  money: e.target.value,
                                });
                                setFirsttime(false);
                                const v = getValues();
                                console.log("v: " + JSON.stringify(v));
                              }}
                            >
                              {money_menu.map(({ label, value }, index) => (
                                <MenuItem key={index} value={value}>
                                  <Typography
                                    sx={{
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    {label}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        )}
                      />
                    </Grid>
                    <Box sx={{ height: 40 }}></Box>
                  </Grid>
                </Box>
              )
          )}
      </form>
      <Grid>
        <Controller
          {...register(`biko`)}
          control={control}
          ref={null}
          render={({ field }) => (
            <div>
              <TextField
                multiline
                label="備考"
                rows={4}
                defaultValue=""
                placeholder="何か神社に伝えておきたいことがあればご記入ください"
                InputProps={{ fontSize: 16 }}
                fullWidth
                sx={{ width: 250 }}
                onChange={(e) => {
                  console.log("biko onchange");
                  setValue(`biko`, e.target.value);
                }}
              ></TextField>
            </div>
          )}
        />
      </Grid>
      <Box sx={{ height: 20 }}></Box>

      <Divider style={{ width: "100%" }} />

      <Box sx={{ height: 40 }}></Box>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"center"}
      >
        <Button
          sx={{ width: "60%", height: 55, backgroundColor: "#ffa94d" }}
          variant="contained"
          onClick={() => onSubmit("next")}
        >
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
            日時の選択へ
          </Typography>
        </Button>
        <Box sx={{ height: 20 }}></Box>

        <Button
          sx={{
            width: "60%",
            height: 55,
            backgroundColor: "#ece9e9f2",
            color: "black",
          }}
          variant="contained"
          onClick={() => goHome()}
        >
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
            もどる
          </Typography>
        </Button>
      </Grid>
      <Box sx={{ height: 150 }}></Box>

      <Dialog fullScreen onClose={negaiDClose} open={NegaiD}>
        <Negai handleCloseUpdate={() => negaiDClose()} />
      </Dialog>

      <Dialog fullScreen onClose={ofudaDClose} open={OfudaD}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"left"}
        >
          <Ofuda handleCloseUpdate={() => ofudaDClose()} />
        </Grid>
      </Dialog>

      <Dialog onClose={retryDClose} open={retryD}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"left"}
          p={3}
        >
          <DialogTitle>
            {" "}
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              選択されていません
            </Typography>
          </DialogTitle>
          <Box sx={{ height: 20 }}></Box>

          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              color="inherit"
              sx={{
                width: "60%",
                height: 55,
                backgroundColor: "#ece9e9f2",
                color: "black",
              }}
              onClick={() => retryDClose()}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                もどる
              </Typography>
            </Button>
            <Box sx={{ height: 20 }}></Box>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default Easyapply1;
