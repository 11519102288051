import React, {
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
  Children,
} from "react";
import Button from "@mui/material/Button";
import {
  collection,
  deleteDoc,
  onSnapshot,
  addDoc,
  doc,
  query,
  getDocs,
  where,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  collectionGroup,
} from "firebase/firestore";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { db, app } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { allowContextMenu } from "@fullcalendar/core";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AlertTitle from "@mui/material/AlertTitle";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@mui/styles";
import Fab from "@mui/material/Fab";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useNavigate, Navigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { forwardRef, useRef } from "react";
import axios from "axios";
import { useKana } from "react-use-kana";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { IconButton } from "@mui/material";
import { deAT } from "date-fns/locale";
import Iframe from "react-iframe";
import { gapi } from "gapi-script";

//NOTE カレンダーの独自キーとgpiのapiキー
const calendarId = "3d1jcmh8t98e0rsfu587in62fg@group.calendar.google.com";
const apiKey = "AIzaSyDkp6qDgEV0QsbBr7c_CyANsjiEx0rGGx8";

export default function DisplayPage() {
  const [events, setEvents] = useState([]);
  const [user_count, setUserCount] = useState(0);
  const [recurrence_text_arr, SetRecurrenceTextArr] = useState([]);
  const [singleevent_text_arr, SetSingleeventTextArr] = useState([]);

  useEffect(() => {
    console.log(recurrence_text_arr.length);
  }, [recurrence_text_arr]);

  const getUserCount = async () => {
    const userRef = collection(db, "account_list");
    const snapshot = await getDocs(userRef);
    // console.log("count: ", snapshot.size);
    setUserCount(snapshot.size);
  };

  const goCalendar = () => {
    openurl("https://calendar.google.com/calendar/u/1/r/week/2023/5/1");
  };

  const openurl = (url) => {
    window.open(url, "_blank");
  };

  const getEvents = (calendarID, apiKey) => {
    function initiate() {
      gapi.client
        .init({
          apiKey: apiKey,
        })

        .then(function () {
          return gapi.client.request({
            path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
            params: {
              timeMin: new Date().toISOString(),
            },
          });
        })

        .then(
          (response) => {
            let events = response.result.items;
            console.log("events ->> " + JSON.stringify(events));
            setEvents(events);
            let recurrence_arr = [];
            let single_event_arr = [];
            console.log("typeof calendar_events" + typeof events);
            for (let i = 0; i < events.length; i++) {
              //定期的な日時を返す
              //配列に入れる
              if (typeof events[i].recurrence !== "undefined") {
                recurrence_arr.push(
                  GetRecurrence(events[i].recurrence, events[i])
                );
              } else {
                single_event_arr.push(events[i]);
              }
            }
            SetRecurrenceTextArr(recurrence_arr);
            SetSingleeventTextArr(single_event_arr);
            return events;
          },
          function (err) {
            return [false, err];
          }
        );
    }

    gapi.load("client", initiate);
  };

  const Outputdate = (start, end, recurrence) => {
    if (typeof start["dateTime"] !== "undefined") {
      const start_date = new Date(start["dateTime"]);
      const end_date = new Date(end["dateTime"]);

      let result_text = "";
      if (recurrence) {
        result_text =
          start_date.getHours() +
          "時" +
          start_date.getMinutes() +
          "分〜" +
          end_date.getHours() +
          "時" +
          end_date.getMinutes() +
          "分";
      } else {
        result_text =
          start_date.getFullYear() +
          "年" +
          (start_date.getMonth() + 1) +
          "月" +
          start_date.getDate() +
          "日" +
          start_date.getHours() +
          "時" +
          start_date.getMinutes() +
          "分〜" +
          end_date.getHours() +
          "時" +
          end_date.getMinutes() +
          "分";
      }

      return result_text;
    } else {
      const day_string = String(start["date"]);
      const year = day_string.substring(0, 4);
      let month = day_string.substring(5, 7);
      let day = day_string.substring(8, 10);
      console.log("day.slice()" + day.slice(0, 1));
      if (month.slice(0, 1) == "0") {
        month = month.slice(1);
      }
      if (day.slice(0, 1) == "0") {
        day = day.slice(1);
      }

      const result_text = year + "年" + month + "月" + day + "日中";
      return result_text;
    }
  };

  //定期的な日時
  const GetRecurrence = (deta, event_deta) => {
    let recurrence_arr = { title: "", disc: "", time_duration: "" };
    console.log("deta: " + JSON.stringify(deta));
    //TODO カンマで文を分け、配列に入れる RRULE:FREQ=WEEKLY;BYDAY=TU ->[FREQ=WEEKLY, BYDAY=TU, BYDAY=] -> [FREQ:WEEKLY, BYDAY:TU]
    const rrule = deta[0].slice(6, deta[0].length);
    let rrule_arr = [];
    var result = rrule.split(";");
    console.log("result: " + JSON.stringify(result));
    for (let i = 0; i < result.length; i++) {
      var separate = result[i].split("=");
      const dic = { [separate[0]]: separate[1] };
      rrule_arr.push(dic);
    }
    console.log(rrule_arr);

    for (let j = 0; j < rrule_arr.length; j++) {
      if (typeof rrule_arr[j]["FREQ"] !== "undefined") {
        if (rrule_arr[j]["FREQ"] == "DAILY") {
          console.log("毎日の繰り返し");
          recurrence_arr["title"] = "毎日の繰り返し";
        } else if (rrule_arr[j]["FREQ"] == "WEEKLY") {
          console.log("週ごとの繰り返し");
          recurrence_arr["title"] = "週ごとの繰り返し";
        }
      }

      if (typeof rrule_arr[j]["INTERVAL"] !== "undefined") {
        const interval = rrule_arr[j]["INTERVAL"];
        console.log(interval + "日に１回繰り返し");
      }

      if (typeof rrule_arr[j]["COUNT"] !== "undefined") {
        const count = rrule_arr[j]["COUNT"];
        console.log("このイベントは " + count + " 回繰り返し");
      }
      if (typeof rrule_arr[j]["UNTIL"] !== "undefined") {
        const until = rrule_arr[j]["UNTIL"];
        console.log("イベントを繰り返す日付は、 " + until + " 回繰り返し");
      }
      if (typeof rrule_arr[j]["BYDAY"] !== "undefined") {
        if (rrule_arr[j]["BYDAY"] == "SU") {
          console.log("日曜日");
          recurrence_arr["disc"] = "日曜日";
        } else if (rrule_arr[j]["BYDAY"] == "MO") {
          console.log("月曜日");
          recurrence_arr["disc"] = "月曜日";
        } else if (rrule_arr[j]["BYDAY"] == "TU") {
          console.log("火曜日");
          recurrence_arr["disc"] = "火曜日";
        } else if (rrule_arr[j]["BYDAY"] == "WE") {
          console.log("水曜日");
          recurrence_arr["disc"] = "水曜日";
        } else if (rrule_arr[j]["BYDAY"] == "TH") {
          console.log("木曜日");
          recurrence_arr["disc"] = "木曜日";
        } else if (rrule_arr[j]["BYDAY"] == "FR") {
          console.log("金曜日");
          recurrence_arr["disc"] = "金曜日";
        } else if (rrule_arr[j]["BYDAY"] == "SA") {
          console.log("土曜日");
          recurrence_arr["disc"] = "土曜日";
        }
      }
    }

    recurrence_arr["time_duration"] = Outputdate(
      event_deta["start"],
      event_deta["end"],
      true
    );

    return recurrence_arr;
  };

  useLayoutEffect(() => {
    getUserCount();
    const calendar_events = getEvents(calendarId, apiKey);
  }, []);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100%", py: 3 }}
      direction={"column"}
    >
      <Typography sx={{ fontSize: 22, fontWeight: "bold", mb: 4 }}>
        管理者専用ページ
      </Typography>
      <Card sx={{ width: "90%", mb: 3 }}>
        <CardContent sx={{ p: 2 }}>
          <Typography sx={{ fontSize: 16, fontWeight: "bold", mb: 1 }}>
            {new Date().getFullYear()}年{new Date().getMonth() + 1}月
            {new Date().getDate()}日時点
          </Typography>
          <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 1 }}>
            ユーザー登録数
          </Typography>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
            {user_count}人
          </Typography>
        </CardContent>
      </Card>
      <Button
        sx={{
          width: "60%",
          height: 55,
          backgroundColor: "#ffa94d",
          color: "white",
          mt: 2,
          mb: 3,
        }}
        variant="contained"
        onClick={() => goCalendar()}
      >
        <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
          Googleカレンダーを開く
        </Typography>
      </Button>
      <Box sx={{ height: 40 }}></Box>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        sx={{ width: "90%" }}
      >
        <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 1 }}>
          定期的な予定
        </Typography>
        <Typography sx={{ fontSize: 16, mb: 1 }}>
          下記の定期的な日時は受付していません。
        </Typography>
      </Grid>
      <Box sx={{ height: 20 }}></Box>

      {recurrence_text_arr?.map((deta, i) => (
        <Card
          key={i}
          sx={{ width: "90%", mb: 1 }}
          style={{ border: "3px solid #fad4ab" }}
        >
          <CardContent>
            <Box
              sx={{
                backgroundColor: "#ece9e9f2",
                p: 1,
                borderRadius: 2,
                width: 150,
              }}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                {deta["title"]}
              </Typography>
            </Box>
            <Typography sx={{ fontSize: 18, fontWeight: "bold", mt: 1 }}>
              {" "}
              {deta["disc"]} {deta["time_duration"]}
            </Typography>
          </CardContent>
        </Card>
      ))}
      <Box sx={{ height: 40 }}></Box>

      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        sx={{ width: "90%" }}
      >
        <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 1 }}>
          臨時的な予定
        </Typography>
        <Typography sx={{ fontSize: 16, mb: 1 }}>
          下記の日時は受付していません。
        </Typography>
      </Grid>
      <Box sx={{ height: 20 }}></Box>

      {singleevent_text_arr?.map(
        (event) =>
          event.status !== "cancelled" && (
            <Card
              key={event.id}
              sx={{ width: "90%", mb: 1 }}
              style={{ border: "3px solid #fad4ab" }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  {event.displayName}
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  {event.summary}
                </Typography>
                {typeof event.start !== "undefined" && (
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    {Outputdate(event.start, event.end)}
                  </Typography>
                )}

                {typeof event.recurrence !== "undefined" && (
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    {GetRecurrence(event.recurrence)}
                  </Typography>
                )}
              </CardContent>
            </Card>
          )
      )}

      <Box sx={{ height: 40 }}></Box>

      {/* <Card sx={{ width: "60%" }} xs={{ width: "90%" }}>
        <CardContent sx={{ p: 2 }}>
          <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 2 }}>
            予約の設定
          </Typography>
        </CardContent>
      </Card> */}

      <Iframe
        src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23F09300&ctz=Asia%2FTokyo&showTitle=0&showNav=1&showPrint=0&showCalendars=0&showTabs=0&showTz=0&mode=AGENDA&showDate=1&src=M2QxamNtaDh0OThlMHJzZnU1ODdpbjYyZmdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23795548"
        style="border:solid 1px #777"
        width="100%"
        height="800"
        frameborder="0"
        scrolling="no"
      ></Iframe>
      <Box sx={{ height: 40 }}></Box>
    </Grid>
  );
}

const event = [
  {
    kind: "calendar#event",
    etag: '"3301881191684000"',
    id: "7p703t0vl00com87nt8u5j79un",
    status: "confirmed",
    htmlLink:
      "https://www.google.com/calendar/event?eid=N3A3MDN0MHZsMDBjb204N250OHU1ajc5dW5fMjAyMjA0MjZUMDAwMDAwWiAzZDFqY21oOHQ5OGUwcnNmdTU4N2luNjJmZ0Bn",
    created: "2022-04-26T02:29:39.000Z",
    updated: "2022-04-26T02:36:35.842Z",
    summary: "定休日",
    creator: { email: "0092sma@gmail.com" },
    organizer: {
      email: "3d1jcmh8t98e0rsfu587in62fg@group.calendar.google.com",
      displayName: "社務時間外",
      self: true,
    },
    start: { dateTime: "2022-04-26T09:00:00+09:00", timeZone: "Asia/Tokyo" },
    end: { dateTime: "2022-04-26T16:00:00+09:00", timeZone: "Asia/Tokyo" },
    recurrence: ["RRULE:FREQ=WEEKLY;BYDAY=TU"],
    transparency: "transparent",
    iCalUID: "7p703t0vl00com87nt8u5j79un@google.com",
    sequence: 1,
    eventType: "default",
  },
  {
    kind: "calendar#event",
    etag: '"3374313232028000"',
    id: "0fe9ufa51pv8njaca7vvn9nou9",
    status: "confirmed",
    htmlLink:
      "https://www.google.com/calendar/event?eid=MGZlOXVmYTUxcHY4bmphY2E3dnZuOW5vdTlfMjAyMzA0MzBUMDMwMDAwWiAzZDFqY21oOHQ5OGUwcnNmdTU4N2luNjJmZ0Bn",
    created: "2023-06-19T06:36:56.000Z",
    updated: "2023-06-19T06:36:56.014Z",
    summary: "御朱印・ご祈願・対応不可",
    creator: { email: "0092sma@gmail.com" },
    organizer: {
      email: "3d1jcmh8t98e0rsfu587in62fg@group.calendar.google.com",
      displayName: "社務時間外",
      self: true,
    },
    start: { dateTime: "2023-04-30T12:00:00+09:00", timeZone: "Asia/Tokyo" },
    end: { dateTime: "2023-04-30T13:00:00+09:00", timeZone: "Asia/Tokyo" },
    recurrence: ["RRULE:FREQ=DAILY"],
    iCalUID: "0fe9ufa51pv8njaca7vvn9nou9@google.com",
    sequence: 0,
    eventType: "default",
  },
  {
    kind: "calendar#event",
    etag: '"3374517257252000"',
    id: "1pusr4jdkcsqndf9cvt83s78d8",
    status: "confirmed",
    htmlLink:
      "https://www.google.com/calendar/event?eid=MXB1c3I0amRrY3NxbmRmOWN2dDgzczc4ZDggM2QxamNtaDh0OThlMHJzZnU1ODdpbjYyZmdAZw",
    created: "2023-06-20T10:57:08.000Z",
    updated: "2023-06-20T10:57:08.626Z",
    summary: "ちょっと忙しい2",
    creator: { email: "0092sma@gmail.com" },
    organizer: {
      email: "3d1jcmh8t98e0rsfu587in62fg@group.calendar.google.com",
      displayName: "社務時間外",
      self: true,
    },
    start: { date: "2023-06-22" },
    end: { date: "2023-06-23" },
    transparency: "transparent",
    iCalUID: "1pusr4jdkcsqndf9cvt83s78d8@google.com",
    sequence: 0,
    eventType: "default",
  },
  {
    kind: "calendar#event",
    etag: '"3374625071398000"',
    id: "49gek0ambvu6ihi3v98neb1ea0",
    status: "confirmed",
    htmlLink:
      "https://www.google.com/calendar/event?eid=NDlnZWswYW1idnU2aWhpM3Y5OG5lYjFlYTAgM2QxamNtaDh0OThlMHJzZnU1ODdpbjYyZmdAZw",
    created: "2023-06-20T10:56:28.000Z",
    updated: "2023-06-21T01:55:35.699Z",
    summary: "ちょっと忙しい",
    creator: { email: "0092sma@gmail.com" },
    organizer: {
      email: "3d1jcmh8t98e0rsfu587in62fg@group.calendar.google.com",
      displayName: "社務時間外",
      self: true,
    },
    start: { dateTime: "2023-06-23T10:15:00+09:00", timeZone: "Asia/Tokyo" },
    end: { dateTime: "2023-06-23T10:45:00+09:00", timeZone: "Asia/Tokyo" },
    transparency: "transparent",
    iCalUID: "49gek0ambvu6ihi3v98neb1ea0@google.com",
    sequence: 2,
    eventType: "default",
  },
];
