import React, {
  useState,
  useContext,
  useLayoutEffect,
  useEffect,
  useRef,
} from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import magatama from "../../images/magatama.png";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getAuth } from "firebase/auth";

const get_deta = async () => {
  try {
    const auth = getAuth();
    const current_uid = auth.currentUser.uid;
    const userRef = collection(db, "account_list");
    let docid = "";
    const q = query(userRef, where("uid", "==", current_uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      docid = doc.id;
    });
    const docRef = doc(db, "account_list", docid);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    if (typeof data["values"] !== "undefined") {
      return data;
    } else {
      const deta_dic = { values: [{ dango: 0, ex: 0 }] };
      await updateDoc(docRef, deta_dic);
    }
  } catch (e) {
    console.log(e);
  }
};

export function GetFieldValues() {
  return get_deta();
}

//ニックネームの登録、チュートリアルが完了しているかどうか
export function CheckTutorialComplete(deta) {
  if (typeof deta["values"][0]["tutorial_complete"] !== "undefined") {
    //完了している
    return true;
  } else {
    console.log("まだチュートリアルが終わっていません");
    return false;
  }
}

export function DailyOmagatama() {}
