import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { getAuth } from "firebase/auth";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { createContext } from "react";

//御朱印を記録する
//アニメーションを表示

export default function GosyuinLoad() {
  const navigate = useNavigate();

  const [user_id, setUser_id] = useState("");
  const [document_id, setDocumentId] = useState("");

  useLayoutEffect(() => {
    get_baseinfo();
  }, []);

  const goDialy = () => {
    navigate("/dialy");
  };

  const get_baseinfo = async () => {
    // console.log("get_baseinfo was called");
    try {
      const auth = getAuth();
      const current_uid = auth.currentUser.uid;
      // console.log("current_uid: " + current_uid);

      setUser_id(current_uid);
      const userRef = collection(db, "account_list");

      let docid = "";
      const q = query(userRef, where("uid", "==", current_uid));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setDocumentId(doc.id);
        // console.log("doc id: " + doc.id);
        docid = doc.id;
      });
      GetGosyuinDeta(userRef, docid);
    } catch (e) {
      console.log("error: " + e.message);
      // setAlert(true);
    }
  };

  const GetGosyuinDeta = async (userRef, docid) => {
    //ここで参拝した日をクエリ

    try {
      let index = 0;
      let gosyuin_values = [""];
      const gosyuinRef = collection(db, "account_list", docid, "gosyuin");

      const gosyuinSnaps = await getDocs(gosyuinRef);
      gosyuinSnaps.forEach((doc) => {
        gosyuin_values[index] = doc.data();
        index += 1;
      });

      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const today_text = year + "年" + month + "月" + day + "日";
      const deta = { day: day, year: year, month: month };
      let add = [];
      console.log("gosyuin_values: " + JSON.stringify(gosyuin_values));

      for (let i = 0; i < gosyuin_values.length; i++) {
        let stringDate = "";

        if (gosyuin_values[0] !== "") {
          const date_value = gosyuin_values[i];
          stringDate =
            date_value["year"] +
            "年" +
            date_value["month"] +
            "月" +
            date_value["day"] +
            "日";
        }
        console.log("stringDate: " + stringDate);

        if (stringDate !== today_text) {
          //今日の日付をDBに登録
          add.push(true);
        } else {
          add.push(false);
        }
      }

      console.log("addDb: " + !add.includes(false));
      if (!add.includes(false)) {
        await addDoc(gosyuinRef, deta);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"center"}
    >
      <Box sx={{ height: 200 }}></Box>
      御朱印の記録ロード画面
      <Box sx={{ height: 20 }}></Box>
      <Button onClick={() => goDialy()} variant="contained">
        アニメーションが終了、DialyHomeへ
      </Button>
    </Grid>
  );
}
