import React, {
  useState,
  useLayoutEffect,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { auth, provider } from "../firebase";
import Button from "@mui/material/Button";
import { useAuthContext } from "../context/ AuthContext";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  addDoc,
  doc,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { db, app } from "../firebase";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { getRedirectResult } from "firebase/auth";
import { Link as RouterLink } from "react-router-dom";
import YakudosiPicker from "./Yakudosi/YakudosiPicker.js";
import KiganSelect from "./Kigan/KiganSelect";
import Contact from "./Sub/Contact.js";
import jsPDF from "jspdf";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ScrollTop from "../helper/ScrollTop";
import DenseAppbar from "./DenseAppbar";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import o_oonusa from "../images/o_oonusa.png";
import torii from "../images/torii.png";
import History from "./History.js";
import CircularProgress from "@mui/material/CircularProgress";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import BottomMenu from "./Sub/BottomMenu";
import WelcomeCard from "./Sub/WelcomeCard";
import MakePWA from "./Sub/MakePWA";
import ChatAdviceComponent from "./ChatAdviceComponent/ ChatAdviceComponent.js";
import { GetFieldValues } from "../ Component/QueryHandler/DailyHandler.js";
import { motion } from "framer-motion";
import CardActionArea from "@mui/material/CardActionArea";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const Gokigan = (props) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  let query_data = [""];
  const [document_id, setDocumentId] = useState("");
  const [detail, setDetail] = useState("");
  const [accounts, setAccounts] = useState("");
  const [apply_num, setApply_num] = useState(0);
  const [applyed_user, setApplyed_user] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [detailDocId, setDetailDocId] = useState("");
  const [email, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [accountD, setAccountD] = useState(false);
  const [yakuD, setYakuD] = useState(false);
  const [contactD, setContactD] = useState(false);
  const [loginD, setLoginD] = useState(false);
  const [bottomV, setBottomV] = useState(1);
  const [phoneD, setPhoneD] = useState(false);
  const pdfRef = useRef(new jsPDF("p", "pt", "a4", true));
  //マガタマ
  const [magatama_count, setmagatama_Count] = useState(null);
  const [loading, setLoading] = useState(true);

  const [db_deta, set_db_deta] = useState(null);
  const [ex_count, set_ex_count] = useState(0);

  useLayoutEffect(() => {
    if (user !== null) {
      GetFirebaseDeta();
    }
  }, []);

  const GetFirebaseDeta = async () => {
    const deta = await GetFieldValues();
    console.log("Firebase Deta" + JSON.stringify(deta));
    set_db_deta(deta);
    set_ex_count(deta["values"][0]["ex"]);
  };

  useLayoutEffect(() => {
    let isMounted = true;
    const get_info = async () => {
      if (user != null) {
        try {
          let docId = "";
          const auth = getAuth();
          const current_uid = auth.currentUser.uid;
          // console.log("Current userID: " + current_uid);
          const userRef = collection(db, "account_list");
          //コレクションから条件クエリし、refを取得
          const q = query(userRef, where("uid", "==", current_uid));
          await getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              // console.log("該当するクエリがありました");
              if (isMounted) {
                setDocumentId(doc.id);
              }
              // console.log("user doc id: " + doc.id);
              docId = doc.id;
            });
            if (docId === "") {
              const current_uid = auth.currentUser.uid;
              // console.log("Adding uid to DB");
              const ref = collection(db, "account_list");
              const documentRef = addDoc(ref, {
                uid: current_uid,
              });
              // console.log(documentRef);
            }
          });

          get_info2(userRef, docId);
        } catch (error) {
          console.log(error);
        }
      } else {
        // console.log("user does not exist");
      }
    };
    const get_info2 = async (userRef, docid) => {
      try {
        //メアドと電話番号を取得
        const docRef = doc(db, "account_list", docid);
        const docSnap = await getDoc(docRef);
        const values = docSnap.data();
        // console.log("email: " + values["email"]);
        // console.log("phone: " + values["phone"]);
        if (isMounted) {
          setMail(values["email"]);
          setPhone(values["phone"]);
        }

        if (typeof values["dango"] !== "undefined") {
          //マガタマの数
          if (isMounted) {
            setmagatama_Count(values["dango"]);
          }
        } else {
          if (isMounted) {
            setmagatama_Count(0);
          }
        }

        //アカウント一覧があるsubcollectionのRef
        let index = 0;
        let accounts_values = [""];
        const accountsRef = collection(db, "account_list", docid, "accounts");
        const accountsSnaps = await getDocs(accountsRef);
        accountsSnaps.forEach((d) => {
          accounts_values[index] = d.data();
          index += 1;
        });
        if (isMounted) {
          setAccounts(accounts_values);
          //EasyApply1に渡す用　Home->App->EasyApplyPage->EasyApply1
          props.setAccounts(accounts_values);
        }

        if (accounts_values[0] !== "") {
          if (isMounted) {
            setApplyed_user(true);
          }
        }

        // //申込内容があるsubcollectionのRef
        const detail_values = [""];
        const docIdarr = [""];
        const i = 0;
        // console.log("Before starting query");
        // console.log("userRef-> " + userRef);
        // console.log("document_id-> " + docid);
        const detail_query = query(
          collection(userRef, docid, "details"),
          orderBy("timestamp", "asc"),
          limit(10)
        );
        const detail_snapshots = await getDocs(detail_query);
        detail_snapshots.forEach((doc) => {
          detail_values[i] = doc.data();
          docIdarr[i] = doc.id;
          // console.log("data->  " + JSON.stringify(doc.data()));
          index += 1;
        });
        // console.log("After starting query");
        // console.log("detail_values-> " + JSON.stringify(detail_values));
        if (isMounted && detail_values !== [""]) {
          setDetail(detail_values);
          setDetailDocId(docIdarr);
          setApply_num(index);
        }
      } catch (error) {
        console.log(error);
      }
    };
    get_info();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (magatama_count !== null) {
      setLoading(false);
    }

    if (user == null) {
      setLoading(false);
    }
  }, [magatama_count]);

  const useStyles = makeStyles((theme) => ({
    fab: {
      position: "fixed",
      margin: 0,
      bottom: "auto",
      right: 20,
      top: 20,
    },
  }));

  const classes = useStyles();

  const addDb = async (id) => {
    if (user != null) {
      try {
        let docId = "";
        const auth = getAuth();
        const current_uid = auth.currentUser.uid;
        // console.log("Current userID: " + current_uid);
        const userRef = collection(db, "account_list");

        const q = query(userRef, where("uid", "==", current_uid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          //既に登録されていたら追加しない
          if (doc.exists) {
            // console.log("user doc id: " + doc.id);
            docId = doc.id;
            // console.log("既に追加されています");
          } else {
            //アカウントがなかったら登録する
            const documentRef = addDoc(userRef, {
              uid: id,
            });
            // console.log("新しくアカウントを登録しました: " + documentRef);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("user does not exist");
    }
  };

  const get_info = async () => {
    if (user != null) {
      try {
        let docId = "";
        const auth = getAuth();
        const current_uid = auth.currentUser.uid;
        // console.log("Current userID: " + current_uid);
        const userRef = collection(db, "account_list");
        //コレクションから条件クエリし、refを取得
        const q = query(userRef, where("uid", "==", current_uid));
        await getDocs(q).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log("該当するクエリがありました");

            setDocumentId(doc.id);
            // console.log("user doc id: " + doc.id);
            docId = doc.id;
          });
          if (docId === "") {
            const current_uid = auth.currentUser.uid;
            // console.log("Adding uid to DB");
            const ref = collection(db, "account_list");
            const documentRef = addDoc(ref, {
              uid: current_uid,
            });
            // console.log(documentRef);
          }
        });

        get_info2(userRef, docId);
      } catch (error) {
        console.log(error);
      }
    } else {
      // console.log("user does not exist");
    }
  };
  const get_info2 = async (userRef, docid) => {
    try {
      //メアドと電話番号を取得
      const docRef = doc(db, "account_list", docid);
      const docSnap = await getDoc(docRef);
      const values = docSnap.data();
      // console.log("email: " + values["email"]);
      // console.log("phone: " + values["phone"]);
      setMail(values["email"]);
      setPhone(values["phone"]);

      if (typeof values["dango"] !== "undefined") {
        //マガタマの数
        setmagatama_Count(values["dango"]);
      } else {
        setmagatama_Count(0);
      }

      //アカウント一覧があるsubcollectionのRef
      let index = 0;
      let accounts_values = [""];
      const accountsRef = collection(db, "account_list", docid, "accounts");
      const accountsSnaps = await getDocs(accountsRef);
      accountsSnaps.forEach((d) => {
        accounts_values[index] = d.data();
        index += 1;
      });
      setAccounts(accounts_values);
      //EasyApply1に渡す用　Home->App->EasyApplyPage->EasyApply1
      props.setAccounts(accounts_values);

      if (accounts_values[0] !== "") {
        setApplyed_user(true);
      }

      // //申込内容があるsubcollectionのRef
      const detail_values = [""];
      const docIdarr = [""];
      const i = 0;
      // console.log("Before starting query");
      // console.log("userRef-> " + userRef);
      // console.log("document_id-> " + docid);
      const detail_query = query(
        collection(userRef, docid, "details"),
        orderBy("timestamp", "asc"),
        limit(10)
      );
      const detail_snapshots = await getDocs(detail_query);
      detail_snapshots.forEach((doc) => {
        detail_values[i] = doc.data();
        docIdarr[i] = doc.id;
        // console.log("data->  " + JSON.stringify(doc.data()));
        index += 1;
      });
      // console.log("After starting query");
      console.log("detail_values-> " + JSON.stringify(detail_values));

      if (detail_values !== [""]) {
        setDetail(detail_values);
        setDetailDocId(docIdarr);
        setApply_num(index);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //予約のキャンセル
  const cancelBooking = async () => {
    //この予約のDocIDを取得して参照、削除

    //最新の申込のDocId  detailDocId
    const targetdocRef = doc(
      db,
      "account_list",
      document_id,
      "details",
      detailDocId[0]
    );
    await deleteDoc(targetdocRef);
    // console.log("---------予約をキャンセルしました-----------");
    CloseCancelDialog();
  };
  //ログアウト
  const handleLogout = () => {
    auth.signOut();
    navigate("/login");
  };
  const handleApply = () => {
    // console.log("accounts.length" + accounts.length);
    if (user == null) {
      loginDopen();
    } else if (accounts == "") {
      //アカウントを登録してもらう
      // setAccountD(true);
    } else {
      //電話番号とメアドが登録されていなかったら登録して頂く
      if (email !== undefined && phone !== undefined) {
        navigate("/apply1");
      } else {
        setContactD(true);
      }
    }
  };
  const goAccounts = () => {
    if (user !== null) {
      navigate("/accounts");
    } else {
      loginDopen();
    }
  };

  const goSignup = () => {
    navigate("/signup");
  };

  const goGosyuin = () => {
    if (user == null) {
      loginDopen();
    } else if (accounts == "") {
      AccountDOpen();
    } else {
      navigate("./gosyuin");
    }
  };

  const goNewYear = () => {
    if (user == null) {
      loginDopen();
    } else if (accounts == "") {
      AccountDOpen();
    } else {
      navigate("/newyear");
    }
  };
  const goNewYearYaku = () => {
    if (user == null) {
      loginDopen();
    } else if (accounts == "") {
      AccountDOpen();
    } else {
      navigate("/newyearyaku");
    }
  };

  const AccountDClose = () => {
    setAccountD(false);
  };
  const AccountDOpen = () => {
    setAccountD(true);
  };

  //予約キャンセルDialog
  const OpenCancelDialog = () => {
    setCancel(true);
  };
  const CloseCancelDialog = () => {
    setCancel(false);
  };

  const yakuDClose = () => {
    setYakuD(false);
  };
  const yakuDOpen = () => {
    setYakuD(true);
  };

  const contactDclose = () => {
    setContactD(false);
  };

  const contactDopen = () => {
    setContactD(true);
  };

  const accountDclose = () => {
    setAccountD(false);
  };

  const accountDopen = () => {
    setAccountD(true);
  };

  const loginDopen = () => {
    setLoginD(true);
  };
  const loginDclose = () => {
    setLoginD(false);
  };

  const phoneDOpen = () => {
    setPhoneD(true);
  };

  const phoneDClose = () => {
    setPhoneD(false);
  };

  const PhoneCall = () => {
    window.open("tel:0246340092", "_blank");
  };

  if (!loading) {
    return (
      <div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ScrollTop></ScrollTop>
          <DenseAppbar
            text="ご祈祷"
            magatama={magatama_count}
            ex_count={ex_count}
          ></DenseAppbar>
          <Box sx={{ height: 30 }}></Box>
          {/* {user === null && <WelcomeCard></WelcomeCard>} */}
          {/* <Box sx={{ height: 10 }}></Box>
        <MakePWA /> */}
          <Box sx={{ height: 40 }}></Box>

          <Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              textAlign={"left"}
            >
              {/* <Divider sx={{ width: "90%", mb: 2 }}></Divider> */}

              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                textAlign={"left"}
              >
                <Grid item xs={6}>
                  <Typography sx={{ ml: 3, fontSize: 20, fontWeight: "bold" }}>
                    厄年検索
                  </Typography>
                  <Typography sx={{ ml: 3, fontSize: 20, fontWeight: "bold" }}>
                    (令和6年版)
                  </Typography>
                </Grid>
                <Grid item xs={5.5} ml={0.5}>
                  <Button
                    onClick={() => yakuDOpen()}
                    sx={{
                      backgroundColor: "#ece9e9f2",
                      width: "90%",
                      height: 44,
                    }}
                  >
                    <Typography sx={{ color: "black", fontSize: 14 }} mr={0.5}>
                      厄年とは？
                    </Typography>
                    <HelpOutlineIcon sx={{ color: "black" }}></HelpOutlineIcon>
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{ height: 25 }}></Box>

              <YakudosiPicker
                openAccountD={() => accountDopen()}
                accounts={accounts}
              />
              <Box sx={{ height: 40 }}></Box>
              <Divider sx={{ width: "90%", mb: 3 }}></Divider>
              {/* <Grid
                container
                direction={"row"}
                justifyContent="flex-start"
                alignItems="center"
                textAlign={"left"}
              >
                <Typography sx={{ ml: 3, fontSize: 20, fontWeight: "bold" }}>
                  新年のおふだの申込
                </Typography>
              </Grid>
              <Box sx={{ height: 20 }}></Box>
              <Card
                sx={{ width: "90%" }}
                style={{ border: "3px solid #fad4ab" }}
              >
                <CardContent>
                  <Grid>
                    <Typography
                      sx={{ mt: 3, mb: 2, fontSize: 16 }}
                      variant="body2"
                    >
                      古いおふだをお下げし、神棚に新しい年魂の宿ったおふだをおまつりしましょう
                    </Typography>
                    <Grid
                      container
                      direction={"row"}
                      justifyContent="center"
                      alignItems="center"
                      textAlign={"left"}
                    >
                      <Button
                        sx={{
                          width: "100%",
                          height: 55,
                          backgroundColor: "#ffa94d",
                          mt: 2,
                          mb: 2,
                        }}
                        variant="contained"
                        onClick={() => goNewYear()}
                      >
                        <Typography
                          sx={{
                            fontSize: 22,
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          新年祈祷のおふだを申込
                        </Typography>
                      </Button>
                    </Grid>
                    {/* <Typography
                      sx={{ mt: 1.1, fontSize: 16, fontWeight: "bold" }}
                      variant="body2"
                    >
                      ・厄年や方位除けに該当する方は厄祓いのおふだをおまつりしましょう
                    </Typography> */}
                    {/* <Grid
                      container
                      direction={"row"}
                      justifyContent="center"
                      alignItems="center"
                      textAlign={"left"}
                    >
                      <Button
                        sx={{
                          width: "100%",
                          height: 55,
                          backgroundColor: "#ffa94d",
                          mt: 2,
                        }}
                        variant="contained"
                        onClick={() => goNewYearYaku()}
                      >
                        <Typography
                          sx={{
                            fontSize: 22,
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          厄祓いのおふだを申込
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>  */}

              <Box sx={{ height: 40 }}></Box>
              <Divider sx={{ width: "90%", mb: 3 }}></Divider>

              <Grid
                container
                direction={"row"}
                justifyContent="flex-start"
                alignItems="center"
                textAlign={"left"}
              >
                <Typography sx={{ ml: 3, fontSize: 20, fontWeight: "bold" }}>
                  ご祈祷の申込
                </Typography>
              </Grid>
              <Box sx={{ height: 20 }}></Box>
              {/* {accounts !== "" && <ChatAdviceComponent />}
            <Box sx={{ height: 20 }}></Box> */}

              <KiganSelect
                contact_data={phone}
                contactDopen={() => contactDopen()}
                setType={props.setType}
                openLoginD={() => loginDopen()}
                openAccountD={() => accountDopen()}
                accounts={accounts}
                phoneDOpen={() => phoneDOpen()}
              />

              <Box sx={{ height: 40 }}></Box>
              <Divider sx={{ width: "90%", mb: 3 }}></Divider>
            </Grid>

            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              textAlign={"left"}
            >
              <Typography sx={{ ml: 3, fontSize: 20, fontWeight: "bold" }}>
                近日のご予約
              </Typography>
            </Grid>
            <Box sx={{ height: 20 }}></Box>

            {detail !== "" && <History values={detail}></History>}
            {detail == "" && (
              <Grid ml={3}>
                <Box mt={2}>
                  <Typography
                    sx={{ fontSize: 15, fontWeight: "bold", color: "gray" }}
                  >
                    まだお申込がありません
                  </Typography>
                  <Box sx={{ height: 20 }}></Box>
                </Box>
              </Grid>
            )}

            <Box sx={{ height: 20 }}></Box>

            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              textAlign={"center"}
            >
              {/* <Divider sx={{ width: "90%" }}></Divider> */}

              <Box sx={{ height: 10 }}></Box>

              <Contact />

              <Box sx={{ height: 120 }}></Box>
            </Grid>

            <Dialog onClose={CloseCancelDialog} open={cancel}>
              <DialogTitle>
                この予約をキャンセルしてもよろしいですか？
              </DialogTitle>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                textAlign={"center"}
              >
                <Grid container justify="flex-start" alignItems="flex-start">
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#c33d3d", color: "white" }}
                      onClick={() => cancelBooking()}
                    >
                      キャンセル
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#ff7043", color: "#FFFFFF" }}
                      onClick={() => CloseCancelDialog()}
                    >
                      戻る
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Dialog>

            <Dialog onClose={yakuDClose} open={yakuD} sx={{ width: "100%" }}>
              <Grid sx={{ height: "90%" }}>
                <Box sx={{ height: 20 }}></Box>

                <Typography
                  sx={{ fontSize: 17, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={1}
                  mt={3}
                >
                  厄年（やくどし）
                </Typography>
                <Typography sx={{ fontSize: 14 }} mr={2} ml={2} mb={1}>
                  災いが多く降りかかる年齢とされています。
                </Typography>
                <Typography sx={{ fontSize: 14 }} mr={2} ml={2} mb={3}>
                  厄年にあたる「つ獲得厄」、前1年間の前兆が現れるとされる「前厄」、厄の恐れが薄らいでいくとされる「後厄」があります。
                </Typography>
                <Typography sx={{ fontSize: 16 }} mr={2} ml={2} mb={3}>
                  ご祈祷の種類：一般祈願/厄除け
                </Typography>

                <Divider sx={{ width: "90%" }}></Divider>

                <Typography
                  sx={{ fontSize: 17, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={1}
                  mt={2}
                >
                  方位
                </Typography>

                <Typography sx={{ fontSize: 14 }} mr={2} ml={2} mb={2}>
                  土地や家、年回りなどから来る災いです。私たちは建築や引越、旅行などによって知らず知らずに方位を犯しつつ生活をしています。
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={2}
                >
                  鬼門：北東の方角
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={2}
                >
                  病門：南西の方角
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={3}
                >
                  八方塞：すべての方角
                </Typography>
                <Typography sx={{ fontSize: 16 }} mr={2} ml={2} mb={3}>
                  ご祈祷の種類：一般祈願/方位除け
                </Typography>
                <Divider sx={{ width: "90%" }}></Divider>
                <Typography
                  sx={{ fontSize: 17, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={1}
                  mt={3}
                >
                  年祝い
                </Typography>
                <Typography sx={{ fontSize: 14 }} mr={2} ml={2} mb={3}>
                  人生で大事な節目の年に達したことを祝い、その後の人生の無病息災・健康長寿を祈願する年です。
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={2}
                >
                  還暦（かんれき）：61歳
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={2}
                >
                  古稀（こき）：70歳
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={3}
                >
                  喜寿（きじゅ）：77歳
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={3}
                >
                  傘寿（さんじゅ）：80歳
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={3}
                >
                  米寿（べいじゅ）：88歳
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={3}
                >
                  卒寿（そつじゅ）：90歳
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={3}
                >
                  白寿（はくじゅ）：99歳
                </Typography>
                <Typography sx={{ fontSize: 16 }} mr={2} ml={2} mb={3}>
                  ご祈祷の種類：一般祈願/年祝い
                </Typography>
                <Divider sx={{ width: "90%" }}></Divider>

                <Box sx={{ height: 50 }}></Box>

                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  direction="column"
                  textAlign={"center"}
                >
                  <Button
                    sx={{
                      width: "70%",
                      height: 44,
                      backgroundColor: "#ece9e9f2",
                    }}
                    onClick={yakuDClose}
                    variant="contained"
                  >
                    <Typography
                      sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
                    >
                      もどる
                    </Typography>
                  </Button>
                  <Box sx={{ height: 60 }}></Box>
                </Grid>
              </Grid>
            </Dialog>

            <Dialog
              onClose={contactDclose}
              open={contactD}
              sx={{ width: "100%" }}
            >
              <Box sx={{ height: 40 }}></Box>

              <Typography sx={{ fontSize: 16 }} mt={2} mr={2} ml={2}>
                予約にはご連絡先の登録が必要です
              </Typography>
              <Box sx={{ height: 40 }}></Box>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                textAlign={"center"}
              >
                <Grid
                  container
                  justify="flex-start"
                  alignItems="flex-start"
                  mt={2}
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="inherit"
                      sx={{ height: 55 }}
                      onClick={() => contactDclose()}
                    >
                      戻る
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#ff7043",
                        color: "white",
                      }}
                      sx={{ height: 55 }}
                      onClick={() => goAccounts()}
                    >
                      登録へ
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ height: 40 }}></Box>
            </Dialog>

            <Dialog
              onClose={accountDclose}
              open={accountD}
              sx={{ width: "100%" }}
            >
              <Box sx={{ height: 40 }}></Box>

              <Typography
                sx={{ fontSize: 16, fontWeight: "bold" }}
                mt={2}
                mr={2}
                ml={2}
              >
                「基本情報とご連絡先」の登録をお願いします。
              </Typography>
              <Box sx={{ height: 40 }}></Box>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                textAlign={"center"}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#ffa94d",
                    color: "white",
                  }}
                  sx={{ height: 55, width: "70%" }}
                  onClick={() => goAccounts()}
                >
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    登録へ
                  </Typography>
                </Button>
                <Box sx={{ height: 20 }}></Box>

                <Button
                  variant="contained"
                  color="inherit"
                  sx={{ height: 55, width: "70%" }}
                  onClick={() => accountDclose()}
                >
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    もどる
                  </Typography>
                </Button>
              </Grid>
              <Box sx={{ height: 40 }}></Box>
            </Dialog>

            <Dialog onClose={loginDclose} open={loginD} sx={{ width: "100%" }}>
              <Box sx={{ height: 40 }}></Box>
              <Grid p={2}>
                <Typography
                  sx={{ fontSize: 16, fontWeight: "bold" }}
                  mt={2}
                  mr={2}
                  ml={2}
                >
                  ご利用には、新規登録・ログインが必要です。
                </Typography>
                <Box sx={{ height: 40 }}></Box>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  direction="column"
                  textAlign={"center"}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#ff7043",
                      color: "white",
                      width: "70%",
                      height: 55,
                    }}
                    onClick={() => goSignup()}
                  >
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      登録・ログイン
                    </Typography>
                  </Button>
                  <Box sx={{ height: 20 }}></Box>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => loginDclose()}
                    style={{ width: "70%" }}
                    sx={{ height: 55 }}
                  >
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      もどる
                    </Typography>
                  </Button>
                </Grid>
                <Box sx={{ height: 40 }}></Box>
              </Grid>
            </Dialog>

            <Dialog onClose={phoneDClose} open={phoneD} sx={{ width: "100%" }}>
              <Box sx={{ height: 40 }}></Box>
              <Grid p={2}>
                <Typography sx={{ fontSize: 14 }} mt={2} mr={2} ml={2}>
                  お電話での申込となります。
                </Typography>
                <Box sx={{ height: 40 }}></Box>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  direction="column"
                  textAlign={"center"}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#ff7043",
                      color: "white",
                      width: "70%",
                    }}
                    sx={{ height: 55 }}
                    onClick={() => PhoneCall()}
                  >
                    電話をかける
                  </Button>
                  <Box sx={{ height: 20 }}></Box>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => phoneDClose()}
                    style={{ width: "70%" }}
                    sx={{ height: 55 }}
                  >
                    戻る
                  </Button>
                </Grid>
                <Box sx={{ height: 40 }}></Box>
              </Grid>
            </Dialog>
          </Grid>
        </motion.div>
        <BottomMenu value={1} />
      </div>
    );
  } else {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress></CircularProgress>
        <BottomMenu value={1} />
      </Box>
    );
  }
};

export default Gokigan;
