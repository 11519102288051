import { React, useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "@mui/material/Button";
import { auth, provider, app } from "../firebase";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, onSnapshot, addDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import Avatar from "@mui/material/Avatar";
import LockIcon from "@mui/icons-material/Lock";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import {
  GoogleAuthProvider,
  getRedirectResult,
  signInWithRedirect,
  FacebookAuthProvider,
} from "firebase/auth";
import { IconButton } from "@mui/material";

import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import torii from "../images/torii.png";
import oonusa from "../images/oonusa.png";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import BottomMenu from "./Sub/BottomMenu";
import CircularProgress from "@mui/material/CircularProgress";

const Login = (props) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [sessionstart, setSessionStart] = useState(false);

  // const [currentState, setCurrentState] = useContext(UserInputData);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      mailBox: "",
      passwordBox: "",
    },
  });

  const addDb = async (id) => {
    console.log("addDB was called");
    const ref = collection(db, "account_list");
    const documentRef = await addDoc(ref, {
      uid: id,
    });
    console.log(documentRef);
  };

  const useStyles = makeStyles((theme) => ({
    GoogleButton: {
      textTransform: "none",
    },
    root: {
      "& .MuiOutlinedInput-input": {
        border: 0,
        borderRadius: 3,
        fontSize: 18,
        fontWeight: "bold",
      },
    },
  }));
  const classes = useStyles();
  const provider = new GoogleAuthProvider();

  const handleLogin = async (event) => {
    setSessionStart(true);
    signInWithRedirect(auth, provider);
    // navigate("/");
  };

  const facebook_provider = new FacebookAuthProvider();

  const facebookLogin = async (event) => {
    signInWithRedirect(auth, facebook_provider);
  };

  const goHome = (event) => {
    navigate("/logincomplete");
  };

  useEffect(() => {
    getRedirectResult(auth).then((result) => {
      if (result == null) {
        // console.log("result-> " + result);
      } else {
        // console.log("result-> " + JSON.stringify(result));
        // The signed-in user info.
        const user = result.user;
        // console.log(user);
        // console.log(user.uid);
        addDb(user.uid);
        console.log("認証成功");
        setSessionStart(false);
      }
    });
  }, []);

  const checkLogint = function () {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const email = user.email;
        // console.log(uid);
        // console.log(email);
        handleOpen();
      } else {
        console.log("user==null");
      }
    });
  };

  checkLogint();
  const goSignup = () => {
    navigate("/signup");
  };

  //確認Dialog
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onSubmit = async (data) => {
    //入力した内容をゲットしたい
    //入力した情報を渡す
    // props.SetMail(data.mailBox)
    // props.SetPass(data.passwordBox)
    try {
      await auth.signInWithEmailAndPassword(data.mailBox, data.passwordBox);
      console.log(
        data.mailBox + "と" + data.passwordBox + "でログインしました"
      );
      navigate("/logincomplete");
    } catch (e) {
      if (e.code === "auth/invalid-email") {
        // メールアドレスの形式がおかしい
        setError("メールアドレスの形式を確認して下さい");
      } else if (e.code === "auth/user-disabled") {
        // ユーザが無効になっている
        setError("無効なユーザーです");
      } else if (e.code === "auth/user-not-found") {
        // ユーザが存在しない
        setError("アカウントがありません");
      } else if (e.code === "auth/wrong-password") {
        // パスワードが間違っている
        setError(
          "パスワードが違います（Googleアカウントで登録されている場合があります）"
        );
      } else if (e.code === "auth/too-many-requests") {
        // 何度もパスワードを間違えた
        setError("一定時間に何度も間違えたため、しばらくお待ちください");
      } else {
        // その他
        console.log("onSubmitでエラー: " + e.code);
      }
    }
  };

  const CustomisedBottomNavigationAction = (props) => (
    <BottomNavigationAction sx={{ minWidth: "70px" }} {...props} />
  );
  if (!sessionstart) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        direction="column"
        textAlign={"center"}
        sx={{ width: "100%" }}
      >
        <Box sx={{ height: 40 }}></Box>
        <Grid container alignItems="flex-start">
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            ml={3}
            sx={{
              backgroundColor: "#ece9e9f2",
              height: 44,
              width: 120,
              borderRadius: 2,
            }}
          >
            <IconButton onClick={() => goSignup()}>
              <ArrowBackIosNewOutlinedIcon
                sx={{ color: "black" }}
              ></ArrowBackIosNewOutlinedIcon>
              <Typography sx={{ fontSize: 16, fontWeight: "bold", ml: 1 }}>
                もどる
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt={3}
        >
          <Grid item>
            <Typography
              sx={{ fontSize: 20, fontWeight: "bold", color: "black" }}
            >
              ログイン
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ height: 20 }}></Box>

        <Card>
          <CardContent
            sx={{
              "&:last-child": { pb: 0 },
            }}
          >
            {error && (
              <Typography style={{ color: "red" }} p={3}>
                {error}
              </Typography>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                textAlign={"center"}
                mb={2}
                sx={{ width: "260px" }}
              >
                <Controller
                  control={control}
                  name="mailBox"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="メールアドレス"
                      margin="normal"
                      fullWidth
                      sx={{ width: "260px" }}
                      className={classes.root}
                      placeholder="メールアドレス"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="passwordBox"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="パスワード"
                      margin="normal"
                      placeholder="パスワード"
                      type="password"
                      fullWidth
                      className={classes.root}
                      sx={{ width: "260px" }}
                    />
                  )}
                />
                <Box sx={{ height: 20 }}></Box>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#ffa94d", color: "white" }}
                  type="submit"
                  sx={{ width: "260px", height: 55 }}
                >
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    ログインする
                  </Typography>
                </Button>
                <Box sx={{ height: 20 }}></Box>
              </Grid>
            </form>
          </CardContent>
        </Card>
        <Box sx={{ height: 40 }}></Box>

        <Button
          variant="contained"
          style={{
            backgroundColor: "#ffffff",
            color: "black",
            textTransform: "capitalize",
          }}
          type="submit"
          onClick={() => {
            facebookLogin();
          }}
          sx={{ width: "260px", minHeight: "50px" }}
          startIcon={
            <Avatar
              src={
                "https://upload.wikimedia.org/wikipedia/commons/b/b8/2021_Facebook_icon.svg"
              }
              className={classes.GoogleButton}
              sx={{ width: 25, height: 25 }}
            />
          }
        >
          <Typography
            sx={{ fontSize: 16, fontWeight: "bold" }}
            className={classes.GoogleButton}
          >
            Facebookログイン
          </Typography>
        </Button>
        <Box sx={{ height: 30 }}></Box>

        <Button
          variant="contained"
          style={{
            backgroundColor: "#ffffff",
            color: "black",
            textTransform: "capitalize",
          }}
          type="submit"
          onClick={() => {
            handleLogin();
          }}
          sx={{ width: "260px", minHeight: "50px" }}
          startIcon={
            <Avatar
              src={
                "https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
              }
              className={classes.GoogleButton}
              sx={{ width: 25, height: 25 }}
            />
          }
        >
          <Typography
            sx={{ fontSize: 16, fontWeight: "bold" }}
            className={classes.GoogleButton}
          >
            Googleログイン
          </Typography>
        </Button>
        <Box sx={{ height: 40 }}></Box>

        <Dialog onClose={handleClose} open={open}>
          <Typography sx={{ fontSize: 18 }} m={2}>
            ログインしました
          </Typography>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Grid container justify="flex-start" alignItems="flex-start" mt={2}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="default"
                  style={{ backgroundColor: "#ffffff", color: "black" }}
                  onClick={() => goHome()}
                >
                  ホームへ
                </Button>
              </Grid>
            </Grid>
            <Box sx={{ height: 20 }}></Box>
          </Grid>
        </Dialog>
        <Box sx={{ height: 60 }}></Box>
        {/* <BottomMenu value={8} /> */}
      </Grid>
    );
  } else {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress></CircularProgress>
      </Box>
    );
  }
};

export default Login;
