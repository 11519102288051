import { React, useState, useContext, useLayoutEffect, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Fab from "@mui/material/Fab";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import inupic from "../../images/inu.jpg";
import Divider from "@mui/material/Divider";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import ScrollTop from "../../helper/ScrollTop.js";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { addDays } from "date-fns";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { OtherApplyData } from "./NewYearYakuPage";

const useStyles = makeStyles(() => ({
  nomalButton: {
    background: "#1920eb",
  },
  activeButton: {
    variant: "contained",
  },

  root: {
    ".MuiPickersDay-root": {
      fontSize: 24,
      fontWeight: "bold",
    },
  },
}));

const NewYearYaku_2 = (props) => {
  const { currentState, loading, setCurrentState } = useContext(OtherApplyData);
  // console.log("currentState = " + JSON.stringify(currentState));
  const [num, setNum] = useState(0);
  const [value, onChange] = useState(new Date());
  const [selected_year, Setselected_year] = useState("");
  const [selected_month, Setselected_month] = useState("");
  const [selected_day, Setselected_day] = useState("");
  const [selected_time, Setselected_time] = useState("");
  const [timezone, setTimezone] = useState(["1月3日", "1月4日以降"]);
  const [activeButton, setActiveButton] = useState("");
  const classes = useStyles();
  const [selectedButton, setSelectedButton] = useState(false);
  const [D, setD] = useState(false);

  const [RactiveButton, setRactiveButton] = useState(false);
  const [Rvalue, setRvalue] = useState("");
  const [RselectedButton, setRselectedButton] = useState(false);

  const [yearValue, setYearValue] = useState("2023年");
  const [monthValue, setMonthValue] = useState(
    String(new Date().getMonth() + 1) + "月"
  );

  const [day_menu, setDayMenu] = useState([
    { label: "1日", value: "1日" },
    { label: "2日", value: "2日" },
    { label: "3日", value: "3日" },
    { label: "4日", value: "4日" },
    { label: "5日", value: "5日" },
    { label: "6日", value: "6日" },
    { label: "7日", value: "7日" },
    { label: "8日", value: "8日" },
    { label: "9日", value: "9日" },
    { label: "10日", value: "10日" },
    { label: "11日", value: "11日" },
    { label: "12日", value: "12日" },
    { label: "13日", value: "13日" },
    { label: "14日", value: "14日" },
    { label: "15日", value: "15日" },
    { label: "16日", value: "16日" },
    { label: "17日", value: "17日" },
    { label: "18日", value: "18日" },
    { label: "19日", value: "19日" },
    { label: "20日", value: "20日" },
    { label: "21日", value: "21日" },
    { label: "22日", value: "22日" },
    { label: "23日", value: "23日" },
    { label: "24日", value: "24日" },
    { label: "25日", value: "25日" },
    { label: "26日", value: "26日" },
    { label: "27日", value: "27日" },
    { label: "28日", value: "28日" },
    { label: "29日", value: "29日" },
    { label: "30日", value: "30日" },
    { label: "31日", value: "32日" },
  ]);

  const [dayValue, setDayValue] = useState(
    String(new Date().getDate() + 2) + "日"
  );

  const receive = [
    "社務所で受取",
    "後日郵送（三ヶ日明けてからの発送になります）",
  ];
  const year_menu = [
    { label: "2023年", value: "2023年" },
    { label: "2024年", value: "2024年" },
  ];

  const default_month_menu = [
    { label: "1月", value: "1月" },
    { label: "2月", value: "2月" },
    { label: "3月", value: "3月" },
    { label: "4月", value: "4月" },
    { label: "5月", value: "5月" },
    { label: "6月", value: "6月" },
    { label: "7月", value: "7月" },
    { label: "8月", value: "8月" },
    { label: "9月", value: "9月" },
    { label: "10月", value: "10月" },
    { label: "11月", value: "11月" },
    { label: "12月", value: "12月" },
  ];
  const [month_menu, setMonthMenu] = useState([
    { label: "1月", value: "1月" },
    { label: "2月", value: "2月" },
    { label: "3月", value: "3月" },
    { label: "4月", value: "4月" },
    { label: "5月", value: "5月" },
    { label: "6月", value: "6月" },
    { label: "7月", value: "7月" },
    { label: "8月", value: "8月" },
    { label: "9月", value: "9月" },
    { label: "10月", value: "10月" },
    { label: "11月", value: "11月" },
    { label: "12月", value: "12月" },
  ]);
  const DClose = () => {
    setD(false);
  };

  const DOpen = () => {
    console.log("deleteDOpen was called");
    setD(true);
  };

  const Select_handla = (value) => {
    const year = value.getFullYear();
    const month = value.getMonth();
    const day = value.getDate();
    Setselected_year(Number(year));

    Setselected_month(Number(month) + 1);
    console.log("month -> " + (Number(month) + 1));

    Setselected_day(Number(day));
    console.log(day + " set");
    // SearchTime(Number(day))
  };

  //選択した時間を取得し、そのボタンの色を変える
  const clickedButtonHandler = (index, time) => {
    setActiveButton(index);
    Setselected_time(time);
    setSelectedButton(true);
    console.log(time + "時を選択！");
  };

  const onSubmit = (action) => {
    if (action === "back") {
      props.handleBack();
    }

    if (
      selectedButton == true &&
      selected_day !== "" &&
      Rvalue !== "" &&
      RselectedButton == true
    ) {
      console.log("onSubmit was called");
      const data = {
        year: selected_year,
        month: selected_month,
        day: selected_day,
        time: selected_time,
        receive: Rvalue,
      };
      setCurrentState({ ...currentState, EasyApply2: data });
      console.log("currentState =  " + JSON.stringify(currentState));

      if (action === "next") {
        props.handleNext();
      }
    } else {
      //選択するように促すダイアログ
      DOpen();
      console.log("-------選択されていません--------");
    }
  };

  const clickedReceiveHandelHandler = (index, value) => {
    setRactiveButton(index);
    setRvalue(value);
    setRselectedButton(true);
  };

  useEffect(() => {}, [yearValue, monthValue, dayValue]);

  useLayoutEffect(() => {
    //その月の最大日数を調べる
    const year_text = yearValue.slice(0, -1);
    const month_text = monthValue.slice(0, -1);
    console.log("month_text" + month_text);
    var date = new Date(Number(year_text), Number(month_text), 0);
    console.log("date: " + date.getDate());
    const max_day_num = date.getDate();
    let month_day_dic = "";

    for (let i = 1; i <= max_day_num; i++) {
      if (i === 1) {
        month_day_dic = [{ label: "1日", value: "1日" }];
      } else {
        const day_string = String(i);
        month_day_dic.push({
          label: day_string + "日",
          value: day_string + "日",
        });
      }
    }

    setDayMenu(month_day_dic);
    HandleDisplay(yearValue, monthValue, dayValue);
  }, []);

  const HandleDisplay = (year, month, day) => {
    console.log("//////////////////// HandleDisplay was called");
    const today = new Date();
    console.log("year: " + year);
    const year_text = year.slice(0, -1);
    const month_text = month.slice(0, -1);
    const day_text = day.slice(0, -1);

    var date = new Date(Number(year_text), Number(month_text), 0);
    const max_day_num = date.getDate();
    //TODO: 今年であれば、過去の月は選択できないように制限する

    let month_dic = "";

    if (year_text === String(today.getFullYear())) {
      //月の制限

      for (let i = today.getMonth() + 1; i <= 12; i++) {
        const day_string = String(i);
        if (i == today.getMonth() + 1) {
          month_dic = [{ label: day_string + "月", value: day_string + "月" }];
        } else {
          month_dic.push({
            label: day_string + "月",
            value: day_string + "月",
          });
        }
      }
      console.log("month_dic：" + JSON.stringify(month_dic));

      setMonthMenu(month_dic);
      console.log("過去の月を制限しました");
    } else {
      console.log("別の年です");
      setMonthMenu(default_month_menu);
    }

    let day_dic = "";
    //TODO: その月が、今月or過去の月であれば、過去の日数を制限する
    console.log("month_text: " + month_text);
    console.log("today.getMonth(): " + Number(today.getMonth() + 1));
    if (Number(month_text) <= Number(today.getMonth() + 1)) {
      for (let i = today.getDate(); i <= max_day_num; i++) {
        const day_string = String(i);
        if (i === today.getDate()) {
          day_dic = [{ label: day_string + "日", value: day_string + "日" }];
        } else {
          const day_string = String(i);
          day_dic.push({
            label: day_string + "日",
            value: day_string + "日",
          });
        }
      }
      setDayMenu(day_dic);
      console.log("過去の日数を制限しました");
    } else {
      console.log("今月以降なので、日数を制限しません");
      let month_day_dic = "";
      for (let i = 1; i <= max_day_num; i++) {
        if (i === 1) {
          month_day_dic = [{ label: "1日", value: "1日" }];
        } else {
          const day_string = String(i);
          month_day_dic.push({
            label: day_string + "日",
            value: day_string + "日",
          });
        }
      }

      setDayMenu(month_day_dic);
    }
    Setselected_year(Number(year_text));
    Setselected_month(Number(month_text));
    Setselected_day(Number(day_text));
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"left"}
      // backgroundColor="#FFD36E"
    >
      <ScrollTop />
      {/* <Card sx={{ width: "90%" }}>
        <CardContent>
          <Grid>
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              日時とおふだの受取方法
            </Typography>

            <Typography sx={{ mt: 1.1, fontSize: 13 }} variant="body2">
              ・ご祈祷は約30分です。
            </Typography>
            <Typography sx={{ mt: 1.1, fontSize: 13 }} variant="body2">
              ・祭事の都合によりご予約した日時が厳しい場合は、神社から再度ご連絡します。
            </Typography>
            <Typography sx={{ mt: 1.1, fontSize: 13 }} variant="body2">
              ・拝殿（ご祈祷をする場）に上がりご祈祷を受けることができます。ご事情により上がることが厳しい場合は、ご祈祷済みのおふだを「社務所で受取」したり「郵送で受取」することができます。
            </Typography>
          </Grid>
        </CardContent>
      </Card> */}

      <Box sx={{ height: 40 }}></Box>
      <Grid>
        <Grid container justify="flex-start" alignItems="flex-start">
          <CheckCircleOutlineIcon
            style={{ color: "#ff7043", fontSize: 30 }}
          ></CheckCircleOutlineIcon>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }} ml={2}>
            日にちを選択してください
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ height: 20 }}></Box>
      {selected_time == "" && (
        <Typography sx={{ color: "red", fontSize: 16, fontWeight: "bold" }}>
          日にちが選択されていません
        </Typography>
      )}
      {selected_time !== "" && (
        <Typography
          sx={{ fontSize: 16, backgroundColor: "#f8c9a0", fontWeight: "bold" }}
          p={2}
        >
          選択した日にち：{selected_time}
        </Typography>
      )}
      {timezone.length > 1 && (
        <Grid>
          <Box sx={{ height: 20 }}></Box>
          {timezone.map((time, index) => (
            <Grid key={index}>
              <Button
                name={`name.${index}`}
                style={{
                  backgroundColor:
                    activeButton === `${index}` ? "#f8a540" : "#ffffff",
                  color: activeButton === `${index}` ? "#ffffff" : "#000000",
                  width: "250px",

                  minHeight: "40px",
                }}
                variant={"outlined"}
                onClick={() => clickedButtonHandler(`${index}`, time)}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  {time}
                </Typography>
              </Button>
              <Box sx={{ height: 10 }}></Box>
            </Grid>
          ))}
        </Grid>
      )}
      <Box sx={{ height: 40 }}></Box>
      <Divider style={{ width: "90%" }} />
      <Box sx={{ height: 40 }}></Box>
      <Grid>
        <Grid container justify="flex-start" alignItems="flex-start">
          <CheckCircleOutlineIcon
            style={{ color: "#ff7043", fontSize: 30 }}
          ></CheckCircleOutlineIcon>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }} ml={2}>
            受取方法を選択してください
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ height: 10 }}></Box>
      {Rvalue == "" && (
        <Typography sx={{ color: "red", fontSize: 16, fontWeight: "bold" }}>
          選択されていません
        </Typography>
      )}
      {Rvalue !== "" && (
        <Typography
          sx={{ fontSize: 16, backgroundColor: "#f8c9a0", fontWeight: "bold" }}
          p={2}
        >
          {Rvalue} を選択しています
        </Typography>
      )}
      <Box sx={{ height: 20 }}></Box>
      {receive.map((value, index) => (
        <Grid key={index}>
          <Button
            name={`name.${index}`}
            style={{
              backgroundColor:
                RactiveButton === `${index}` ? "#f8a540" : "#ffffff",
              color: RactiveButton === `${index}` ? "#ffffff" : "#000000",
              width: "250px",

              minHeight: "40px",
            }}
            variant={"outlined"}
            onClick={() => clickedReceiveHandelHandler(`${index}`, value)}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              {value}
            </Typography>
          </Button>
          <Box sx={{ height: 10 }}></Box>
        </Grid>
      ))}
      <Box sx={{ height: 40 }}></Box>
      <Divider style={{ width: "100%" }} />
      <Box sx={{ height: 20 }}></Box>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"center"}
      >
        <Button
          sx={{ width: "60%", height: 40 }}
          variant="contained"
          onClick={() => onSubmit("next")}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            内容の確認へ
          </Typography>
        </Button>
        <Box sx={{ height: 20 }}></Box>
        <Button
          variant="contained"
          sx={{
            width: "60%",
            height: 40,
            backgroundColor: "#ece9e9f2",
            color: "black",
          }}
          onClick={() => onSubmit("back")}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            もどる
          </Typography>
        </Button>
      </Grid>
      <Box sx={{ height: 120 }}></Box>
      <Dialog onClose={DClose} open={D}>
        <DialogTitle>選択されていません</DialogTitle>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          <Button variant="contained" color="inherit" onClick={() => DClose()}>
            戻る
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default NewYearYaku_2;
