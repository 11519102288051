import React, {
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
  Children,
} from "react";
import Button from "@mui/material/Button";
import {
  collection,
  deleteDoc,
  onSnapshot,
  addDoc,
  doc,
  query,
  getDocs,
  where,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  collectionGroup,
} from "firebase/firestore";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { db, app } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { allowContextMenu } from "@fullcalendar/core";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AlertTitle from "@mui/material/AlertTitle";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@mui/styles";
import Fab from "@mui/material/Fab";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useNavigate, Navigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { forwardRef, useRef } from "react";
import axios from "axios";
import { useKana } from "react-use-kana";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { IconButton } from "@mui/material";
import { deAT } from "date-fns/locale";
import DisplayPage from "./DisplayPage";

export default function AdminHome() {
  const [index, setIndex] = useState(0);
  const [error_text, setErrorText] = useState("");
  const { control, handleSubmit } = useForm({
    defaultValues: {
      mailBox: "",
      passwordBox: "",
    },
  });

  const useStyles = makeStyles((theme) => ({
    GoogleButton: {
      textTransform: "none",
    },
    root: {
      "& .MuiOutlinedInput-input": {
        border: 0,
        borderRadius: 3,
        fontSize: 18,
        fontWeight: "bold",
      },
    },
  }));
  const classes = useStyles();

  const onSubmit = (data) => {
    if (data.passwordBox === "0092sama") {
      console.log("admin です");
      localStorage.setItem("admin", "true");
      setIndex(1);
    } else {
      setErrorText("パスワードが違います");
    }
  };

  const Component = () => {
    if (index === 0) {
      return (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
          textAlign={"center"}
        >
          <Box sx={{ height: 40 }}></Box>
          <Typography sx={{ fontSize: 22, fontweight: "bold" }}>
            管理者ログイン
          </Typography>

          {error_text !== "" && (
            <Typography
              sx={{ fontSize: 18, fontweight: "bold", color: "red", mt: 2 }}
            >
              パスワードが違います
            </Typography>
          )}
          <Box sx={{ height: 40 }}></Box>

          <Card>
            <CardContent>
              <Grid container alignItems="center" justifyContent="center">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    direction="column"
                    textAlign={"center"}
                    mb={2}
                    sx={{ width: "260px" }}
                  >
                    <Controller
                      control={control}
                      name="passwordBox"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="パスワード"
                          margin="normal"
                          placeholder="パスワード"
                          type="password"
                          fullWidth
                          className={classes.root}
                          sx={{ width: "260px" }}
                        />
                      )}
                    />
                    <Box sx={{ height: 20 }}></Box>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#ffa94d", color: "white" }}
                      type="submit"
                      sx={{ width: "260px", height: 55 }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        認証する
                      </Typography>
                    </Button>
                    <Box sx={{ height: 20 }}></Box>
                  </Grid>
                </form>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      );
    } else if (index === 1) {
      return <DisplayPage />;
    }
  };

  useLayoutEffect(() => {
    if (localStorage.getItem("admin") === "true") {
      setIndex(1);
    }
  }, []);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Component />
    </Grid>
  );
}
