import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import tukinamigohei from "../../images/tukinamigohei.jpg";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";

export default function LineComponent() {
  const [state, setState] = useState(false);

  const toggleDrawer = () => {
    setState(!state);
  };

  const goLine = () => {
    console.log("goline");
  };

  return (
    <Grid
      container
      textAlign={"left"}
      alignItems="center"
      justifyContent="center"
    >
      <Card sx={{ width: "90%" }}>
        {/* <CardMedia
          sx={{ height: 200, padding: "1em 1em 0 1em", objectFit: "contain" }}
          image={tukinamigohei}
        /> */}
        {/* <CardActionArea onClick={() => toggleDrawer()}> */}
        <Typography
          gutterBottom
          component="div"
          sx={{ fontSize: 15, fontWeight: "bold" }}
          m={0.5}
          ml={1}
        >
          公式LINEを追加して好きな時にアプリを開く
        </Typography>
        {state && (
          <Grid m={1}>
            <Box mt={2} mb={2}>
              <img src={tukinamigohei} width={"100%"}></img>
            </Box>
            <Typography sx={{ fontSize: 14 }}>
              　毎月1日の朝に行う月次祭で、皆様のひと月のご安全と願いが実るようご祈祷をこめた「色御幣」です。
            </Typography>
            <Typography sx={{ mt: 1, fontSize: 14 }}>
              季節に合わせた襲（かさね）の色でお作りしています。
            </Typography>
            <Typography sx={{ mt: 1, fontSize: 14 }}>
              拝殿前、もしくは授与所(お守りのお配り場所)にてお声がけください。
            </Typography>
            <Typography sx={{ mt: 1, fontSize: 14 }}>お初穂料：千円</Typography>
          </Grid>
        )}
        {!state && (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            pr={1}
            pb={1}
          >
            <Button
              size="small"
              onClick={() => window.open("https://lin.ee/wD9P918")}
            >
              <Typography sx={{ fontWeight: "bold" }}>追加する</Typography>
            </Button>
          </Grid>
        )}
        {/* {state && (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            pr={1}
            pb={1}
          >
            <Button size="small" onClick={() => toggleDrawer()} mb={2}>
              <Typography sx={{ color: "#ffa94d", fontWeight: "bold" }}>
                とじる
              </Typography>
            </Button>
          </Grid>
        )}
        {!state && (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            pr={1}
            pb={1}
          >
            <Button size="small" onClick={() => toggleDrawer()} mb={2}>
              <Typography sx={{ color: "#ffa94d", fontWeight: "bold" }}>
                詳細をみる
              </Typography>
            </Button>
          </Grid>
        )} */}
        {/* </CardActionArea> */}
      </Card>
    </Grid>
  );
}
