import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import { useNavigate, Navigate, Link } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TextField from "@mui/material/TextField";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import pic3 from "../../images/three.jpg";
import pic5 from "../../images/five.jpg";
import pic8 from "../../images/eight.jpg";
import pic1 from "../../images/one.jpg";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useForm, Controller } from "react-hook-form";
import Fab from "@mui/material/Fab";
import { Typography } from "@mui/material";

export default function OfudaImageList() {
  const [imageD, setImageD] = useState(false);
  const [target, setTarget] = useState(pic3);

  useEffect(() => {}, [target]);

  const imageDOpen = () => {
    setImageD(true);
  };
  const imageDClose = () => {
    setImageD(false);
  };
  const useStyles = makeStyles((theme) => ({
    fab: {
      position: "fixed",
      margin: 0,
      top: 100,
      right: 20,
      bottom: "auto",
    },
  }));
  const classes = useStyles();

  const imageOnClick = (name) => {
    if (name === "三千円のおふだ") {
      setTarget(pic3);
    } else if (name === "五千円のおふだ") {
      setTarget(pic5);
    } else if (name === "壱万円のおふだ") {
      setTarget(pic1);
    }
    imageDOpen();
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"center"}
    >
      <ImageList sx={{ width: "90%", height: 250 }} cols={3} rowHeight={180}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              onClick={() => imageOnClick(item.title)}
            />
            <ImageListItemBar
              title={
                <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
                  {item.title}
                </Typography>
              }
              subtitle={
                <Typography style={{ color: "gray", fontSize: 16 }}>
                  {item.author}
                </Typography>
              }
              position="below"
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Dialog onClose={imageDClose} open={imageD}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          <Box sx={{ height: 40 }}></Box>
          <img src={target} alt="pic" style={{ width: "90%" }} />
          <Box sx={{ height: 40 }}></Box>
        </Grid>
        <Box className={classes.fab}>
          <Fab aria-label="close" onClick={() => imageDClose()} color="primary">
            <CloseIcon></CloseIcon>
          </Fab>
        </Box>
      </Dialog>
    </Grid>
  );
}

const itemData = [
  {
    img: pic3,
    title: "三千円のおふだ",
    author: "普通:28cm 小:24cm",
  },
  {
    img: pic5,
    title: "五千円のおふだ",
    author: "普通:39cm 小:30cm",
  },
  {
    img: pic1,
    title: "壱万円のおふだ",
    author: "普通:60cm 小:30cm",
  },
];
