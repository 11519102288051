import { React, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Fab from "@mui/material/Fab";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

const Negai = (props) => {
  const useStyles = makeStyles((theme) => ({
    fab: {
      position: "fixed",
      margin: 0,
      bottom: "auto",
      right: 20,
      top: 20,
    },
  }));
  const classes = useStyles();

  const handleCloseUpdate = () => {
    props.handleCloseUpdate();
  };

  return (
    <Grid
      container
      alignItems="left"
      justify="center"
      direction="column"
      textAlign={"left"}
    >
      <Box sx={{ height: 70 }}></Box>

      <Typography sx={{ mt: 1.1, ml: 3, fontSize: 18 }} variant="body2">
        家内安全(かないあんぜん)
      </Typography>
      <Typography
        sx={{ mt: 1.1, mb: 2, ml: 3, mr: 3, fontSize: 15 }}
        variant="body2"
      >
        火災や盗難などの災いが無く、家族仲良く笑顔のあふれる家庭でありますように
      </Typography>
      <Divider sx={{ width: "100%" }} mt={3}></Divider>
      <Typography sx={{ mt: 1.1, ml: 3, fontSize: 18 }} variant="body2">
        身体堅固(しんたいけんご)
      </Typography>

      <Typography
        sx={{ mt: 1.1, mb: 2, ml: 3, mr: 3, fontSize: 15 }}
        variant="body2"
      >
        病気や怪我が無く、心身ともに健康でありますように
      </Typography>
      <Divider sx={{ width: "100%" }} mt={3}></Divider>

      <Typography sx={{ mt: 1.1, ml: 3, fontSize: 18 }} variant="body2">
        交通安全(こうつうあんぜん)
      </Typography>
      <Typography
        sx={{ mt: 1.1, mb: 2, ml: 3, mr: 3, fontSize: 15 }}
        variant="body2"
      >
        事故が無く、安全な運転を心がけ、通勤・通学に支障なく生活できますように
      </Typography>
      <Divider sx={{ width: "100%" }} mt={3}></Divider>
      <Typography sx={{ mt: 1.1, ml: 3, fontSize: 18 }} variant="body2">
        社内安全(しゃないあんぜん)
      </Typography>
      <Typography
        sx={{ mt: 1.1, mb: 2, ml: 3, mr: 3, fontSize: 15 }}
        variant="body2"
      >
        社員、またそれぞれの家族にも、健康で業務を行えますように
      </Typography>
      <Divider sx={{ width: "100%" }} mt={3}></Divider>
      <Typography sx={{ mt: 1.1, ml: 3, fontSize: 18 }} variant="body2">
        商売繁盛(しょうばいはんじょう)
      </Typography>
      <Typography
        sx={{ mt: 1.1, mb: 2, ml: 3, mr: 3, fontSize: 15 }}
        variant="body2"
      >
        ご商売での交流が広がり、より良い取り引きが叶いますように
      </Typography>

      <Divider sx={{ width: "100%" }} mt={3}></Divider>
      <Typography sx={{ mt: 1.1, ml: 3, fontSize: 18 }} variant="body2">
        當病平癒(とうびょうへいゆ)
      </Typography>
      <Typography
        sx={{ mt: 1.1, mb: 2, ml: 3, mr: 3, fontSize: 15 }}
        variant="body2"
      >
        身にかかる怪我や病気が一日でも早く良くなりますよう、また痛みや苦しみから救われますように
      </Typography>

      <Divider sx={{ width: "100%" }} mt={3}></Divider>
      <Typography sx={{ mt: 1.1, ml: 3, fontSize: 18 }} variant="body2">
        厄災悉除(やくさいしつじょ)
      </Typography>
      <Typography
        sx={{ mt: 1.1, mb: 2, ml: 3, mr: 3, fontSize: 15 }}
        variant="body2"
      >
        厄年(やくどし)や方位に該当する年ではないが、嫌なことや不安なことが重なることを防げますように
      </Typography>
      <Divider sx={{ width: "100%" }} mt={3}></Divider>
      <Typography sx={{ mt: 1.1, ml: 3, fontSize: 18 }} variant="body2">
        良縁成就(りょうえんじょうじゅ)
      </Typography>
      <Typography
        sx={{ mt: 1.1, mb: 2, ml: 3, mr: 3, fontSize: 15 }}
        variant="body2"
      >
        なかなか機会に恵まれない男女の縁、縁結びのご利益を得られますように
      </Typography>
      <Divider sx={{ width: "100%" }} mt={3}></Divider>
      <Typography sx={{ mt: 1.1, ml: 3, fontSize: 18 }} variant="body2">
        学芸伸長(がくげいしんちょう)
      </Typography>
      <Typography
        sx={{ mt: 1.1, mb: 2, ml: 3, mr: 3, fontSize: 15 }}
        variant="body2"
      >
        学習や学芸（文芸や音楽、スポーツ）にますます励み成長できますように
      </Typography>
      <Divider sx={{ width: "100%" }} mt={3}></Divider>
      <Typography sx={{ mt: 1.1, ml: 3, fontSize: 18 }} variant="body2">
        開運招福(かいうんしょうふく)
      </Typography>
      <Typography
        sx={{ mt: 1.1, mb: 2, ml: 3, mr: 3, fontSize: 15 }}
        variant="body2"
      >
        心身ともに満ち足りた活力のある生活をおくり、心機一転生活を改善することができますように
      </Typography>
      <Box sx={{ height: 50 }}></Box>

      <Box className={classes.fab}>
        <Fab
          aria-label="close"
          onClick={() => handleCloseUpdate()}
          color="primary"
        >
          <CloseIcon></CloseIcon>
        </Fab>
      </Box>
    </Grid>
  );
};

export default Negai;
