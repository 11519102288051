import { React, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Fab from "@mui/material/Fab";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";

const AccountsDetail = (props) => {
  const sex = ["男性", "女性"];
  const year = [
    "1923年",
    "1924年",
    "1925年",
    "1926年",
    "1927年",
    "1928年",
    "1929年",
    "1930年",
    "1931年",
    "1932年",
    "1933年",
    "1934年",
    "1935年",
    "1936年",
    "1937年",
    "1938年",
    "1939年",
    "1940年",
    "1941年",
    "1942年",
    "1943年",
    "1944年",
    "1945年",
    "1946年",
    "1947年",
    "1948年",
    "1949年",
    "1950年",
    "1951年",
    "1952年",
    "1953年",
    "1954年",
    "1955年",
    "1956年",
    "1957年",
    "1958年",
    "1959年",
    "1960年",
    "1961年",
    "1962年",
    "1963年",
    "1964年",
    "1965年",
    "1966年",
    "1967年",
    "1968年",
    "1969年",
    "1970年",
    "1971年",
    "1972年",
    "1973年",
    "1974年",
    "1975年",
    "1976年",
    "1977年",
    "1978年",
    "1979年",
    "1980年",
    "1981年",
    "1982年",
    "1983年",
    "1984年",
    "1985年",
    "1986年",
    "1987年",
    "1988年",
    "1989年",
    "1990年",
    "1991年",
    "1992年",
    "1993年",
    "1994年",
    "1995年",
    "1996年",
    "1997年",
    "1998年",
    "1999年",
    "2000年",
    "2001年",
    "2002年",
    "2003年",
    "2004年",
    "2005年",
  ];
  const { register, handleSubmit, getValues } = useForm();
  const accounts = props.accounts;
  const dialog_index = props.dialog_index;
  console.log("dialog_index: " + dialog_index);

  console.log("accounts: " + JSON.stringify(accounts));

  const useStyles = makeStyles((theme) => ({
    fab: {
      position: "fixed",
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 20,
    },
    root: {
      "& .MuiOutlinedInput-input": {
        border: 0,
        borderRadius: 3,
        fontSize: 18,
        fontWeight: "bold",
      },
    },
  }));
  const classes = useStyles();
  const [sex_value, setSexValue] = useState(accounts[dialog_index]["sex"]);
  const [input_sex, setInputSex] = useState(accounts[dialog_index]["sex"]);

  const [year_value, setYear] = useState(accounts[dialog_index]["birthday"]);
  const [input_year, setInputYear] = useState(
    accounts[dialog_index]["birthday"]
  );

  const updateDB = () => {
    const value = getValues();
    value.birthday = input_year;
    value.sex = input_sex;
    props.updateDB(value);
  };

  const deleteDOpen = () => {
    props.deleteDOpen();
  };

  const handleCloseUpdate = () => {
    props.handleCloseUpdate();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(updateDB)}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"left"}
        >
          <Grid container alignItems="flex-end" justifyContent={"flex-end"}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                width: "40%",
                height: 55,
                my: 2,
                mr: 2,
              }}
              onClick={() => deleteDOpen()}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                削除する
              </Typography>
            </Button>
          </Grid>
          <Box sx={{ my: 3, mx: 2, mt: 1 }}>
            <Box sx={{ height: 30 }}></Box>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                お名前
              </Typography>
              <TextField
                variant="outlined"
                sx={{ width: 290 }}
                type="text"
                className={classes.root}
                defaultValue={accounts[dialog_index]["name"]}
                {...register("nameUpdate", { required: true })}
              ></TextField>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                フリガナ
              </Typography>
              <TextField
                sx={{ width: 290 }}
                variant="outlined"
                type="text"
                className={classes.root}
                defaultValue={accounts[dialog_index]["name_furi"]}
                {...register("name_furiUpdate", { required: true })}
              ></TextField>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography mb={1} sx={{ fontSize: 16, fontWeight: "bold" }}>
                誕生年と性別
              </Typography>
              <Grid container sx={{ width: "100%" }}>
                <Autocomplete
                  value={year_value}
                  inputValue={input_year}
                  onChange={(event, newValue) => {
                    setYear(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputYear(newInputValue);
                    setYear(newInputValue);
                  }}
                  name="birthday"
                  options={year}
                  sx={{ width: 140, mr: 1 }}
                  renderInput={({ inputProps, ...rest }) => (
                    <TextField
                      {...rest}
                      className={classes.root}
                      inputProps={{ ...inputProps, readOnly: true }}
                      label="生まれた年"
                    />
                  )}
                  {...register("birthday", { required: true })}
                />
                <Box
                  sx={{ height: 10 }}
                  style={{ backgroundColor: "red" }}
                ></Box>
                <Autocomplete
                  value={sex_value}
                  inputValue={sex_value}
                  onChange={(event, newValue) => {
                    setSexValue(newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    setInputSex(newValue);
                    setSexValue(newValue);
                  }}
                  name="sex"
                  options={sex}
                  sx={{ width: 140 }}
                  renderInput={({ inputProps, ...rest }) => (
                    <TextField
                      {...rest}
                      className={classes.root}
                      inputProps={{ ...inputProps, readOnly: true }}
                      label="性別"
                    />
                  )}
                  {...register("sex", { required: true })}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                郵便番号
              </Typography>
              <TextField
                sx={{ width: 290 }}
                variant="outlined"
                type="text"
                className={classes.root}
                defaultValue={accounts[dialog_index]["postcode"]}
                {...register("postcodeUpdate", { required: true })}
              ></TextField>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                住所
              </Typography>
              <TextField
                sx={{ width: 290 }}
                variant="outlined"
                type="text"
                className={classes.root}
                defaultValue={accounts[dialog_index]["adress"]}
                {...register("adressUpdate", { required: true })}
              ></TextField>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                フリガナ(市町村まで)
              </Typography>
              <TextField
                sx={{ width: 290 }}
                variant="outlined"
                type="text"
                className={classes.root}
                defaultValue={accounts[dialog_index]["adress_furi"]}
                {...register("adress_furiUpdate", { required: true })}
              ></TextField>
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              textAlign={"center"}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#ffa94d",
                  color: "white",
                  width: "60%",
                  height: 55,
                  mb: 2,
                }}
                onClick={() => updateDB()}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  更新する
                </Typography>
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#ece9e9f2",
                  color: "black",
                  width: "60%",
                  height: 55,
                  mb: 2,
                }}
                onClick={() => handleCloseUpdate()}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  もどる
                </Typography>
              </Button>
            </Grid>
          </Box>
          <Box sx={{ height: 150 }}></Box>
        </Grid>
      </form>
    </div>
  );
};

export default AccountsDetail;
