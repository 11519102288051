/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useLayoutEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SignUp from "./ Component/Signup.js";
import Gokigan from "./ Component/Gokigan.js";
import Login from "./ Component/Login.js";
import Accounts from "./ Component/Accounts.js";
import Card from "@mui/material/Card";
import { AuthProvider } from "./context/ AuthContext";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import PublicRoute from "./ Component/PublicRoute.js";
import PrivateRoute from "./ Component/PrivateRoute.js";
import Thanks from "./ Component/Thanks.js";
import EasyApplyPage from "./ Component/EasyApplyPage.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ScrollToTop from "react-scroll-to-top";
import ScrollTop from "./helper/ScrollTop.js";
import Privacy from "./ Component/ Policy/ Plivacy.js";
import Gosyuin from "./ Component/Gosyuin/Gosyuin.js";
import SinupComplete from "./ Component/Sub/SignupComplete.js";
import LoginComplete from "./ Component/Sub/LoginComplete.js";
import LogoutComplete from "./ Component/Sub/LogoutComplete.js";
import SignupComplete from "./ Component/Sub/SignupComplete.js";
import BaseOfHatumiya from "./ Component/Hatumiya/BaseOfHatumiya.js";
import BaseOfAnzan from "./ Component/AnzanApply/BaseOfAnzan.js";
import BaseOfCar from "./ Component/CarApply/BaseOfCar.js";
import BaseOfGoukaku from "./ Component/GoukakuApply/BaseOfGoukaku.js";
import BaseOfYakubarai from "./ Component/Yakubarai/BaseOfYakubarai.js";
import Load from "./LoadComponent/Load.js";
import DialyHome from "./DialyComponent/Page/DialyHome.js";
import LogHistory from "./DialyComponent/Page/LogHistory.js";
import AchievePage from "./AchieveComponent/AchievePage.js";
import GosyuinLoad from "./LoadComponent/GosyuinLoad.js";
import KamidanaLoad from "./LoadComponent/KamidanaLoad.js";
import { useAuthContext } from "./context/ AuthContext";
import QuizHome from "./QuizComponent/Page/QuizHome.js";
import SelectPage from "./QuizComponent/Page/SelectPage.js";
import SettingHome from "./SettingConponent/pages/SettingHome.js";
import { useNavigate, Navigate, Link } from "react-router-dom";
import KamidanaHome from "./KamidanaComponent/page/KamidanaHome.js";
import Walkthrough from "./ Component/Sub/WalkthroughPage.js";
import Camera from "./AchieveComponent/Camera.js";
import KonroomHome from "./KonroomComponent/KonroomHome.js";
import AdminHome from "./AdminPage/AdminHome.js";
import NewYearApplyPage from "./ Component/NewYearApply/NewYearApplyPage.js";
import NewYearYakuPage from "./ Component/NewYearYaku/NewYearYakuPage.js";

import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import { getAuth } from "firebase/auth";

const { palette } = createTheme();
const { augmentColor } = palette;

const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

// フォントを設定
const fontFamily = ["Klee One, cursive"].join(",");

const theme = createTheme({
  typography: {
    fontFamily: fontFamily, // フォント
  },
  palette: {
    mode: "light",
    primary: {
      contrastText: "#fff",
      light: "#ffa94d",
      main: "#ff922b",
      dark: "#ff922b",
    },
    secondary: {
      light: "#ff9e40",
      main: "#ff922b",
      dark: "#ff922b",
    },
  },
  components: {
    MuiLocalizationProvider: {
      defaultProps: {
        localeText: {
          cancelButtonLabel: "キャンセル",
          okButtonLabel: "選択",
          fontSize: 18,
        },
      },
    },
    "& .PrivatePickersYear-yearButton": {
      fontSize: "20px",
    },
  },
});

function App() {
  const { user } = useAuthContext();

  const [mail_v, Setmail_v] = useState("");
  const [pass_v, Setpass_v] = useState("");
  const [applyed_user, SetApplyed_user] = useState(false);
  const [accounts, setAccounts] = useState("");
  const [type, setType] = useState("");

  //日記
  const [dialy_alldeta, setDialy_alldeta] = useState("");
  const [ids_deta, setIds_deta] = useState("");
  const [document_id, setDocument_id] = useState("");
  const [reload, setReload] = useState("");

  useEffect(() => {
    SetApplyed_user(false);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <ScrollToTop smooth></ScrollToTop> */}
      <BrowserRouter>
        <Routes>
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <AdminHome />
              </PrivateRoute>
            }
          />
          <Route path="/logincomplete" element={<LoginComplete />} />
          <Route path="/signupcomplete" element={<SignupComplete />} />
          <Route path="/logoutcomplete" element={<LogoutComplete />} />
          <Route path="/gosyuin" element={<Gosyuin accounts={accounts} />} />
          <Route path="/terms" element={<Privacy />} />
          <Route path="/room" element={<KonroomHome />} />

          <Route
            path="/apply1"
            element={
              <PrivateRoute>
                <EasyApplyPage accounts={accounts} />
              </PrivateRoute>
            }
          />
          <Route
            path="/apply2"
            element={
              <PrivateRoute>
                <BaseOfAnzan accounts={accounts} type={type} />
              </PrivateRoute>
            }
          />
          <Route
            path="/apply3"
            element={
              <PrivateRoute>
                <BaseOfHatumiya accounts={accounts} type={type} />
              </PrivateRoute>
            }
          />
          <Route
            path="/apply4"
            element={
              <PrivateRoute>
                <BaseOfCar accounts={accounts} type={type} />
              </PrivateRoute>
            }
          />
          <Route
            path="/apply5"
            element={
              <PrivateRoute>
                <BaseOfGoukaku accounts={accounts} type={type} />
              </PrivateRoute>
            }
          />
          <Route
            path="/apply6"
            element={
              <PrivateRoute>
                <BaseOfYakubarai accounts={accounts} type={type} />
              </PrivateRoute>
            }
          />
          <Route
            path="/newyear"
            element={
              <PrivateRoute>
                <NewYearApplyPage accounts={accounts} type={type} />
              </PrivateRoute>
            }
          />
          <Route
            path="/newyearyaku"
            element={
              <PrivateRoute>
                <NewYearYakuPage accounts={accounts} type={type} />
              </PrivateRoute>
            }
          />

          <Route
            path="/accounts"
            element={
              <PrivateRoute>
                <Accounts accounts={accounts} />
              </PrivateRoute>
            }
          />
          <Route
            path="/kigan"
            element={
              <Gokigan
                SetApplyed_user={SetApplyed_user}
                setAccounts={setAccounts}
                setType={setType}
              />
            }
          />
          <Route path="/kamidana" element={<KamidanaHome />} />
          <Route path="/welcome" element={<Walkthrough />} />
          {/* <Route path="/camera" element={<Camera />} /> */}

          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />{" "}
              </PublicRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <PublicRoute>
                <SignUp />
              </PublicRoute>
            }
          />
          <Route
            path="/thanks"
            element={
              <PrivateRoute>
                <Thanks />
              </PrivateRoute>
            }
          />
          <Route
            path="/load"
            element={<Load accounts={accounts} type={type} />}
          />
          <Route
            path="/loadgosyuin"
            element={<GosyuinLoad accounts={accounts} type={type} />}
          />
          <Route
            path="/loadkamidana"
            element={<KamidanaLoad accounts={accounts} type={type} />}
          />
          <Route
            path="/dialy"
            element={
              <DialyHome
                accounts={accounts}
                type={type}
                setDialy_alldeta={setDialy_alldeta}
                setIds_deta={setIds_deta}
                setDocument_id={setDocument_id}
              />
            }
          />
          <Route
            path="/alldialy"
            element={
              <LogHistory
                accounts={accounts}
                dialy_alldeta={dialy_alldeta}
                ids_deta={ids_deta}
                document_id={document_id}
                setReload={setReload}
              />
            }
          />
          <Route path="/" element={<AchievePage></AchievePage>}></Route>
          <Route path="/home" element={<AchievePage></AchievePage>}></Route>
          <Route path="/quiz" element={<QuizHome></QuizHome>}></Route>
          <Route path="/selectquiz" element={<SelectPage></SelectPage>}></Route>
          <Route path="/setting" element={<SettingHome></SettingHome>}></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
