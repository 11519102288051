import React, {
  useState,
  useContext,
  useLayoutEffect,
  useEffect,
  useRef,
} from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useAuthContext } from "../../context/ AuthContext";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import {
  CardActionArea,
  Fab,
  IconButton,
  Typography,
  fabClasses,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { Progress } from "semantic-ui-react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckIcon from "@mui/icons-material/Check";
import syokyu from "../../images/syokyu.jpg";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  updateDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getAuth } from "firebase/auth";
import { Player } from "@lottiefiles/react-lottie-player";
import correct from "../../movies/correct.json";
import wrong from "../../movies/wrong.json";
import complete from "../../movies/complete.json";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Dialog from "@mui/material/Dialog";
import magatama from "../../images/magatama.png";
import ex from "../../images/ex.png";
import { Typewriter } from "react-simple-typewriter";
import sleepy_fox from "../../images/sleepy_fox.png";
import happy_fox from "../../images/happy_fox.png";
import NumberEasing from "react-number-easing";
import LinearProgress from "@mui/material/LinearProgress";
import { motion } from "framer-motion";

const AnswerPage = (props) => {
  const navigate = useNavigate();

  const { user } = useAuthContext();
  const alldeta = props.quiz_deta;
  const session_index = props.session_index;
  const quiz_progress = props.quiz_progress;
  let completed_session;
  if (quiz_progress !== null) {
    completed_session = quiz_progress["session"];
  }

  let addmagatama_count = 0;
  if (props.session_index + 1 > completed_session) {
    addmagatama_count = 3;
  } else {
    addmagatama_count = 1;
  }

  const [session_deta, SetSessionDeta] = useState("");

  const [tap1, SetTap1] = useState(false);
  const [tap2, SetTap2] = useState(false);
  const [tap3, SetTap3] = useState(false);
  const [tap4, SetTap4] = useState(false);
  const [selected_index, SetSelectedIndex] = useState(0);
  const [magatama_count, setmagatama_Count] = useState(props.magatama_count);
  const [magatamaD, setmagatamaD] = useState(false);
  const [addmagatamaD, setaddmagatamaD] = useState(false);

  const [collect, SetCollect] = useState(false);
  const [disc_Comp, SetDisc_Comp] = useState(false);
  const [question_index, SetQuestion_Index] = useState(0);
  const [complete_Comp, SetComplete_Comp] = useState(false);
  const [wrong_count, set_wrong_count] = useState(0);
  const [accountD, setAccountD] = useState(false);
  const [good_loading, set_good_Loading] = useState(false);
  const [wrong_loading, set_wrong_Loading] = useState(false); //TODO Lottie表示
  const [complete_loading, set_complete_Loading] = useState(false);
  const [typedone, set_typedone] = useState(false);

  //経験値
  const [ex_count, set_ex_count] = useState(0);
  const [showEx, set_showEx] = useState(false);

  const [counter, set_counter] = useState(0);

  const player1 = useRef(null);
  const [correct_lottie, set_correct_Lottie] = useState(null);
  const player2 = useRef(null);
  const [wrong_lottie, set_wrong_lottie] = useState(null);
  const player3 = useRef(null);
  const [complete_lottie, set_complete_lottie] = useState(null);

  const [heart_arr, set_heart_arr] = useState([
    <FavoriteIcon sx={{ fontSize: 24, color: "red " }} />,
    <FavoriteIcon sx={{ fontSize: 24, color: "red " }} />,
    <FavoriteIcon sx={{ fontSize: 24, color: "red " }} />,
    <FavoriteIcon sx={{ fontSize: 24, color: "red " }} />,
  ]);

  useLayoutEffect(() => {
    if (props.db_deta !== null) {
      if (props.db_deta["values"][0]["ex"] !== null) {
        set_ex_count(props.db_deta["values"][0]["ex"]);
      } else {
        set_ex_count(0);
      }
    }
  }, []);

  useEffect(() => {
    if (correct_lottie) {
      correct_lottie.play();
    }
  }, [correct_lottie]);

  useEffect(() => {
    if (wrong_lottie) {
      wrong_lottie.play();
    }
  }, [wrong_lottie]);

  useEffect(() => {
    if (complete_lottie) {
      complete_lottie.play();
    }
  }, [complete_lottie]);

  const goSignup = () => {
    navigate("/signup");
  };

  const accountDclose = () => {
    setAccountD(false);
  };

  const accountDopen = () => {
    setAccountD(true);
  };

  const useStyles = makeStyles((theme) => ({
    lottie: {
      position: "fixed",
      top: "10%",
      left: "50%",
      transform: "translate(-50%, -10%)",
      zIndex: 2,
    },
    correct: {
      position: "fixed",
      top: "3%",
      left: "50%",
      transform: "translate(-50%, -3%)",
      zIndex: 2,
    },
    bottom_button: {
      position: "fixed",
      bottom: "1%",
      left: "50%",
      transform: "translate(-50%, -1%)",
    },
    extext: {
      position: "fixed",
      top: "20%",
    },

    complete_button: {
      position: "fixed",
      bottom: "10%",
      left: "50%",
      transform: "translate(-50%, -10%)",
    },
    complete: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));
  const classes = useStyles();

  useEffect(() => {}, [
    tap1,
    tap2,
    tap3,
    tap4,
    good_loading,
    wrong_loading,
    counter,
  ]);

  useEffect(() => {
    let category_alldeta = [];
    for (
      let i = props.category_index * 25;
      i < props.category_index * 25 + 25;
      i++
    ) {
      category_alldeta.push(alldeta[i]);
    }

    // console.log("categpry_deta: " + JSON.stringify(category_alldeta));

    const start_index = 5 * session_index;
    let arr = [];
    for (let i = start_index; i < start_index + 5; i++) {
      arr.push(category_alldeta[i]);
    }
    SetSessionDeta(arr);
    console.log("sessionDeta: " + arr);
  }, []);

  const TapHandler = (index) => {
    console.log("タップ: " + index);
    if (
      (index === 1 && tap1 === true) ||
      (index === 2 && tap2 === true) ||
      (index === 3 && tap3 === true) ||
      (index === 4 && tap4 === true)
    ) {
      SetTap1(false);
      SetTap2(false);
      SetTap3(false);
      SetTap4(false);
      SetSelectedIndex(0);
      return;
    }

    SetTap1(false);
    SetTap2(false);
    SetTap3(false);
    SetTap4(false);

    SetSelectedIndex(index);

    if (index === 1) {
      SetTap1(!tap1);
    } else if (index === 2) {
      SetTap2(!tap2);
    } else if (index === 3) {
      SetTap3(!tap3);
    } else if (index === 4) {
      SetTap4(!tap4);
    }
  };

  const AnswerHandler = () => {
    if (selected_index !== 0) {
      //ここで回答があっているかどうか判別する
      console.log("selected_index: " + selected_index);
      console.log("correct_answer: " + session_deta[question_index][10]);
      if (selected_index == session_deta[question_index][10]) {
        console.log("正解");
        SetCollect(true);
        set_good_Loading(true);
        showExmoment();
        set_ex_count(ex_count + 15);
      } else {
        console.log("間違い");
        SetCollect(false);
        set_wrong_Loading(true);
        set_wrong_count(wrong_count + 1);
        const new_count = wrong_count + 1;
        const new_heart = heart_arr;

        new_heart.splice(
          4 - new_count,
          1,
          <FavoriteBorderIcon sx={{ fontSize: 28, color: "red " }} />
        );

        set_heart_arr(new_heart);
      }

      //結果のコンポーネントと解説を表示する
      SetDisc_Comp(true);
      SetTap1(false);
      SetTap2(false);
      SetTap3(false);
      SetTap4(false);
      SetSelectedIndex(0);
      console.log("selectedIndexをリセットしました");
    } else {
      console.log("選択していません");
    }
  };

  const NextHandler = () => {
    if (good_loading || wrong_loading || !typedone) {
      return;
    }

    if (wrong_count === 4) {
      console.log("４つ間違えました");
      togglemagatamaD(true);
      return;
    }
    if (question_index < 4) {
      console.log("question_index: " + question_index);
      SetQuestion_Index(question_index + 1);
      SetDisc_Comp(false);
    } else {
      // addmagatama();
      UpdateQuizProgress();
      set_complete_Loading(true);
      SetComplete_Comp(true);

      if (user == null) {
        console.log("userがいません");
        return;
      }

      //マガタマを１本追加
      // setaddmagatamaD(true);
    }

    set_typedone(false);
  };

  //DBのクイズ進捗データを更新する
  const UpdateQuizProgress = async () => {
    if (user == null) {
      setTimeout(() => {
        accountDopen(true);
      }, 2000);
      console.log("userがいません");
      return;
    }

    //現在の進捗状況
    const quiz_progress = props.quiz_progress;
    const docid = props.docid;

    console.log("quiz_progress: " + JSON.stringify(quiz_progress));

    if (quiz_progress !== null && typeof quiz_progress !== "undefined") {
      const completed_session = quiz_progress["session"];
      console.log("Completed session: " + completed_session);
      console.log("Selected session: " + props.session_index + 1);

      if (props.session_index + 1 > completed_session) {
        console.log("docid: " + docid);
        try {
          const quizRef = doc(db, "account_list", docid, "quiz", "progress");
          //[初級、セクション]
          const deta = {
            level: 1,
            session: props.session_index + 1,
          };
          console.log(quizRef);
          //進捗を追加
          await setDoc(quizRef, deta);
        } catch (e) {
          console.log(e.message);
        }

        console.log("GetQuizProgress at AnswerPage");
      } else {
        console.log("there was no update");
      }
    } else {
      const quizRef = doc(db, "account_list", docid, "quiz", "progress");
      //[初級、セクション]
      const deta = {
        level: 1,
        session: props.session_index + 1,
      };
      //進捗を追加
      await setDoc(quizRef, deta);
    }
    // //TODO - DBからクエリする
    // props.GetQuizProgress();
    // props.onClose();
    // props.CloseSelectPage();
  };

  const togglemagatamaD = (open) => {
    setmagatamaD(open);
  };

  const toggleAddmagatamaD = (open) => {
    setaddmagatamaD(open);
  };

  const addmagatama = async () => {
    if (props.quiz_progress == null) {
      return;
    }
    let new_magatama = 1;
    const quiz_progress = props.quiz_progress;
    const completed_session = quiz_progress["session"];

    console.log("-----------       addmagatamaCount " + addmagatama_count);
    if (typeof props.magatama_count === "number") {
      new_magatama = props.magatama_count + addmagatama_count;
    }

    console.log(new_magatama);

    const docRef = doc(db, "account_list", props.docid);
    const deta = { dango: new_magatama };
    const deta_dic = { values: [{ dango: new_magatama, ex: ex_count }] };
    try {
      await updateDoc(docRef, deta);
      await updateDoc(docRef, deta_dic);
    } catch (e) {
      console.log("error" + e);
    }
  };

  const minusmagatama = async () => {
    let new_magatama = 1;
    if (props.magatama_count === 0) {
      return;
    }

    if (typeof props.magatama_count === "number") {
      new_magatama = props.magatama_count - 1;
    }
    console.log(new_magatama);

    const docRef = doc(db, "account_list", props.docid);
    const deta = { dango: new_magatama };
    try {
      await updateDoc(docRef, deta);
    } catch (e) {
      console.log("error" + e);
    }
  };

  const BackMenu = () => {
    props.GetQuizProgress();
    togglemagatamaD(false, 0);
    props.onClose();
  };

  const handleDone = () => {
    set_typedone(true);
  };

  const showExmoment = () => {
    set_showEx(true);
    setTimeout(() => {
      set_showEx(false);
    }, 3000);
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      textAlign={"center"}
      sx={{ height: "100%" }}
    >
      {/* {showEx && (
        <Grid className={classes.extext} sx={{ width: "100%", zIndex: 3 }}>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            animate={{ y: "-100px" }}
          >
            <Typography sx={{ fontSize: 55, fontWeight: "bold" }}>
              + 15
            </Typography>
          </motion.div>
        </Grid>
      )} */}
      {good_loading && (
        <Player
          lottieRef={(data) => {
            set_correct_Lottie(data);
          }}
          onEvent={(event) => {
            if (event === "complete") {
              correct_lottie.stop();
              set_good_Loading(false);
            }
          }}
          ref={player1}
          src={correct}
          keepLastFrame={true}
          loop={false}
          autoPlay={false}
          style={{ height: "300px", width: "300px" }}
          className={classes.correct}
          speed={2.5}
        />
      )}

      {wrong_loading && (
        <Player
          lottieRef={(data) => {
            set_wrong_lottie(data);
          }}
          onEvent={(event) => {
            if (event === "complete") {
              wrong_lottie.stop();
              set_wrong_Loading(false);
            }
          }}
          ref={player2}
          src={wrong}
          keepLastFrame={true}
          loop={false}
          autoPlay={false}
          style={{ height: "160px", width: "160px" }}
          className={classes.lottie}
          speed={2}
        />
      )}

      {complete_loading && (
        <Player
          lottieRef={(data) => {
            set_complete_lottie(data);
          }}
          onEvent={(event) => {
            if (event === "complete") {
              complete_lottie.stop();
              set_complete_Loading(false);
            }
          }}
          ref={player3}
          src={complete}
          keepLastFrame={true}
          loop={false}
          autoPlay={false}
          style={{ height: 390, width: 390 }}
          className={classes.complete}
          speed={0.9}
        />
      )}

      <Box sx={{ height: 10 }}></Box>
      {!complete_Comp && (
        <Grid
          item
          container
          alignItems="center"
          direction="column"
          justifyContent="flex-start"
          textAlign={"center"}
        >
          <Grid
            container
            alignItems="flex-end"
            justifyContent="flex-start"
            direction={"row"}
            px={2}
            my={2}
          >
            <Grid
              item
              xs={10}
              container
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                width: "100%",
                height: 40,
              }}
            >
              {/* <Grid
                item
                container
                sx={{
                  width: 100,
                }}
              >
                <img src={magatama} height={"30"} />
                <Typography
                  sx={{ fontSize: 18, color: "black", fontWeight: "bold" }}
                >
                  {magatama_count}
                </Typography>
              </Grid>

              <Grid
                item
                container
                sx={{
                  width: 100,
                }}
              >
                <img src={ex} height={"23"} />
                <Typography
                  sx={{
                    fontSize: 18,
                    color: "black",
                    fontWeight: "bold",
                    ml: 1,
                  }}
                >
                  {question_index === 0 && ex_count}
                  {question_index !== 0 && (
                    <NumberEasing
                      value={ex_count}
                      speed={1300}
                      decimals={0}
                      ease="quintInOut"
                    />
                  )}
                </Typography>
              </Grid> */}
            </Grid>

            <Grid item xs={2}>
              <Grid
                onClick={() => props.onClose()}
                container
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  width: 35,
                  height: 35,
                  backgroundColor: "#ece9e9f2",
                  borderRadius: 2,
                }}
              >
                <CloseOutlinedIcon sx={{ color: "gray", fontSize: 25 }} />
              </Grid>
            </Grid>

            <Grid
              container
              sx={{ width: "100%" }}
              alignItems={"center"}
              justifyContent={"center"}
              mt={1}
            >
              <Grid
                item
                xs={5}
                container
                alignItems="center"
                justifyContent={"flex-start"}
              >
                {heart_arr.map((icon, i) => (
                  <Box key={i} ml={0.3}>
                    {icon}
                  </Box>
                ))}
              </Grid>

              <Grid
                item
                xs={7}
                alignItems="center"
                justifyContent="center"
                sx={{ width: "100%" }}
              >
                <LinearProgress
                  value={25 * question_index}
                  variant="determinate"
                  style={{ height: 24, borderRadius: 5 }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ width: "90%", mb: 2 }}></Divider>

          <Card sx={{ width: "90%" }} style={{ border: "3px solid #fad4ab" }}>
            <CardContent sx={{ p: 1, "&:last-child": { pb: 1 } }}>
              <Grid textAlign={"left"}>
                {session_deta !== "" && (
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    {session_deta[question_index][1]}
                  </Typography>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Box sx={{ height: 20 }}></Box>

          {session_deta !== "" &&
            session_deta[question_index][6] !== "" &&
            !disc_Comp && (
              <img src={session_deta[question_index][6]} height="100" />
            )}

          {!disc_Comp && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{ height: "100%" }}
              mt={1}
              spacing={2}
            >
              <Grid item container sx={{ width: "90%" }} direction={"row"}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      border: tap1 ? "solid 4px #ff7043" : "solid 4px white",
                      borderRadius: 2,
                    }}
                  >
                    <CardActionArea
                      onClick={() => {
                        TapHandler(1);
                      }}
                    >
                      <CardContent
                        sx={{ minHeight: 60, p: 1, "&:last-child": { pb: 1 } }}
                      >
                        <Grid textAlign={"left"}>
                          {session_deta !== "" && (
                            <React.Fragment>
                              <Grid container textAlign={"left"} mb={0.5}>
                                <Grid item xs={1}>
                                  <Typography
                                    sx={{
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ①
                                  </Typography>
                                </Grid>
                                <Grid item xs={11}>
                                  <Typography
                                    sx={{ fontSize: 15, fontWeight: "bold" }}
                                  >
                                    {session_deta[question_index][2]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          )}
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                {/* <Grid item xs={1}></Grid> */}

                <Grid item xs={12} mt={2}>
                  <Card
                    sx={{
                      border: tap2 ? "solid 4px #ff7043" : "solid 4px white",
                      borderRadius: 2,
                    }}
                  >
                    <CardActionArea
                      onClick={() => {
                        TapHandler(2);
                      }}
                    >
                      <CardContent
                        sx={{ minHeight: 60, p: 1, "&:last-child": { pb: 1 } }}
                      >
                        <Grid textAlign={"left"}>
                          {session_deta !== "" && (
                            <React.Fragment>
                              <Grid container textAlign={"left"} mb={0.5}>
                                <Grid item xs={1}>
                                  <Typography
                                    sx={{
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ②
                                  </Typography>
                                </Grid>
                                <Grid item xs={11}>
                                  <Typography
                                    sx={{ fontSize: 15, fontWeight: "bold" }}
                                  >
                                    {session_deta[question_index][3]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          )}
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent="space-between"
                sx={{ width: "90%" }}
                direction={"row"}
              >
                <Grid item xs={12}>
                  <Card
                    sx={{
                      border: tap3 ? "solid 4px #ff7043" : "solid 4px white",
                      borderRadius: 2,
                    }}
                  >
                    <CardActionArea
                      onClick={() => {
                        TapHandler(3);
                      }}
                    >
                      <CardContent
                        sx={{ minHeight: 60, p: 1, "&:last-child": { pb: 1 } }}
                      >
                        <Grid textAlign={"left"}>
                          {session_deta !== "" && (
                            <React.Fragment>
                              <Grid container textAlign={"left"} mb={0.5}>
                                <Grid item xs={1}>
                                  <Typography
                                    sx={{
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ③
                                  </Typography>
                                </Grid>
                                <Grid item xs={11}>
                                  <Typography
                                    sx={{ fontSize: 15, fontWeight: "bold" }}
                                  >
                                    {session_deta[question_index][4]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          )}
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>

                <Grid item xs={12} mt={2}>
                  <Card
                    sx={{
                      border: tap4 ? "solid 4px #ff7043" : "solid 4px white",
                      borderRadius: 2,
                    }}
                  >
                    <CardActionArea
                      onClick={() => {
                        TapHandler(4);
                      }}
                    >
                      <CardContent
                        sx={{ minHeight: 60, p: 1, "&:last-child": { pb: 1 } }}
                      >
                        <Grid textAlign={"left"}>
                          {session_deta !== "" && (
                            <React.Fragment>
                              <Grid container textAlign={"left"} mb={0.5}>
                                <Grid item xs={1}>
                                  <Typography
                                    sx={{
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ④
                                  </Typography>
                                </Grid>
                                <Grid item xs={11}>
                                  <Typography
                                    sx={{ fontSize: 15, fontWeight: "bold" }}
                                  >
                                    {session_deta[question_index][5]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          )}
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          )}

          {disc_Comp && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
              mt={1}
            >
              <Grid item>
                {collect && <img src={happy_fox} height="100" />}
                {!collect && <img src={sleepy_fox} height="100" />}
              </Grid>
              <Grid item container alignItems="center" justifyContent="center">
                <Card
                  sx={{
                    width: "90%",
                    border: collect
                      ? "5px solid #7cc58a"
                      : "5px solid lightpink",
                    height: "100%",
                  }}
                >
                  <CardContent>
                    <Grid container alignItems="center" textAlign={"left"}>
                      {collect && (
                        <Grid>
                          <Typography
                            sx={{ fontSize: 20, fontWeight: "bold" }}
                            mb={2}
                          >
                            正解！
                          </Typography>
                          <Typography
                            sx={{ fontSize: 16, fontWeight: "bold" }}
                            mb={3}
                          >
                            「
                            {
                              session_deta[question_index][
                                Number(session_deta[question_index][10]) + 1
                              ]
                            }
                            」
                          </Typography>
                          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                            <Typewriter
                              loop={1}
                              cursor
                              cursorStyle="|"
                              typeSpeed={50}
                              // deleteSpeed={50}
                              delaySpeed={1000}
                              words={[session_deta[question_index][11]]}
                              onType={() => {
                                if (
                                  counter + 1 ===
                                  session_deta[question_index][11].length
                                ) {
                                  console.log("終了");
                                  set_typedone(true);
                                  set_counter(0);
                                } else {
                                  set_counter(counter + 1);
                                  // console.log(counter);
                                }
                              }}
                            />
                          </Typography>
                        </Grid>
                      )}
                      {!collect && (
                        <Grid>
                          <Typography
                            sx={{ fontSize: 20, fontWeight: "bold" }}
                            mb={2}
                          >
                            おしい！
                          </Typography>
                          <Typography sx={{ fontSize: 16 }} mb={1}>
                            正解は
                          </Typography>
                          <Typography
                            sx={{ fontSize: 16, fontWeight: "bold" }}
                            mb={2}
                          >
                            「
                            {
                              session_deta[question_index][
                                Number(session_deta[question_index][10]) + 1
                              ]
                            }
                            」
                          </Typography>
                          <Typography
                            sx={{ fontSize: 16, fontWeight: "bold" }}
                            mb={2}
                          >
                            <Typewriter
                              loop={1}
                              cursor
                              cursorStyle="|"
                              typeSpeed={50}
                              delaySpeed={1000}
                              words={[session_deta[question_index][11]]}
                              onType={() => {
                                if (
                                  counter + 1 ===
                                  session_deta[question_index][11].length
                                ) {
                                  console.log("終了");
                                  set_typedone(true);
                                  set_counter(0);
                                  console.log(counter);
                                } else {
                                  set_counter(counter + 1);
                                  // console.log(counter);
                                }
                              }}
                            />
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
          <Box sx={{ height: 20 }}></Box>

          <Grid sx={{ width: "100%" }} mb={3}>
            {!disc_Comp && (
              <Button
                sx={{ backgroundColor: "#ffa94d", width: "90%", height: 55 }}
                onClick={() => AnswerHandler()}
                variant="contained"
              >
                <Typography
                  sx={{ fontSize: 18, color: "white", fontWeight: "bold" }}
                >
                  選択する
                </Typography>
              </Button>
            )}
            {disc_Comp && (
              <Button
                sx={{ backgroundColor: "#ffa94d", width: "90%", height: 55 }}
                onClick={() => NextHandler()}
                variant="contained"
              >
                <Typography
                  sx={{ fontSize: 18, color: "white", fontWeight: "bold" }}
                >
                  つぎへ
                </Typography>
              </Button>
            )}
          </Grid>
          <Box sx={{ height: 40 }}></Box>
        </Grid>
      )}
      {complete_Comp && (
        <Grid
          container
          alignItems="center"
          direction="column"
          justifyContent="flex-start"
          textAlign={"center"}
          sx={{ height: "100%" }}
        >
          <Box sx={{ height: 40 }}></Box>

          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            {props.session_index + 1}章 コンプリート！
          </Typography>
          <Box sx={{ height: 40 }}></Box>

          <Grid container alignItems="center" justifyContent="center">
            <Card
              sx={{
                width: "90%",
                border: "5px solid #fad4ab",
                height: "100%",
              }}
            >
              <CardContent>
                <Grid container alignItems="center" textAlign={"left"}>
                  <Grid>
                    <Typography
                      sx={{ fontSize: 18, fontWeight: "bold" }}
                      mb={2}
                    >
                      {props.session_index + 1}章のまとめ
                    </Typography>
                    <Typography
                      sx={{ fontSize: 16, fontWeight: "bold" }}
                      mb={2}
                    >
                      {session_deta[0][12]}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 16, fontWeight: "bold" }}
                      mb={2}
                    >
                      {session_deta[0][13]}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 16, fontWeight: "bold" }}
                      mb={2}
                    >
                      {session_deta[0][14]}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Box sx={{ height: 40 }}></Box>

            <Button
              sx={{ width: "80%", height: 55, mt: 2 }}
              variant="contained"
              onClick={() => BackMenu()}
            >
              <Typography
                sx={{ fontSize: 18, color: "white", fontWeight: "bold" }}
              >
                もどる
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}

      <Dialog
        onClose={() => togglemagatamaD(false)}
        open={magatamaD}
        sx={{ width: "100%" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
          textAlign={"left"}
          p={2}
        >
          {/* 勾玉(まがたま)を1つ落としてしまいました... */}
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }} m={4}>
            体力がなくなってしまいました...
          </Typography>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            textAlign={"left"}
            width={"50%"}
          >
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              xs={6}
            >
              <img src={magatama} height={"90"}></img>
            </Grid>
            <Grid
              xs={6}
              item
              container
              alignItems="center"
              justifyContent="center"
              textAlign={"left"}
            >
              <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                -1
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ height: 20 }}></Box>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              minusmagatama();
              BackMenu();
            }}
            sx={{ width: "70%", height: 40 }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              もどる
            </Typography>
          </Button>
        </Grid>
      </Dialog>

      <Dialog
        onClose={() => toggleAddmagatamaD(false)}
        open={addmagatamaD}
        sx={{ width: "100%" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
          textAlign={"left"}
          p={2}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }} m={4}>
            勾玉(まがたま)を{addmagatama_count}つ獲得！
          </Typography>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            textAlign={"left"}
            width={"50%"}
          >
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              xs={6}
            >
              <img src={magatama} height={"90"}></img>
            </Grid>
            <Grid
              xs={6}
              item
              container
              alignItems="center"
              justifyContent="center"
              textAlign={"left"}
            >
              <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                × {addmagatama_count}
              </Typography>
            </Grid>
          </Grid>

          <Box sx={{ height: 20 }}></Box>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => toggleAddmagatamaD(false)}
            sx={{ width: "70%", height: 40 }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              もどる
            </Typography>
          </Button>
        </Grid>
      </Dialog>

      <Dialog onClose={accountDclose} open={accountD} sx={{ width: "100%" }}>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Box sx={{ height: 40 }}></Box>
          <Grid p={2}>
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold" }}
              mt={2}
              mr={2}
              ml={2}
            >
              アカウントを登録(無料)して他のクイズにも挑戦しましょう！
            </Typography>
            <Box sx={{ height: 40 }}></Box>
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              textAlign={"center"}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#ff7043",
                  color: "white",
                  width: "70%",
                  height: 55,
                }}
                onClick={() => goSignup()}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  登録・ログイン
                </Typography>
              </Button>
              <Box sx={{ height: 20 }}></Box>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => props.CloseSelectPage()}
                sx={{
                  width: "70%",
                  height: 55,
                }}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  もどる
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ height: 40 }}></Box>
        </motion.div>
      </Dialog>
    </Grid>
  );
};

export default AnswerPage;
