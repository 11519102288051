import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import pic from "../../images/login.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { TextField } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db, app } from "../../firebase";

export default function Contact(props) {
  const navigate = useNavigate();
  const [sendD, setSendD] = useState(false);
  const [confirmD, setConfirmD] = useState(false);
  const [value, setValue] = useState("");

  const sendDOpen = () => {
    if (value !== "") {
      setSendD(true);
    } else {
      return;
    }
  };
  const sendDClose = () => {
    setSendD(false);
  };
  const confirmDOpen = () => {
    setConfirmD(true);
  };
  const confirmDClose = () => {
    setConfirmD(false);
  };

  const onSubmit = (action) => {
    sendDClose();
    let customer__word = "レビューが届きました。\n" + value;
    const customerValues = {
      email: "pokky0206@gmail.com",
      name: "管理者",
      content: customer__word,
    };
    const functions = getFunctions(app, "asia-northeast1");
    let sendMail = httpsCallable(functions, "sendMailToUser");
    sendMail(customerValues)
      .then(() => {
        console.log("Successed send mail.");
      })
      .catch((err) => {
        console.log("mail_error" + err);
      })
      .finally(() => {
        console.log("finally");
        confirmDOpen();
      });
  };

  const placeholder_text = `・使ってみた感想\n・使いづらかったところ\n・見づらいところ\n\n些細なことでもお聞かせ頂ければ幸いです`;

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"center"}
    >
      <Box sx={{ height: 40 }}></Box>

      <Card sx={{ width: "90%" }} style={{ border: "3px solid lightgray" }}>
        <CardContent>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"left"}
            p={2}
          >
            <Typography
              sx={{ mt: 1.1, fontSize: 16, fontWeight: "bold" }}
              variant="body2"
            >
              アプリの感想をお聞かせください！
            </Typography>
          </Grid>
          <Box sx={{ height: 20 }}></Box>

          <TextField
            multiline
            rows={6}
            sx={{ width: "90%" }}
            value={value}
            placeholder={placeholder_text}
            onChange={(event, newValue) => {
              setValue(event.target.value);
              console.log(event.target.value);
            }}
            inputProps={{ style: { fontSize: 18, fontWeight: "bold" } }}
          ></TextField>
          <Box sx={{ height: 30 }}></Box>

          <Button
            variant="contained"
            sx={{
              width: "60%",
              height: 55,
              backgroundColor: "#ece9e9f2",
              color: "black",
            }}
            onClick={() => sendDOpen()}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              匿名で声を届ける
            </Typography>
          </Button>
        </CardContent>
      </Card>

      <Box sx={{ height: 10 }}></Box>

      <Dialog onClose={sendDClose} open={sendD} sx={{ width: "100%" }}>
        <Box sx={{ height: 40 }}></Box>
        <Grid p={2}>
          <Typography sx={{ fontSize: 14 }} mt={2} mr={2} ml={2}>
            送信してもよろしいですか？
          </Typography>
          <Box sx={{ height: 40 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#ff7043",
                color: "white",
                width: "70%",
              }}
              onClick={() => onSubmit()}
            >
              送信
            </Button>
            <Box sx={{ height: 20 }}></Box>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => sendDClose()}
              style={{ width: "70%" }}
            >
              キャンセル
            </Button>
          </Grid>
          <Box sx={{ height: 40 }}></Box>
        </Grid>
      </Dialog>

      <Dialog onClose={confirmDClose} open={confirmD} sx={{ width: "100%" }}>
        <Box sx={{ height: 40 }}></Box>
        <Grid p={2}>
          <Typography sx={{ fontSize: 14 }} mt={2} mr={2} ml={2}>
            ありがとうございました！　より良い参拝ができるよう励んで参ります。
          </Typography>
          <Box sx={{ height: 40 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "white",
                color: "black",
                width: "70%",
              }}
              onClick={() => confirmDClose()}
            >
              戻る
            </Button>
          </Grid>
          <Box sx={{ height: 40 }}></Box>
        </Grid>
      </Dialog>
    </Grid>
  );
}
