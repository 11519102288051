import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Fab from "@mui/material/Fab";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { CardMedia } from "@mui/material";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getAuth } from "firebase/auth";
import unsei_wallpaper from "../../images/unsei_wallpaper.png";
import ex from "../../images/ex.png";
import { motion } from "framer-motion";

//その日の暦、詩を表示する

export default function Unsei(props) {
  const navigate = useNavigate();

  const [rokuyou_text, setRokuyou_text] = useState("");
  const [rokuyou_yomi, setRokuyou_yomi] = useState("");
  const [disc, setDisc] = useState("");
  const [koyomiD, setKoyomiD] = useState(false);
  const [today_jyuunicyoku, setToday_jyuunicyoku] = useState("");
  const [today_nijyuhassyuku, setToday_nijyuhassyuku] = useState("");
  const [deta_display, set_deta_display] = useState(false);
  const [showEx, set_showEx] = useState(false);

  const jyuunicyoku = [
    {
      name: "建",
      furi: "たつ",
      disc: "神仏の祭祀、婚礼、開店、新規事業、移転、柱立て、 棟上げ、旅行は大吉。屋敷内の動土、蔵開きは凶。",
    },
    {
      name: "除",
      furi: "のぞく",
      disc: "医師にかかり始め、薬の飲み始め、種まき、井戸掘りは吉。婚礼、屋敷内の動土は凶。",
    },
    {
      name: "満",
      furi: "みつ",
      disc: "建築、移転、新規事の開始をはじめ、婚礼その他の祝い事、種まき、動土など、すべて吉。",
    },
    {
      name: "平",
      furi: "たいら",
      disc: "地固め、柱立て、旅行、婚礼その他祝い事に吉。池、 溝、穴などを掘るのは凶。",
    },
    {
      name: "定",
      furi: "さだん",
      disc: "建築、柱立て、棟上げ、移転、婚礼、開店、開業、種まきに吉。訴訟、旅行、樹木の植替えは凶。",
    },
    {
      name: "執",
      furi: "とる",
      disc: "神仏の祭祀、婚礼その他の祝い事、造作、種まきに吉の日。金銭の出し入れ、財産整理には凶。",
    },
    {
      name: "破",
      furi: "やぶる",
      disc: "訴訟、談判事などでは好結果をみる。婚礼、神仏の祭祀、その他祝い事には忌むべき凶の日。",
    },
    {
      name: "危",
      furi: "あやぶ",
      disc: "万事に危惧を含む日なので、諸事控えめに慎むべき。旅行、登山はとくに大凶。",
    },
    {
      name: "成",
      furi: "なる",
      disc: "建築、開店、種まきなど、新規に事を始めるのには吉。訴訟、談判事などには凶。",
    },
    {
      name: "納",
      furi: "おさん",
      disc: "万物を納め入れるのに良い日。五穀の収納、商品購入は吉。見合い、婚礼などには凶。",
    },
    {
      name: "開",
      furi: "ひらく",
      disc: "神使天険を開通する日で、建築、移転、婚礼、開店などすべて吉。葬送の儀などの不浄事は凶。",
    },
    {
      name: "閉",
      furi: "とづ",
      disc: "諸事閉止する意を含む。金銭の収納、建墓、トイレ造りは吉。柱立て、棟上げ、開店、婚礼などは凶。",
    },
  ];
  const nijyuhassyuku = [
    {
      name: "昴",
      furi: "ぼう",
      disc: "神仏詣り、祝い事、家畜購入、新規事開始吉。造改修凶。",
    },
    {
      name: "畢",
      furi: "ひつ",
      disc: "神仏祭祀、婚礼、屋根ふき、棟上げ、 取引開始すべて吉。",
    },
    {
      name: "觜",
      furi: "し",
      disc: "稽古始め、山仕事始め、運搬始め 吉。仕立物の着初め凶。",
    },
    {
      name: "参",
      furi: "しん",
      disc: "物品仕入れ、倉庫納入、新規取引開始、祝い事など吉",
    },
    {
      name: "井",
      furi: "せい",
      disc: "神仏参詣、種まき、動土、普請建築、 落成式などよろず吉。",
    },
    {
      name: "鬼",
      furi: "き",
      disc: "大吉日にて祝い事よろずよろし。 ただし婚礼のみ凶。",
    },
    {
      name: "柳",
      furi: "りゅう",
      disc: "物事を断るに用いてよき日。婚礼、 新規事の開始凶。",
    },
    {
      name: "星",
      furi: "せい",
      disc: "乗馬始め、治療始め、便所改造吉。婚礼、葬式は凶。",
    },
    {
      name: "張",
      furi: "ちょう",
      disc: "就職、見合い、神仏祈願、諸祝宴、 和合事よろずよろし。",
    },
    {
      name: "翼",
      furi: "よく",
      disc: "耕作始め、樹木の植替え、種まき吉。高所での仕事凶。",
    },
    {
      name: "軫",
      furi: "しん",
      disc: "地鎮祭、棟上げ、落成式、神仏祭祀、 祝い事よろず吉。",
    },
    {
      name: "角",
      furi: "かく",
      disc: "衣類の着初め、柱立て、普請造作、婚礼など吉。葬式凶。",
    },
    {
      name: "亢",
      furi: "こう",
      disc: "衣類仕立て、物品購入、結納取交わし吉。普請建築凶。",
    },
    {
      name: "氐",
      furi: "てい",
      disc: "婚礼、開店、新規事業の開始吉。 仕立物の着初め凶。",
    },
    {
      name: "房",
      furi: "ぼう",
      disc: "婚礼、旅行、移転、柱立て、棟上げなど新規事の開始吉。",
    },

    {
      name: "心",
      furi: "しん",
      disc: "神仏の祭祀、移転、旅行吉。婚礼、 普請建築、葬式凶。",
    },
    {
      name: "尾",
      furi: "び",
      disc: "婚礼、開店、移転、新規事の開始吉。 仕立物の着初め凶",
    },
    {
      name: "箕",
      furi: "き",
      disc: "動土、池掘り、物品仕入れ、集金、 建物の改造大吉。葬式凶。",
    },
    {
      name: "斗",
      furi: "と",
      disc: "とくに土掘り事に大吉。倉庫の建造、新規事の開始吉。",
    },
    {
      name: "牛",
      furi: "ぎゅう",
      disc: "吉祥宿にてよろずよろし。とくに午の刻大吉祥の日柄。",
    },
    {
      name: "女",
      furi: "じょ",
      disc: "稽古事始め吉。訴訟、争論、掛け合い事、婚礼、葬式凶。",
    },
    {
      name: "虚",
      furi: "きょ",
      disc: "衣類着初め、学問始めに吉。相談事、掛け合い事は大凶。",
    },
    {
      name: "危",
      furi: "き",
      disc: "壁塗り、船普請、酒造り吉。登山、 高所での仕事大凶。",
    },
    {
      name: "室",
      furi: "しつ",
      disc: "祈願始め、婚礼、祝い事、神仏祭祀などよろずよろし。",
    },
    {
      name: "壁",
      furi: "へき",
      disc: "新規事の開始、旅立ち、婚礼大吉、 ただし南へ行くは凶。",
    },
    {
      name: "奎",
      furi: "けい",
      disc: "宮造り、柱立て、棟上げ、井戸掘り、神仏祭祀、旅立ち吉。",
    },
    {
      name: "婁",
      furi: "ろう",
      disc: "動土造作、嫁取りの相談事、契約、取引始め、造園吉。",
    },
    {
      name: "胃",
      furi: "い",
      disc: "公事に関与するはよろし。私事にこだわるは悪し。",
    },
  ];

  const goDialy = () => {
    navigate("/dialy");
  };

  const KoyomiDhandler = (type) => {
    setKoyomiD(type);
  };

  const useStyles = makeStyles((theme) => ({
    extext: {
      position: "fixed",
      top: "20%",
    },
  }));
  const classes = useStyles();

  const showExmoment = () => {
    set_showEx(true);
    setTimeout(() => {
      set_showEx(false);
    }, 3000);
  };

  const getKoyomi = () => {
    const today = new Date();
    var month = ("0" + (today.getMonth() + 1)).slice(-2);
    var day = ("0" + today.getDate()).slice(-2);

    const server = `https://koyomi.zingsystem.com/api/?mode=d&cnt=1&targetyyyy=2023&targetmm=${month}&targetdd=${day}`;
    // console.log(server);
    axios
      .get(server)
      .then((res) => {
        // console.log(res);
        const deta = res.data;
        const day = Object.keys(deta["datelist"]);
        const rokuyou = deta["datelist"][day]["rokuyou"];
        // console.log("day: " + day);
        display_Handler(rokuyou);
      })
      .catch(console.error);
  };

  const yaku1 = `何かを早めに決断するのが良いでしょう。\n午前は吉、午後は凶です。`;
  const yaku2 = `物事の結果がはっきり出るとされています。\n祝い事は吉、葬式など仏事は凶です。`;
  const yaku3 = `慌てず、ゆっくり考えて決断するのが良いでしょう。\n午前は凶、午後は吉です。`;
  const yaku4 = `祝い事は控えましょう。\n仏事には吉です。`;
  const yaku5 = `物事が思うように進むとされています。\nただし、有頂天は禁物です。`;
  const yaku6 = `物事が思うように進まないとされています。\nただし、昼前後は吉です。`;

  const display_Handler = (value) => {
    switch (value) {
      case "先勝":
        setRokuyou_text("先勝");
        setRokuyou_yomi("せんしょう");
        setDisc(yaku1);
        break;
      case "友引":
        setRokuyou_text("友引");
        setRokuyou_yomi("ともびき");
        setDisc(yaku2);
        break;
      case "先負":
        setRokuyou_text("先負");
        setRokuyou_yomi("せんぶ");
        setDisc(yaku3);
        break;
      case "仏滅":
        setRokuyou_text("仏滅");
        setRokuyou_yomi("ぶつめつ");
        setDisc(yaku4);
        break;
      case "大安":
        setRokuyou_text("大安");
        setRokuyou_yomi("たいあん");
        setDisc(yaku5);
        break;
      case "赤口":
        setRokuyou_text("赤口");
        setRokuyou_yomi("しゃっこう");
        setDisc(yaku6);
        break;

      default:
    }
  };

  useLayoutEffect(() => {
    if (props.docid == "") {
      set_deta_display(true);
    }
  }, []);

  useEffect(() => {
    getUnseiChecked();
    getKoyomi();
    getJyuniChoku();
    getNijyuhassyuku();
  }, []);

  const getJyuniChoku = () => {
    //節切りの旧暦月の最初の特定の十二支の日を「建」とする
    //以後、順に繰り返して行く。
    //ただし、二十四節気の「節」にあたる日は、前日の十二直を繰り返しすため、これ以降順番が１つずれる。
    //月毎の特定の十二支は、
    // 正月 寅	二月 卯	三月 辰	四月 巳
    // 五月 午	六月 未	七月 申	八月 酉
    // 九月 戌	十月 亥	十一月 子	十二月 丑
    //TODO - まず、「今日」が「該当する節」を出す.

    const today = new Date();
    const today_month = today.getMonth();
    let base_jyunishi = "";

    // 〈春〉
    const sekki1 = new Date(2023, 1, 4, 11, 43); // 1. 立春（りっしゅん）　02月04日
    const sekki2 = new Date(2023, 1, 19, 7, 34); // 2. 雨水（うすい）　02月19日
    const sekki3 = new Date(2023, 2, 6, 5, 36); // 3. 啓蟄（けいちつ）　03月06日
    const sekki4 = new Date(2023, 2, 21, 6, 24); // 4. 春分（しゅんぶん）　03月21日
    const sekki5 = new Date(2023, 3, 5, 10, 13); // 5. 清明（せいめい）04月05日
    const sekki6 = new Date(2023, 3, 20, 17, 14); // 6. 穀雨（こくう）　04月20日

    // 〈夏〉
    const sekki7 = new Date(2023, 4, 6, 3, 19); // 7. 立夏（りっか）	05月06日
    const sekki8 = new Date(2023, 4, 21, 16, 9); // 8. 小満（しょうまん）　05月21日
    const sekki9 = new Date(2023, 5, 6, 7, 18); // 9. 芒種（ぼうしゅ）　06月06日
    const sekki10 = new Date(2023, 5, 21, 23, 58); // 10. 夏至（げし）　06月21日
    const sekki11 = new Date(2023, 6, 7, 17, 31); // 11. 小暑（しょうしょ）　07月07日
    const sekki12 = new Date(2023, 6, 23, 10, 50); // 12. 大暑（たいしょ）　07月23日

    // 〈秋〉
    const sekki13 = new Date(2023, 7, 8, 3, 23); // 13. 立秋（りっしゅう）　08月08日
    const sekki14 = new Date(2023, 7, 23, 18, 1); // 14. 処暑（しょしょ）　08月23日
    const sekki15 = new Date(2023, 8, 8, 6, 27); // 15. 白露（はくろ）　09月08日
    const sekki16 = new Date(2023, 8, 23, 15, 50); // 16. 秋分（しゅうぶん）　09月23日
    const sekki17 = new Date(2023, 9, 8, 22, 16); // 17. 寒露（かんろ）　10月08日
    const sekki18 = new Date(2023, 9, 24, 1, 21); // 18. 霜降（そうこう）　10月24日

    // 〈冬〉
    const sekki19 = new Date(2023, 10, 7, 1, 36); // 19. 立冬（りっとう）　11月07日
    const sekki20 = new Date(2023, 10, 22, 23, 3); // 20. 小雪（しょうせつ）　11月22日
    const sekki21 = new Date(2023, 11, 7, 18, 33); // 21. 大雪（たいせつ）　12月07日
    const sekki22 = new Date(2023, 11, 22, 12, 27); // 22. 冬至（とうじ）　12月22日
    const sekki23 = new Date(2023, 0, 6, 0, 5); // 23. 小寒（しょうかん）　01月06日
    const sekki24 = new Date(2023, 0, 20, 17, 30); // 24. 大寒（だいかん）　01月20日

    let target_sekki = "";
    if (today_month == 1) {
      target_sekki = sekki1;
    } else if (today_month == 2) {
      target_sekki = sekki3;
    } else if (today_month == 3) {
      target_sekki = sekki5;
    } else if (today_month == 4) {
      target_sekki = sekki7;
    } else if (today_month == 5) {
      target_sekki = sekki9;
    } else if (today_month == 6) {
      target_sekki = sekki11;
    } else if (today_month == 7) {
      target_sekki = sekki13;
    } else if (today_month == 8) {
      target_sekki = sekki15;
    } else if (today_month == 9) {
      target_sekki = sekki17;
    } else if (today_month == 10) {
      target_sekki = sekki19;
    } else if (today_month == 11) {
      target_sekki = sekki21;
    } else if (today_month == 0) {
      target_sekki = sekki23;
    }

    if (today_month >= 1 && today_month < 3) {
      //正月節
      if (CompareSetu(today, sekki1, sekki3)) {
        base_jyunishi = "寅";
        // console.log("正月節");
      }
    }
    if (today_month >= 2 && today_month < 4) {
      //二月節
      if (CompareSetu(today, sekki3, sekki5)) {
        base_jyunishi = "卯";
        // console.log("二月節");
      }
    }
    if (today_month >= 3 && today_month < 5) {
      //三月節
      if (CompareSetu(today, sekki5, sekki7)) {
        base_jyunishi = "辰";
        // console.log("三月節");
      }
    }
    if (today_month >= 4 && today_month < 6) {
      //四月節
      // console.log("4月節かどうか検証します");
      if (CompareSetu(today, sekki7, sekki9)) {
        base_jyunishi = "巳";
        // console.log("四月節");
      }
    }
    if (today_month >= 5 && today_month < 7) {
      //五月節
      // console.log("5月節かどうか検証します");

      if (CompareSetu(today, sekki9, sekki11)) {
        base_jyunishi = "午";
        // console.log("五月節");
      }
    }
    if (today_month >= 6 && today_month < 8) {
      //六月節
      if (CompareSetu(today, sekki11, sekki13)) {
        base_jyunishi = "未";
        // console.log("六月節");
      }
    }
    if (today_month >= 7 && today_month < 9) {
      //七月節
      if (CompareSetu(today, sekki13, sekki15)) {
        base_jyunishi = "申";
        // console.log("七月節");
      }
    }
    if (today_month >= 8 && today_month < 10) {
      //八月節
      if (CompareSetu(today, sekki15, sekki17)) {
        base_jyunishi = "酉";
        // console.log("八月節");
      }
    }
    if (today_month >= 9 && today_month < 11) {
      //九月節
      if (CompareSetu(today, sekki17, sekki19)) {
        base_jyunishi = "戌";
        // console.log("九月節");
      }
    }
    if (today_month >= 10) {
      //十月節
      if (CompareSetu(today, sekki19, sekki21)) {
        base_jyunishi = "亥";
        // console.log("十月節");
      }
    }
    if (today_month >= 11 || today_month <= 0) {
      //十一月節
      if (CompareSetu(today, sekki21, sekki23)) {
        base_jyunishi = "子";
        // console.log("十一月節");
      }
    }
    if (today_month >= 0 && today_month < 2) {
      //十一月節 //FIXME - 年を跨ぐ場合?
      if (CompareSetu(today, sekki23, sekki1)) {
        base_jyunishi = "丑";
        // console.log("十一月節");
      }
    }

    //節のうち、「該当する特定の１２支」を出し、その１２支を基準として並べる。
    // console.log("今月の基準となる１２支は、" + base_jyunishi + "　です");

    let order_jyunishi = [];
    const jyunishi_arr = [
      "子",
      "丑",
      "寅",
      "卯",
      "辰",
      "巳",
      "午",
      "未",
      "申",
      "酉",
      "戌",
      "亥",
    ];

    //今月の1日の「１２支」を取得する
    var month = ("0" + (today.getMonth() + 1)).slice(-2);

    const server = `https://koyomi.zingsystem.com/api/?mode=d&cnt=1&targetyyyy=2023&targetmm=${month}&targetdd=01`;
    // console.log(server);

    axios
      .get(server)
      .then((res) => {
        // console.log(res);
        const deta = res.data;
        const day = Object.keys(deta["datelist"]);
        const first_zyunisi = deta["datelist"][day]["zyunisi"];

        let zyunishi_index = "";
        for (let i = 0; i < jyunishi_arr.length; i++) {
          if (jyunishi_arr[i] == first_zyunisi) {
            zyunishi_index = i;
          }
        }
        const max_day = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          0
        ).getDate();
        let jyunishi_calendar = [];

        let j = 0;
        for (let i = 0; i < max_day; i++) {
          if (i === 0) {
            jyunishi_calendar = [
              { day: i + 1, jyunishi: jyunishi_arr[zyunishi_index] },
            ];
          } else {
            if (zyunishi_index + i > 11) {
              jyunishi_calendar.push({
                day: i + 1,
                jyunishi: jyunishi_arr[j],
              });
              j++;
              if (j === 12) {
                j = 0;
              }
            } else {
              jyunishi_calendar.push({
                day: i + 1,
                jyunishi: jyunishi_arr[zyunishi_index + 1],
              });
            }
          }
        }
        //基準となる日（午、たつ、17日）を出す
        let base_day = "";
        for (let i = 0; i < max_day; i++) {
          if (jyunishi_calendar[i]["jyunishi"] == base_jyunishi) {
            base_day = jyunishi_calendar[i]["day"];
            break;
          }
        }
        let all_calendar = [];
        const target_sekkidate = target_sekki.getDate();
        // console.log("target_sekkidate: " + target_sekkidate);
        //節気の日は前日の１２直を入れる。
        //基準となる日から、1日にかけて、１２直を割り当てていく。 16, 15, 14, 13,..
        // console.log("base_day: " + base_day);

        let k = 11;
        for (let i = base_day - 1; i > 0; i--) {
          if (i === target_sekkidate - 1) {
            all_calendar.unshift({
              day: i,
              jyuunicyoku: jyuunicyoku[k + 1]["name"],
            });
            k = 1;
          } else {
            all_calendar.unshift({
              day: i,
              jyuunicyoku: jyuunicyoku[k]["name"],
            });
          }

          k = k - 1;
          if (k < 0) {
            k = 11;
          }
        }

        //基準となる日から、31日にかけて、１２直を割り当てていく 17, 18, 19, ...
        let l = 0;
        for (let i = base_day; i < max_day + 1; i++) {
          const day_deta = { day: i, jyuunicyoku: jyuunicyoku[l]["name"] };
          all_calendar.push(day_deta);

          l += 1;
          if (l > 11) {
            l = 0;
          }
        }

        const jyunicyoku_result =
          all_calendar[Number(today.getDate() - 1)]["jyuunicyoku"];
        // console.log("all_calendar: " + JSON.stringify(all_calendar));
        // console.log(jyunicyoku_result);
        for (let i = 0; i < 12; i++) {
          if (jyuunicyoku[i]["name"] == jyunicyoku_result) {
            setToday_jyuunicyoku(jyuunicyoku[i]);
          }
        }
      })
      .catch(console.error);
  };

  const getNijyuhassyuku = () => {
    const today = new Date();
    const newyear = new Date(2023, 0, 1);
    const diffdate = parseInt((today - newyear) / 1000 / 60 / 60 / 24);
    const surplus = diffdate % 28;
    setToday_nijyuhassyuku(nijyuhassyuku[surplus]);
  };
  const CompareSetu = (today, beforesekki, aftersekki) => {
    return beforesekki <= today && today <= aftersekki;
  };

  const HandleUnseiDisplay = async (open) => {
    if (open) {
      //DBを更新し、運勢をチェックしたことを登録
      const today = new Date();
      const docRef = doc(db, "account_list", props.docid);
      let deta_dic;
      //TODO チュートリアルが終わっていた時の処理
      if (props.tutorial_complete) {
        deta_dic = {
          values: [
            {
              ex: props.ex_count + 16,
              dango: props.magatama_count,
              unsei_check: today,
              tutorial_complete: true,
            },
          ],
        };
      } else {
        deta_dic = {
          values: [
            {
              ex: props.ex_count + 16,
              dango: props.magatama_count,
              unsei_check: today,
            },
          ],
        };
      }

      try {
        await updateDoc(docRef, deta_dic);
        props.GetFirebaseDeta();
      } catch (e) {
        console.log("error" + e);
      }
    }
    set_deta_display(open);
    // showExmoment();
  };

  const getUnseiChecked = async () => {
    try {
      const today_day = new Date().getDate();

      const docRef = doc(db, "account_list", props.docid);
      const docSnap = await getDoc(docRef);
      const values = docSnap.data();
      let diffDays = null;
      //前回のチェック日を取得
      if (typeof values["values"][0]["unsei_check"] !== "undefined") {
        const past_loginday = values["values"][0]["unsei_check"]
          .toDate()
          .getDate();

        if (today_day === past_loginday) {
          set_deta_display(true);
        }
      } else {
        console.log("unsei check not found");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"left"}
    >
      <Divider sx={{ width: "90%", mb: 3 }}></Divider>
      {showEx && (
        <Grid className={classes.extext}>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            animate={{ y: "-50px", x: "-40px" }}
          >
            <Grid container alignItems={"center"} justifyContent={"center"}>
              <img src={ex} height={50} />
              <Typography sx={{ fontSize: 55, fontWeight: "bold", ml: 1 }}>
                +16
              </Typography>
            </Grid>
          </motion.div>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        textAlign={"left"}
      >
        <Grid item xs={6}>
          <Typography sx={{ ml: 3, fontSize: 20, fontWeight: "bold" }}>
            本日の運勢
          </Typography>
        </Grid>
        <Grid item xs={5.5} ml={0.5}>
          <Button
            onClick={() => KoyomiDhandler(true)}
            sx={{ backgroundColor: "#ece9e9f2", width: "90%", height: 44 }}
          >
            <Typography sx={{ color: "black", fontSize: 14 }} mr={0.5}>
              六曜とは？
            </Typography>
            <HelpOutlineIcon sx={{ color: "black" }}></HelpOutlineIcon>
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ height: 20 }}></Box>

      {!deta_display && (
        <Card
          sx={{
            width: "90%",
            height: 445,
            backgroundImage: `url(${unsei_wallpaper})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          style={{ border: "3px solid #fad4ab" }}
        >
          {/* <CardMedia component="img" height="445" image={unsei_wallpaper} /> */}
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <motion.div
              animate={{ scale: 1.2 }}
              transition={{ type: "spring", repeat: Infinity, duration: 3 }}
            >
              <Button
                sx={{ width: "100%", height: 55, backgroundColor: "#ffa94d" }}
                variant="contained"
                onClick={() => HandleUnseiDisplay(true)}
              >
                <Typography
                  sx={{ fontSize: 16, fontWeight: "bold", color: "white" }}
                >
                  運勢を見る（1日1回）
                </Typography>
              </Button>
            </motion.div>
          </Grid>
        </Card>
      )}

      {deta_display && (
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Grid container alignItems="center" justifyContent="center">
            <Card
              sx={{
                width: "90%",
                backgroundImage: `url(${unsei_wallpaper})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              style={{ border: "3px solid #fad4ab" }}
            >
              <CardContent
                sx={{
                  p: 1,
                  "&:last-child": { pb: 1 },
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="center"
                  direction="column"
                  textAlign={"left"}
                  pl={1}
                  pr={1}
                >
                  <Box
                    sx={{
                      backgroundColor: "#ece9e9f2",
                      borderRadius: 1,
                    }}
                    p={0.7}
                    mt={2}
                  >
                    <Typography sx={{ fontSize: 15 }} variant="body2">
                      六曜
                    </Typography>
                  </Box>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    mt={1}
                    mb={1}
                  >
                    <Grid item xs={3} textAlign={"center"}>
                      <Typography sx={{ fontSize: 13 }}>
                        {rokuyou_yomi}
                      </Typography>
                      <Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
                        {rokuyou_text}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                        {disc}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Divider sx={{ width: "100%", mb: 2, mt: 2 }}></Divider>

                  <Box
                    sx={{ backgroundColor: "#ece9e9f2", borderRadius: 1 }}
                    p={0.7}
                    mt={1}
                    mb={1}
                  >
                    <Typography sx={{ fontSize: 15 }} variant="body2">
                      十二直
                    </Typography>
                  </Box>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={3} textAlign={"center"}>
                      <Typography sx={{ fontSize: 13 }}>
                        {today_jyuunicyoku["furi"]}
                      </Typography>
                      <Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
                        {today_jyuunicyoku["name"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                        {today_jyuunicyoku["disc"]}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Divider sx={{ width: "100%", mb: 2, mt: 2 }}></Divider>

                  <Box
                    sx={{ backgroundColor: "#ece9e9f2", borderRadius: 1 }}
                    p={0.7}
                    mt={1}
                    mb={1}
                  >
                    <Typography sx={{ fontSize: 15 }} variant="body2">
                      二十八宿
                    </Typography>
                  </Box>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    mb={3}
                  >
                    <Grid item xs={3} textAlign={"center"}>
                      <Typography sx={{ fontSize: 13 }}>
                        {today_nijyuhassyuku["furi"]}
                      </Typography>
                      <Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
                        {today_nijyuhassyuku["name"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                        {today_nijyuhassyuku["disc"]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </motion.div>
      )}

      <Dialog
        onClose={() => KoyomiDhandler(false)}
        open={koyomiD}
        sx={{ width: "100%" }}
      >
        <Grid sx={{ height: "90%" }}>
          <Box sx={{ height: 50 }}></Box>

          <Typography
            sx={{ fontSize: 17, fontWeight: "bold" }}
            mr={2}
            ml={2}
            mb={4}
            mt={3}
          >
            六曜(ろくよう)
          </Typography>
          <Typography sx={{ fontSize: 15 }} mr={2} ml={2} mb={3}>
            中国で時刻の吉凶占いに用いられていた運勢の1つです。
          </Typography>
          <Typography sx={{ fontSize: 15 }} mr={2} ml={2} mb={3}>
            14世紀の鎌倉時代に日本に伝来してからは、日の吉凶を判断する方法として用いられるようになりました。
          </Typography>
          <Typography sx={{ fontSize: 15 }} mr={2} ml={2} mb={1}>
            先勝(せんしょう)・友引(ともびき)・先負(せんぶ)・仏滅(ぶつめつ)・大安(たいあん)・赤口(しゃっこう)があります。
          </Typography>
          <Box sx={{ height: 20 }}></Box>

          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              color="inherit"
              onClick={() => KoyomiDhandler(false)}
              sx={{ width: "70%", height: 40 }}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                戻る
              </Typography>
            </Button>
            <Box sx={{ height: 60 }}></Box>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
}
