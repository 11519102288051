import { React, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Fab from "@mui/material/Fab";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import pic3 from "../../images/three.jpg";
import pic5 from "../../images/five.jpg";
import pic8 from "../../images/eight.jpg";
import pic1 from "../../images/one.jpg";
import Divider from "@mui/material/Divider";

const Ofuda = (props) => {
  const useStyles = makeStyles((theme) => ({
    fab: {
      position: "fixed",
      margin: 0,
      bottom: "auto",
      right: 20,
      top: 20,
    },
  }));
  const classes = useStyles();

  const handleCloseUpdate = () => {
    props.handleCloseUpdate();
  };

  return (
    <div>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"left"}
      >
        <Box sx={{ height: 80 }}></Box>
        <Typography
          sx={{
            mt: 1.1,
            fontSize: 16,
            backgroundColor: "#f7ad6c",
            p: 1,
          }}
          variant="body2"
        >
          願う想いの大きさに応じてお選びください
        </Typography>
        <Box sx={{ height: 20 }}></Box>
        <img src={pic3} alt="pic3" style={{ width: "70%" }} />
        <Typography sx={{ mt: 1.1, fontSize: 15 }} variant="body2">
          三千円のおふだ(普通: 28cm、小:24cm)
        </Typography>
        <Box sx={{ height: 15 }}></Box>
        <Divider sx={{ width: "100%" }}></Divider>

        <Box sx={{ height: 15 }}></Box>
        <img src={pic5} alt="pic5" style={{ width: "70%" }} />
        <Typography sx={{ mt: 1.1, fontSize: 15 }} variant="body2">
          五千円のおふだ(普通: 39cm、小:30cm)
        </Typography>
        <Box sx={{ height: 15 }}></Box>
        <Divider sx={{ width: "100%" }}></Divider>

        <Box sx={{ height: 15 }}></Box>
        <img src={pic1} alt="pic1" style={{ width: "70%" }} />
        <Typography sx={{ mt: 1.1, fontSize: 15 }} variant="body2">
          一万円のおふだ(普通: 60cm、小:30cm)
        </Typography>
        <Box sx={{ height: 40 }}></Box>
        <Divider sx={{ width: "100%" }}></Divider>

        <Box className={classes.fab}>
          <Fab
            aria-label="close"
            onClick={() => handleCloseUpdate()}
            color="primary"
          >
            <CloseIcon></CloseIcon>
          </Fab>
        </Box>
      </Grid>
    </div>
  );
};

export default Ofuda;
