import React, {
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
  Children,
} from "react";
import Button from "@mui/material/Button";
import {
  collection,
  deleteDoc,
  onSnapshot,
  addDoc,
  doc,
  query,
  getDocs,
  where,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  collectionGroup,
} from "firebase/firestore";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { db, app } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { allowContextMenu } from "@fullcalendar/core";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AlertTitle from "@mui/material/AlertTitle";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@mui/styles";
import Fab from "@mui/material/Fab";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useNavigate, Navigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountsDetail from "./AccountsDetail.js";
import { forwardRef, useRef } from "react";
import axios from "axios";
import { useKana } from "react-use-kana";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { IconButton } from "@mui/material";
import BottomMenu from "./Sub/BottomMenu";
import DenseAppbar from "./DenseAppbar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Accounts(props) {
  const sex = ["男性", "女性"];
  const year = [
    "1923年",
    "1924年",
    "1925年",
    "1926年",
    "1927年",
    "1928年",
    "1929年",
    "1930年",
    "1931年",
    "1932年",
    "1933年",
    "1934年",
    "1935年",
    "1936年",
    "1937年",
    "1938年",
    "1939年",
    "1940年",
    "1941年",
    "1942年",
    "1943年",
    "1944年",
    "1945年",
    "1946年",
    "1947年",
    "1948年",
    "1949年",
    "1950年",
    "1951年",
    "1952年",
    "1953年",
    "1954年",
    "1955年",
    "1956年",
    "1957年",
    "1958年",
    "1959年",
    "1960年",
    "1961年",
    "1962年",
    "1963年",
    "1964年",
    "1965年",
    "1966年",
    "1967年",
    "1968年",
    "1969年",
    "1970年",
    "1971年",
    "1972年",
    "1973年",
    "1974年",
    "1975年",
    "1976年",
    "1977年",
    "1978年",
    "1979年",
    "1980年",
    "1981年",
    "1982年",
    "1983年",
    "1984年",
    "1985年",
    "1986年",
    "1987年",
    "1988年",
    "1989年",
    "1990年",
    "1991年",
    "1992年",
    "1993年",
    "1994年",
    "1995年",
    "1996年",
    "1997年",
    "1998年",
    "1999年",
    "2000年",
    "2001年",
    "2002年",
    "2003年",
    "2004年",
    "2005年",
    "2006年",
    "2007年",
    "2008年",
    "2009年",
    "2010年",
    "2011年",
    "2012年",
    "2013年",
    "2014年",
    "2015年",
    "2016年",
    "2017年",
    "2018年",
    "2019年",
    "2020年",
    "2021年",
    "2022年",
    "2023年",
  ];

  const [accounts, setAccounts] = useState("");
  const [ids, setIds] = useState();
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [document_id, setDocumentId] = useState();
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [deleteD, setDeleteD] = useState(false);
  const [alertUpdate, setAlertUpdate] = useState(false);
  const [alertDelete, setAlertDelete] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [contact_error, setContactError] = useState("");
  //編集Dialogを開いたユーザーのindex
  const [dialog_index, setDialog_index] = useState(0);
  const [Contact, setContact] = useState(false);
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [post1, setPost1] = useState("");
  const [post2, setPost2] = useState("");
  const [zipcode, setZipcodeMain] = useState({
    main: "",
    sub: "",
  });
  const [address, setAddress] = useState({
    address1: "",
    address2: "",
    address3: "",
  });
  const [address_value, setAddress_value] = useState("");
  const [sex_value, setSexValue] = useState(sex[0]);
  const [input_sex, setInputSex] = useState(sex[0]);

  const [year_value, setYear] = useState(year[70]);
  const [input_year, setInputYear] = useState(year[70]);

  const useStyles = makeStyles((theme) => ({
    fab: {
      borderRadius: 50,
    },
    root: {
      "& .MuiOutlinedInput-input": {
        border: 0,
        borderRadius: 3,
        fontSize: 18,
        fontWeight: "bold",
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    get_info();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log("year_value: " + year_value);
    console.log("input_year: " + input_year);
  }, [year_value, input_year]);

  useEffect(
    () => {
      console.log(
        "Address: ",
        address["address1"] + address["address2"] + address["address3"]
      );
      setValue(
        "adress",
        address["address1"] + address["address2"] + address["address3"]
      );
      console.log("setAddress_value");
    },
    [address, post1, post2],
    address_value
  );

  //NOTE - DB
  const get_info = async () => {
    try {
      let docId = "";
      const auth = getAuth();
      const current_uid = auth.currentUser.uid;
      const userRef = collection(db, "account_list");
      //コレクションから条件クエリし、refを取得
      const q = query(userRef, where("uid", "==", current_uid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setDocumentId(doc.id);
        console.log("user doc id: " + doc.id);
        docId = doc.id;
      });
      getCurrentAccount(userRef, docId);

      //電話番号とメアドを取得
      //メアドと電話番号を取得
      const docRef = doc(db, "account_list", docId);
      const docSnap = await getDoc(docRef);
      const values = docSnap.data();
      console.log("email: " + values["email"]);
      console.log("phone: " + values["phone"]);
      setValue("phone", values["phone"]);
      setValue("email,", values["email"]);
    } catch (error) {
      console.log("error at get info: " + error);
    }
  };

  const getCurrentAccount = async (userRef, docid) => {
    console.log("get current account was called");
    try {
      let index = 0;
      let accounts_values = [""];
      let accounts_docid = [];

      //登録されているアカウント全て取得
      const accountsRef = collection(db, "account_list", docid, "accounts");
      const accountsSnaps = await getDocs(accountsRef);
      accountsSnaps.forEach((d) => {
        accounts_values[index] = d.data();
        accounts_docid[index] = d.id;
        index += 1;
        console.log("account doc id=> " + d.id);
      });
      setAccounts(accounts_values);
      console.log("accounts_docid = " + accounts_docid);
      setIds(accounts_docid);
      console.log("accounts-> " + JSON.stringify(accounts_values));
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (index) => {
    console.log("index : " + index);
    const new_index = index;
    setDialog_index(new_index);
    console.log("dialog_index : " + new_index);
    handleOpenUpdate();
  };

  //個人情報を更新する
  const updateDB = async (value) => {
    console.log("-> updateDB was called");
    console.log("value: " + JSON.stringify(value));
    const values = value;
    console.log("document_id: " + document_id);
    console.log("ids[dialog_index]: " + ids[dialog_index]);
    const targetdocRef = doc(
      db,
      "account_list",
      document_id,
      "accounts",
      ids[dialog_index]
    );
    console.log(
      "name:" +
        values.nameUpdate +
        "name_furi:" +
        values.name_furiUpdate +
        "postcode:" +
        values.postcodeUpdate +
        "adress:" +
        values.adressUpdate +
        "adress_furi:" +
        values.adress_furiUpdate
    );
    await setDoc(targetdocRef, {
      name: values.nameUpdate,
      name_furi: values.name_furiUpdate,
      birthday: values.birthday,
      sex: values.sex,
      postcode: values.postcodeUpdate,
      adress: values.adressUpdate,
      adress_furi: values.adress_furiUpdate,
    });

    AlertUpdateOpen();
    get_info();
    getCurrentAccount();
  };

  //アカウントを削除する
  const deleteDB = async () => {
    console.log("-> deleteDB was called");
    const targetdocRef = doc(
      db,
      "account_list",
      document_id,
      "accounts",
      ids[dialog_index]
    );
    await deleteDoc(targetdocRef);
    deleteDClose();
    AlertDeleteOpen();
    setDialog_index(0);
    get_info();
    getCurrentAccount();
  };

  //アカウントを追加する
  const addDB = async () => {
    const input_data = getValues();
    console.log("input_year: " + input_year);
    const data = {
      name: input_data.name,
      name_furi: input_data.name_furi,
      birthday: input_year,
      sex: input_sex,
      postcode: input_data.postcode1 + input_data.postcode2,
      adress: input_data.adress,
      adress_furi: input_data.adress_furi,
    };
    console.log("input_data-> " + JSON.stringify(data));
    console.log("document_id-> " + document_id);
    const docRef = doc(db, "account_list", document_id);
    const colRef = collection(docRef, "accounts");
    //既に存在するDBに登録

    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("doc of accounts:", docSnap.data());
        await addDoc(colRef, data);
        handleOpen();
      } else {
        console.log("No such document! at Accounts.js");
      }
    } catch {}
    get_info();
    getCurrentAccount();
  };

  const addMailDB = async () => {
    const input_data = getValues();
    //account_list:accounts:....
    const docRef = doc(db, "account_list", document_id);
    //電話番号とメールアドレスを登録
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("doc of accounts:", docSnap.data());
      await updateDoc(docRef, {
        email: input_data.email,
        phone: input_data.phone,
      });
      setContact(true);
    } else {
      console.log("No such document! at accounts");
    }
  };

  const checkForm = () => {
    console.log("checkForm was called");
    const input_data = getValues();
    console.log(JSON.stringify(input_data));

    if (input_data.name == "") {
      setError("入力内容に不備があります");
      return;
    } else if (input_data.name_furi == "") {
      setError("入力内容に不備があります");
      return;
    } else if (input_year == "") {
      setError("入力内容に不備があります");
      return;
    } else if (input_data.postcode1 == "") {
      setError("入力内容に不備があります");
      return;
    } else if (input_data.postcode2 == "") {
      setError("入力内容に不備があります");
      return;
    } else if (input_data.adress == "") {
      setError("入力内容に不備があります");
      return;
    } else if (input_data.adress_furi == "") {
      setError("入力内容に不備があります");
      return;
    } else {
      addDB();
    }
  };

  const checkMailForm = () => {
    const input_data = getValues();
    if (input_data.email == "") {
      setContactError(
        "入力内容に不備があります、メールアドレスも入力して下さい"
      );
      return;
    } else if (input_data.phone == "") {
      setContactError("入力内容に不備があります、電話番号も入力して下さい");
    } else {
      addMailDB();
    }
  };

  const updateZipcodeMain = (v) => {
    console.log("main: " + v);
    setZipcodeMain({ ...zipcode, main: v });
  };
  const updateZipcodeSub = async (v) => {
    console.log("sub: " + v);

    setZipcodeMain({ ...zipcode, sub: v });
    if (v.length === 4 && zipcode.main.length === 3) {
      console.log("if");
      try {
        const res = await axios.get(
          "https://zipcloud.ibsnet.co.jp/api/search",
          {
            params: {
              zipcode: zipcode.main + v,
            },
          }
        );
        if (res.data.results) {
          const result = res.data.results[0];
          setAddress({
            ...address,
            address1: result["address1"],
            address2: result["address2"],
            address3: result["address3"],
          });
        }
      } catch {
        alert("住所の取得に失敗しました。");
      }
    }
  };

  //NOTE - Dialog
  //登録Dialog
  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };
  const handleOpen = () => {
    setOpen(true);
  };
  //更新用ダイアログ
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  const handleOpenUpdate = () => {
    setOpenUpdate(true);
  };

  //消去Dialog
  const deleteDClose = () => {
    setDeleteD(false);
    handleCloseUpdate();
  };

  const deleteDOpen = () => {
    console.log("deleteMOpen was called");
    setDeleteD(true);
  };

  //更新通知D
  const AlertUpdateOpen = () => {
    setAlertUpdate(true);
  };

  const AlertUpdateClose = () => {
    setAlertUpdate(false);
    handleCloseUpdate();
  };

  //削除通知D
  const AlertDeleteOpen = () => {
    setAlertDelete(true);
  };

  const AlertDeleteClose = () => {
    setAlertDelete(false);
    handleCloseUpdate();
  };

  const onSubmit = (action) => {
    if (action === "back") {
      navigate("/");
      console.log("goHome was called");
    }
  };

  //連絡先通知D
  const ContactOpen = () => {
    setContact(true);
  };

  const ContactClose = () => {
    setContact(false);
  };

  return (
    <div>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        textAlign={"left"}
        sx={{ height: "100%" }}
      >
        <DenseAppbar
          text="登録内容"
          magatama={props.magatama_count}
          // ex_count={ex_count}
        ></DenseAppbar>
        <Box sx={{ height: 40 }}></Box>
        <Grid container justify="flex-start" alignItems="flex-start" pl={2}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            sx={{
              backgroundColor: "#ece9e9f2",
              height: 44,
              width: 120,
              borderRadius: 2,
            }}
          >
            <IconButton onClick={() => onSubmit("back")}>
              <ArrowBackIosNewOutlinedIcon
                sx={{ color: "black", fontSize: 24 }}
              ></ArrowBackIosNewOutlinedIcon>
              <Typography sx={{ fontSize: 18, fontWeight: "bold", ml: 1 }}>
                もどる
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
        <Box sx={{ height: 40 }}></Box>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="center"
          direction="column"
          textAlign={"left"}
          pl={3}
        >
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} component="div">
            ご連絡先の編集
          </Typography>
        </Grid>
        <Box sx={{ height: 20 }}></Box>
        {contact_error && (
          <Typography sx={{ color: "red", fontSize: 16, fontWeight: "bold" }}>
            {contact_error}
          </Typography>
        )}
        <form onSubmit={handleSubmit(addMailDB)} style={{ width: "90%" }}>
          <Card sx={{ width: "100%" }}>
            <CardContent mb={2} sx={{ p: 0, "&:last-child": { pb: 0 } }}>
              <Grid container alignItems="center" justifyContent={"center"}>
                <Box sx={{ my: 3, mx: 2, mt: 1 }}>
                  <Grid item xs={12} sx={{ my: 3 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      電話番号
                    </Typography>
                    <TextField
                      autoFocus={false}
                      variant="outlined"
                      sx={{ width: 250 }}
                      type="text"
                      name="phone"
                      defaultValue=""
                      className={classes.root}
                      {...register("phone", { required: true })}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 3 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      メールアドレス
                    </Typography>
                    <TextField
                      autoFocus={false}
                      variant="outlined"
                      sx={{ width: 250 }}
                      type="text"
                      name="email"
                      defaultValue=""
                      className={classes.root}
                      {...register("email", { required: true })}
                    ></TextField>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    direction="column"
                    textAlign={"center"}
                  >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#ffa94d",
                        color: "white",
                        width: "60%",
                        height: 55,
                      }}
                      onClick={() => checkMailForm()}
                    >
                      <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                        登録する
                      </Typography>
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </form>
        <Box sx={{ height: 40 }}></Box>

        <Divider style={{ width: "90%" }} />

        <Box sx={{ height: 40 }}></Box>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="center"
          direction="column"
          textAlign={"left"}
          pl={3}
        >
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} component="div">
            基本情報の編集
          </Typography>
        </Grid>
        <Box sx={{ height: 30 }}></Box>

        {accounts != "" &&
          accounts.map((data, i) => (
            <Grid
              key={i}
              container
              alignItems={"center"}
              justifyContent="center"
              sx={{ width: "100%", mb: 2 }}
            >
              <Card sx={{ width: "90%" }}>
                <CardContent>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    textAlign={"left"}
                  >
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                        {accounts[i]["name"] + " 様"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems={"center"}
                        justifyContent="center"
                        sx={{ width: "100%" }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#ffa94d",
                            color: "white",
                            width: "100%",
                            height: 55,
                          }}
                          onClick={() => handleEdit(i)}
                        >
                          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                            編集する
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Box sx={{ height: 20 }}></Box>
            </Grid>
          ))}
        {accounts == "" && (
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="info">
              登録されているアカウントがありません。
            </Alert>
          </Stack>
        )}
      </Grid>
      <Box sx={{ height: 40 }}></Box>

      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"left"}
      >
        <Divider sx={{ width: "90%" }} />
        <Box sx={{ height: 40 }}></Box>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="center"
          direction="column"
          textAlign={"left"}
          pl={3}
        >
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} component="div">
            アカウントの追加
          </Typography>
        </Grid>
        <Box sx={{ height: 40 }}></Box>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <form onSubmit={handleSubmit(addDB)}>
          <Card sx={{ width: "100%" }}>
            <CardContent mb={2} sx={{ p: 0, "&:last-child": { pb: 0 } }}>
              <Grid container alignItems="center">
                <Box sx={{ my: 3, mx: 2, mt: 1 }}>
                  <Grid item xs={12} sx={{ mb: 3 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      お名前
                    </Typography>
                    <TextField
                      autoFocus={false}
                      variant="outlined"
                      sx={{ width: 290 }}
                      type="text"
                      name="name"
                      className={classes.root}
                      {...register("name", { required: true })}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 3 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      フリガナ
                    </Typography>
                    <TextField
                      autoFocus={false}
                      sx={{ width: 290 }}
                      variant="outlined"
                      type="text"
                      name="name_furi"
                      className={classes.root}
                      {...register("name_furi", { required: true })}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 3 }}>
                    <Typography
                      mb={1}
                      sx={{ fontSize: 16, fontWeight: "bold" }}
                    >
                      誕生年と性別
                    </Typography>
                    <Grid container sx={{ width: "100%" }}>
                      <Autocomplete
                        value={year_value}
                        inputValue={input_year}
                        onChange={(event, newValue) => {
                          setYear(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                          setInputYear(newInputValue);
                          setYear(newInputValue);
                        }}
                        name="birthday"
                        options={year}
                        sx={{ width: 140, mr: 1 }}
                        renderInput={({ inputProps, ...rest }) => (
                          <TextField
                            autoFocus={false}
                            {...rest}
                            className={classes.root}
                            inputProps={{ ...inputProps, readOnly: true }}
                            label="生まれた年"
                          />
                        )}
                        {...register("birthday", { required: true })}
                      />
                      <Box
                        sx={{ height: 10 }}
                        style={{ backgroundColor: "red" }}
                      ></Box>
                      <Autocomplete
                        value={sex_value}
                        inputValue={sex_value}
                        onChange={(event, newValue) => {
                          setSexValue(newValue);
                        }}
                        onInputChange={(event, newValue) => {
                          setInputSex(newValue);
                          setSexValue(newValue);
                        }}
                        name="sex"
                        options={sex}
                        sx={{ width: 140 }}
                        renderInput={({ inputProps, ...rest }) => (
                          <TextField
                            autoFocus={false}
                            {...rest}
                            className={classes.root}
                            inputProps={{ ...inputProps, readOnly: true }}
                            label="性別"
                          />
                        )}
                        {...register("sex", { required: true })}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ mb: 3 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      郵便番号
                    </Typography>

                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      direction="row"
                      textAlign={"left"}
                    >
                      <TextField
                        sx={{ width: 110 }}
                        autoFocus={false}
                        variant="outlined"
                        type="text"
                        className={classes.root}
                        name="postcode1"
                        {...register("postcode1", { required: true })}
                        value={post1}
                        onChange={(event) => {
                          setPost1(event.target.value);
                          updateZipcodeMain(event.target.value);
                        }}
                      ></TextField>
                      <Box>
                        <Typography> ー </Typography>
                      </Box>
                      <TextField
                        sx={{ width: 110 }}
                        variant="outlined"
                        autoFocus={false}
                        className={classes.root}
                        type="text"
                        name="postcode2"
                        {...register("postcode2", { required: true })}
                        value={post2}
                        onChange={(event) => {
                          setPost2(event.target.value);
                          updateZipcodeSub(event.target.value);
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ mb: 3 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      住所
                    </Typography>
                    <TextField
                      autoFocus={false}
                      sx={{ width: 290 }}
                      variant="outlined"
                      type="text"
                      className={classes.root}
                      // inputvalue={address_value}
                      // onChange={(event) => {
                      //   console.log("onChange was called");
                      //   setAddress_value(event.target.value);
                      // }}
                      name="adress"
                      {...register("adress", { required: true })}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 3 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      フリガナ（番地を除く）
                    </Typography>
                    <TextField
                      autoFocus={false}
                      sx={{ width: 290 }}
                      variant="outlined"
                      type="text"
                      className={classes.root}
                      name="adress_furi"
                      {...register("adress_furi", { required: true })}
                    ></TextField>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    direction="column"
                    textAlign={"center"}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#ffa94d",
                        color: "white",
                        width: "60%",
                        height: 55,
                      }}
                      onClick={() => checkForm()}
                    >
                      <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                        登録する
                      </Typography>
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </Grid>
      <BottomMenu value={4}></BottomMenu>
      <Box sx={{ height: 30 }}></Box>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"left"}
      >
        <Box sx={{ height: 20 }}></Box>
      </Grid>

      <Box sx={{ height: 100 }}></Box>
      {accounts != "" && (
        <Dialog
          fullScreen
          onClose={handleCloseUpdate}
          open={openUpdate}
          TransitionComponent={Transition}
        >
          <AccountsDetail
            accounts={accounts}
            dialog_index={dialog_index}
            updateDB={(value) => updateDB(value)}
            deleteDOpen={() => deleteDOpen()}
            handleCloseUpdate={() => handleCloseUpdate()}
          ></AccountsDetail>
        </Dialog>
      )}

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            アカウントを登録しました
          </Typography>
        </DialogTitle>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              backgroundColor: "#ece9e9f2",
              width: "60%",
              height: 55,
              color: "black",
            }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              もどる
            </Typography>
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>

      <Dialog onClose={AlertUpdateClose} open={alertUpdate}>
        <DialogTitle>
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            アカウントを更新しました
          </Typography>
        </DialogTitle>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          <Button
            variant="contained"
            onClick={AlertUpdateClose}
            sx={{
              backgroundColor: "#ece9e9f2",
              width: "60%",
              height: 55,
              color: "black",
            }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              もどる
            </Typography>
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>

      <Dialog onClose={AlertDeleteClose} open={alertDelete}>
        <DialogTitle>
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            アカウントを削除しました
          </Typography>
        </DialogTitle>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          <Button
            variant="contained"
            color="inherit"
            onClick={AlertDeleteClose}
            sx={{
              backgroundColor: "#ece9e9f2",
              width: "60%",
              height: 55,
              color: "black",
            }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              もどる
            </Typography>
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>

      <Dialog onClose={deleteDClose} open={deleteD}>
        <DialogTitle>
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            削除してもよろしいですか？
          </Typography>
        </DialogTitle>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#c33d3d",
              color: "white",
              width: "60%",
              height: 55,
              mb: 2,
            }}
            onClick={() => deleteDB()}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              削除
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#ece9e9f2",
              width: "60%",
              height: 55,
              color: "black",
            }}
            onClick={() => deleteDClose()}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              もどる
            </Typography>
          </Button>

          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>

      <Dialog onClose={ContactOpen} open={Contact}>
        <DialogTitle>
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            連絡先を更新しました
          </Typography>
        </DialogTitle>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          <Button
            variant="contained"
            onClick={ContactClose}
            sx={{
              backgroundColor: "#ece9e9f2",
              height: 55,
              width: "60%",
              color: "black",
            }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              もどる
            </Typography>
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>
    </div>
  );
}
