import React, { useContext, useEffect, useState, useLayoutEffect } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export default function Family(props) {
  const accounts = props.accounts;
  if (accounts === "") {
    // console.log("accounts" + accounts)
  }

  const isYaku = (year, sex) => {
    let yaku = "";

    //八方塞
    switch (year) {
      case "1924年":
        yaku = "八方塞";
        break;
      case "1933年":
        yaku = "八方塞";
        break;

      case "1942年":
        yaku = "八方塞";
        break;

      case "1951年":
        yaku = "八方塞";
        break;

      case "1960年":
        yaku = "八方塞";
        break;
      case "1969年":
        yaku = "八方塞";
        break;
      case "1978年":
        yaku = "八方塞";
        break;
      case "1987年":
        yaku = "八方塞";
        break;
      case "1996年":
        yaku = "八方塞";
        break;
      case "2005年":
        yaku = "八方塞";
        break;
      case "2014年":
        yaku = "八方塞";
        break;
      case "2023年":
        yaku = "八方塞";
        break;
      default:
        break;
    }

    //鬼門
    switch (year) {
      case "1921年":
        yaku = "鬼門";
        break;
      case "1930年":
        yaku = "鬼門";
        break;
      case "1939年":
        yaku = "鬼門";
        break;
      case "1948年":
        yaku = "鬼門";
        break;
      case "1957年":
        yaku = "鬼門";
        break;
      case "1966年":
        yaku = "鬼門";
        break;
      case "1975年":
        yaku = "鬼門";
        break;
      case "1984年":
        yaku = "鬼門";
        break;
      case "1993年":
        yaku = "鬼門";
        break;
      case "2002年":
        yaku = "鬼門";
        break;
      case "2011年":
        yaku = "鬼門";
        break;
      case "2020年":
        yaku = "鬼門";
        break;
      default:
        break;
    }

    //病門
    switch (year) {
      case "1918年":
        yaku = "病門";
        break;
      case "1927年":
        yaku = "病門";
        break;
      case "1936年":
        yaku = "病門";
        break;
      case "1945年":
        yaku = "病門";
        break;
      case "1954年":
        yaku = "病門";
        break;
      case "1963年":
        yaku = "病門";
        break;
      case "1972年":
        yaku = "病門";
        break;
      case "1981年":
        yaku = "病門";
        break;
      case "1990年":
        yaku = "病門";
        break;
      case "1999年":
        yaku = "病門";
        break;
      case "2008年":
        yaku = "病門";
        break;
      case "2017年":
        yaku = "病門";
        break;

      default:
        break;
    }

    //男性の厄年
    if (sex === "男性") {
      switch (year) {
        case "1923年":
          break;
        case "1924年":
          break;
        case "1925年":
          break;
        case "1926年":
          break;
        case "1927年":
          break;
        case "1928年":
          break;
        case "1929年":
          break;
        case "1930年":
          break;
        case "1931年":
          break;
        case "1932年":
          break;
        case "1933年":
          break;
        case "1934年":
          break;
        case "1935年":
          break;
        case "1936年":
          break;
        case "1937年":
          break;
        case "1938年":
          break;
        case "1939年":
          break;
        case "1940年":
          break;
        case "1941年":
          break;
        case "1942年":
          break;
        case "1943年":
          break;
        case "1944年":
          break;
        case "1945年":
          break;
        case "1946年":
          break;
        case "1947年":
          break;
        case "1948年":
          break;
        case "1949年":
          break;
        case "1950年":
          break;
        case "1951年":
          break;
        case "1952年":
          break;
        case "1953年":
          break;
        case "1954年":
          break;
        case "1955年":
          break;
        case "1956年":
          break;
        case "1957年":
          break;
        case "1958年":
          break;
        case "1959年":
          break;
        case "1960年":
          break;
        case "1961年":
          break;
        case "1962年":
          yaku = "後厄";
          break;
        case "1963年":
          yaku = "本厄";
          break;
        case "1964年":
          yaku = "前厄";
          break;
        case "1965年":
          break;
        case "1966年":
          break;
        case "1967年":
          break;
        case "1968年":
          break;
        case "1969年":
          break;
        case "1970年":
          break;
        case "1971年":
          break;
        case "1972年":
          break;
        case "1973年":
          break;
        case "1974年":
          break;
        case "1975年":
          break;
        case "1976年":
          break;
        case "1977年":
          break;
        case "1978年":
          break;
        case "1979年":
          break;
        case "1980年":
          break;
        case "1981年":
          yaku = "後厄";
          break;
        case "1982年":
          yaku = "本厄";
          break;
        case "1983年":
          yaku = "前厄";
          break;
        case "1984年":
          break;
        case "1985年":
          break;
        case "1986年":
          break;
        case "1987年":
          break;
        case "1988年":
          break;
        case "1989年":
          break;
        case "1990年":
          break;
        case "1991年":
          break;
        case "1992年":
          break;
        case "1993年":
          break;
        case "1994年":
          break;
        case "1995年":
          break;
        case "1996年":
          break;
        case "1997年":
          break;
        case "1998年":
          yaku = "後厄";
          break;
        case "1999年":
          yaku = "本厄";
          break;
        case "2000年":
          yaku = "前厄";
          break;
        case "2001年":
          break;
        case "2002年":
          break;
        case "2003年":
          break;
        case "2004年":
          break;
        case "2005年":
          break;
        case "2006年":
          break;
        case "2007年":
          break;
        case "2008年":
          break;
        case "2009年":
          break;
        case "2010年":
          break;
        case "2011年":
          break;
        case "2012年":
          break;
        case "2013年":
          break;
        case "2014年":
          break;
        case "2015年":
          break;
        case "2016年":
          break;
        case "2017年":
          break;
        case "2018年":
          break;
        case "2019年":
          break;
        case "2020年":
          break;
        case "2021年":
          break;
        case "2022年":
          break;
        case "2023年":
          break;

        default:
          break;
      }
    } else {
      //女性の厄年
      switch (year) {
        case "1923年":
          break;
        case "1924年":
          break;
        case "1925年":
          break;
        case "1926年":
          break;
        case "1927年":
          break;
        case "1928年":
          break;
        case "1929年":
          break;
        case "1930年":
          break;
        case "1931年":
          break;
        case "1932年":
          break;
        case "1933年":
          break;
        case "1934年":
          break;
        case "1935年":
          break;
        case "1936年":
          break;
        case "1937年":
          break;
        case "1938年":
          break;
        case "1939年":
          break;
        case "1940年":
          break;
        case "1941年":
          break;
        case "1942年":
          break;
        case "1943年":
          break;
        case "1944年":
          break;
        case "1945年":
          break;
        case "1946年":
          break;
        case "1947年":
          break;
        case "1948年":
          break;
        case "1949年":
          break;
        case "1950年":
          break;
        case "1951年":
          break;
        case "1952年":
          break;
        case "1953年":
          break;
        case "1954年":
          break;
        case "1955年":
          break;
        case "1956年":
          break;
        case "1957年":
          break;
        case "1958年":
          break;
        case "1959年":
          break;
        case "1960年":
          break;
        case "1961年":
          break;
        case "1962年":
          yaku = "後厄";
          break;
        case "1963年":
          yaku = "本厄";
          break;
        case "1964年":
          yaku = "前厄";
          break;
        case "1965年":
          break;
        case "1966年":
          break;
        case "1967年":
          break;
        case "1968年":
          break;
        case "1969年":
          break;
        case "1970年":
          break;
        case "1971年":
          break;
        case "1972年":
          break;
        case "1973年":
          break;
        case "1974年":
          break;
        case "1975年":
          break;
        case "1976年":
          break;
        case "1977年":
          break;
        case "1978年":
          break;
        case "1979年":
          break;
        case "1980年":
          break;
        case "1981年":
          break;
        case "1982年":
          break;
        case "1983年":
          break;
        case "1984年":
          break;
        case "1985年":
          break;
        case "1986年":
          yaku = "後厄";
          break;
        case "1987年":
          yaku = "本厄";
          break;
        case "1988年":
          yaku = "前厄";
          break;
        case "1989年":
          break;
        case "1990年":
          yaku = "後厄";
          break;
        case "1991年":
          yaku = "本厄";
          break;
        case "1992年":
          yaku = "前厄";
          break;
        case "1993年":
          break;
        case "1994年":
          break;
        case "1995年":
          break;
        case "1996年":
          break;
        case "1997年":
          break;
        case "1998年":
          break;
        case "1999年":
          break;
        case "2000年":
          break;
        case "2001年":
          break;
        case "2002年":
          break;
        case "2003年":
          break;
        case "2004年":
          yaku = "後厄";
          break;
        case "2005年":
          yaku = "本厄";
          break;
        case "2006年":
          yaku = "前厄";
          break;
        case "2007年":
          break;
        case "2008年":
          break;
        case "2009年":
          break;
        case "2010年":
          break;
        case "2011年":
          break;
        case "2012年":
          break;
        case "2013年":
          break;
        case "2014年":
          break;
        case "2015年":
          break;
        case "2016年":
          break;
        case "2017年":
          break;
        case "2018年":
          break;
        case "2019年":
          break;
        case "2020年":
          break;
        case "2021年":
          break;
        case "2022年":
          break;
        case "2023年":
          break;

        default:
          break;
      }
    }

    //年祝い
    switch (year) {
      case "1924年":
        yaku = "白寿";
        break;
      case "1934年":
        yaku = "卒寿";
        break;
      case "1936年":
        yaku = "米寿";
        break;
      case "1944年":
        yaku = "傘寿";
        break;
      case "1947年":
        yaku = "喜寿";
        break;
      case "1954年":
        yaku = "古稀";
        break;
      case "1963年":
        yaku = "還暦";
        break;
      default:
        break;
    }

    return yaku;
  };

  return (
    <Grid
      mt={2}
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"left"}
    >
      {accounts != "" &&
        accounts.map((data, i) => (
          <Grid key={i} mb={1}>
            <Card sx={{ minWidth: 280, height: 55 }}>
              <CardContent>
                <Grid container justify="flex-start" alignItems="center">
                  <Typography
                    sx={{ fontSize: 18, fontWeight: "bold" }}
                    pl={1}
                    pr={1}
                  >
                    {accounts[i]["name"] + " 様"}
                  </Typography>
                  {isYaku(accounts[i]["birthday"], accounts[i]["sex"]) !==
                    "" && (
                    <Grid>
                      <Typography
                        sx={{
                          fontSize: 16,
                          backgroundColor: "#f7ad6c",
                          fontWeight: "bold",
                          borderRadius: 2,
                        }}
                      >
                        {"「" +
                          isYaku(accounts[i]["birthday"], accounts[i]["sex"]) +
                          "」の年　"}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      {accounts == "" && (
        <Box
          sx={{
            width: "90%",
            backgroundColor: "#fffab995",
            p: 2,
            borderRadius: 2,
          }}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            ご家族やご友人を登録して、厄年かどうか確認してみましょう
          </Typography>
        </Box>
      )}
    </Grid>
  );
}
