import React, {
  useState,
  useContext,
  useLayoutEffect,
  useEffect,
  useRef,
} from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import ex from "../../images/ex.png";

export default function ExpointComponent(props) {
  return (
    <Grid container alignItems={"center"} justifyContent={"center"}>
      <img src={ex} height={"25"} />
      <Typography sx={{ fontSize: 16, color: "black", ml: 1 }}>
        {props.ex_count}
      </Typography>
    </Grid>
  );
}
