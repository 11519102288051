import { React, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Fab from "@mui/material/Fab";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate, Navigate, Link } from "react-router-dom";

const Privacy = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"center"}
      p={3}
    >
      <Grid
        container
        alignItems="left"
        justify="left"
        direction="column"
        textAlign={"left"}
      >
        <Typography sx={{ fontSize: 18 }} mb={2}>
          プライバシーポリシー（個人情報保護方針）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          いわき市大國魂神社（以下、「当社」という。）は，ユーザーの個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。本ポリシーは、当社がどのような個人情報を取得し、どのように利用・共有するか、ユーザーがどのようにご自身の個人情報を管理できるかをご説明するものです。
        </Typography>
        <Typography sx={{ fontSize: 13 }}>【1. 個人情報の取得方法】</Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社はユーザーが利用登録をするとき、氏名・生年月日・住所・電話番号・メールアドレスなど個人を特定できる情報を取得させていただきます。
          お問い合わせフォームやコメントの送信時には、氏名・電話番号・メールアドレスを取得させていただきます。
        </Typography>
        <Typography sx={{ fontSize: 13 }}>
          【2．個人情報の利用目的】{" "}
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          取得した情報は、ご祈祷や参拝に関する情報をお知らせするために利用します。
        </Typography>
        <Typography sx={{ fontSize: 13 }}>
          【３．個人データを安全に管理するための措置】{" "}
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社は個人情報を正確かつ最新の内容に保つよう努め、不正なアクセス・改ざん・漏えい・滅失及び毀損から保護するためのソフトウェアを使用しています。また、個人情報の管理についても定期的に点検を行っています。
        </Typography>
        <Typography sx={{ fontSize: 13 }}>
          【4．個人データの第三者提供について】{" "}
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社は法令及びガイドラインに別段の定めがある場合を除き、同意を得ないで第三者に個人情報を提供することは致しません。
        </Typography>
        <Typography sx={{ fontSize: 13 }}>
          【5．保有個人データの開示、訂正】{" "}
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社は本人から個人情報の開示を求められたときには、遅滞なく本人に対しこれを開示します。個人情報の利用目的の通知や訂正、追加、削除、利用の停止、第三者への提供の停止を希望される方は以下の手順でご請求ください。
          福島県いわき市平菅波字宮前26−1 大國魂神社社務所（0246-34-0092）
        </Typography>
        <Typography sx={{ fontSize: 13 }}>
          【6．個人情報取り扱いに関する相談や苦情の連絡先】{" "}
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社の個人情報の取り扱いに関するご質問やご不明点、苦情、その他のお問い合わせはお問い合わせフォームよりご連絡ください。
        </Typography>
        <Typography sx={{ fontSize: 13 }}>
          【7．SSL（Secure Socket Layer）について】{" "}
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。
        </Typography>
        <Typography sx={{ fontSize: 13 }}>【8．cookieについて】 </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          cookieとは、WebサーバーからWebブラウザに送信されるデータのことです。Webサーバーがcookieを参照することでユーザーのパソコンを識別でき、効率的に当社Webサイトを利用することができます。当社Webサイトがcookieとして送るファイルは、個人を特定するような情報は含んでおりません。
          お使いのWebブラウザの設定により、cookieを無効にすることも可能です。
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          【9．プライバシーポリシーの制定日及び改定日】 制定：2023年1月30日
        </Typography>
        <Typography sx={{ fontSize: 13 }}>【10．免責事項】 </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社Webサイトに掲載されている情報の正確性には万全を期していますが、利用者が当社Webサイトの情報を用いて行う一切の行為に関して、一切の責任を負わないものとします。
          当社は、利用者が当社Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。
        </Typography>
        <Typography sx={{ fontSize: 13 }}>【11．著作権・肖像権】 </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されています。無断での使用や転用は禁止されています。
        </Typography>
        <Typography sx={{ fontSize: 13 }}>【12．リンク】 </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社Webサイトへのリンクは、自由に設置していただいて構いません。ただし、Webサイトの内容等によってはリンクの設置をお断りすることがあります。
        </Typography>
        <Box sx={{ height: 30 }}></Box>

        <Typography sx={{ fontSize: 18 }} mb={2}>
          利用規約
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          この利用規約（以下，「本規約」といいます。）は，いわき市大國魂神社（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          第1条（適用）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
          当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
          本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          第2条（利用登録）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。
          当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
          利用登録の申請に際して虚偽の事項を届け出た場合
          本規約に違反したことがある者からの申請である場合
          その他，当社が利用登録を相当でないと判断した場合
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          第3条（ユーザーIDおよびパスワードの管理）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
          ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。
          ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。
        </Typography>

        <Typography sx={{ fontSize: 13 }} mb={1}>
          第4条（禁止事項）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
          法令または公序良俗に違反する行為 犯罪行為に関連する行為
          本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
          当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
          本サービスによって得られた情報を商業的に利用する行為
          当社のサービスの運営を妨害するおそれのある行為
          不正アクセスをし，またはこれを試みる行為
          他のユーザーに関する個人情報等を収集または蓄積する行為
          不正な目的を持って本サービスを利用する行為
          本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為
          他のユーザーに成りすます行為
          当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為
          面識のない異性との出会いを目的とした行為
          当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
          その他，当社が不適切と判断する行為
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          第5条（本サービスの提供の停止等）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
          本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
          地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
          コンピュータまたは通信回線等が事故により停止した場合
          その他，当社が本サービスの提供が困難と判断した場合
          当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          第6条（利用制限および登録抹消）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。
          本規約のいずれかの条項に違反した場合
          登録事項に虚偽の事実があることが判明した場合
          料金等の支払債務の不履行があった場合
          当社からの連絡に対し，一定期間返答がない場合
          本サービスについて，最終の利用から一定期間利用がない場合
          その他，当社が本サービスの利用を適当でないと判断した場合
          当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          第7条（退会）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          ユーザーは，当社の定める退会手続により，本サービスから退会できるものとします。
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          第8条（保証の否認および免責事項）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
          当社は，本サービスに起因してユーザーに生じたあらゆる損害について、当社の故意又は重過失による場合を除き、一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
          前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
          当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          第9条（サービス内容の変更等）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社は，ユーザーへの事前の告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          第10条（利用規約の変更）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社は以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。
          本規約の変更がユーザーの一般の利益に適合するとき。
          本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。
          当社はユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          第11条（個人情報の取扱い）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          第12条（通知または連絡）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          第13条（権利義務の譲渡の禁止）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          第14条（準拠法・裁判管轄）
        </Typography>
        <Typography sx={{ fontSize: 13 }} mb={1}>
          本規約の解釈にあたっては，日本法を準拠法とします。
          本サービスに関して紛争が生じた場合には，当社の所在地を管轄する裁判所を専属的合意管轄とします。
        </Typography>
      </Grid>
      <Box sx={{ height: 30 }}></Box>

      <Grid item xs={6}>
        <Button
          variant="contained"
          style={{ backgroundColor: "#ff7043", color: "white" }}
          onClick={() => navigate("/")}
        >
          ホームへ戻る
        </Button>
      </Grid>
      <Box sx={{ height: 40 }}></Box>
    </Grid>
  );
};

export default Privacy;
