import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getAuth } from "firebase/auth";
import { useAuthContext } from "../../context/ AuthContext";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { Typography } from "@mui/material";
import syokyu from "../../images/syokyu.jpg";
import cyukyu from "../../images/cyukyu.jpg";
import jyokyu from "../../images/jyokyu.jpg";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import SelectPage from "./SelectPage";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fab from "@mui/material/Fab";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { SimpleScrollGrid } from "@fullcalendar/core";
import axios from "axios";
import DenseAppbar from "../../ Component/DenseAppbar";
import TukinamiGohei from "../../ Component/Sub/TukinamiGohei";
import Divider from "@mui/material/Divider";
import oonusa from "../../images/oonusa.png";
import torii from "../../images/torii.png";
import shelf from "../../images/shelf.png";
import fox from "../../images/fox.png";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import WelcomeCard from "../../ Component/Sub/WelcomeCard";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import BottomMenu from "../../ Component/Sub/BottomMenu";
import Contact from "../../ Component/Sub/Contact";
import MakePWA from "../../ Component/Sub/MakePWA";
import { IconButton } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { GetFieldValues } from "../../ Component/QueryHandler/DailyHandler.js";
import { motion } from "framer-motion";

export default function QuizHome(props) {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [state, setState] = useState(false);
  const [level, SetLevel] = useState(0);
  const [quiz_deta, SetQuizDeta] = useState("");
  const [quiz_progress, SetQuizProgress] = useState(null);
  const [user_id, setUser_id] = useState("");
  const [document_id, setDocumentId] = useState("");
  const [magatama_count, setmagatama_Count] = useState(0);
  const [loading, setLoading] = useState(true);
  const [db_deta, set_db_deta] = useState(null);

  const url =
    "https://sheets.googleapis.com/v4/spreadsheets/1fodIuTaDJKkFgGCHLpLSuqPFkZw8WrwOVarTgSu7fH0/values/参拝のおともサイトデータ?key=AIzaSyAupITr3flkBqDPJk21-Wv9Qb5aP5bJisI";

  const toggleDrawer = (open, index) => {
    setState(open);
    SetLevel(index);
  };

  useLayoutEffect(() => {
    if (user !== null) {
      GetFirebaseDeta();
    }
  }, []);

  const GetFirebaseDeta = async () => {
    const deta = await GetFieldValues();
    set_db_deta(deta);
    console.log("db_deta: " + deta["values"][0]["ex"]);
  };

  useEffect(() => {
    if (magatama_count !== null && quiz_progress !== "" && quiz_deta !== "") {
      setLoading(false);
    }

    if (user == null) {
      setLoading(false);
    }
    console.log("magatama_count: " + magatama_count);
  }, [state, magatama_count, quiz_progress, quiz_deta]);

  useLayoutEffect(() => {
    console.log("useLayoutEffect was called");
    let isMounted = true;

    axios.get(url).then((res) => {
      // console.log(res.data.values);
      const values = res.data.values;
      values.shift();

      // console.log("values: " + JSON.stringify(values));
      if (isMounted) {
        SetQuizDeta(values);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const get_baseinfo = async () => {
      // console.log("get_baseinfo was called");
      try {
        const auth = getAuth();
        const current_uid = auth.currentUser.uid;
        // console.log("current_uid: " + current_uid);
        if (isMounted) {
          setUser_id(current_uid);
        }
        const userRef = collection(db, "account_list");

        let docid = "";
        const q = query(userRef, where("uid", "==", current_uid));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          if (isMounted) {
            setDocumentId(doc.id);
          }
          // console.log("doc id: " + doc.id);
          docid = doc.id;
        });
        GetQuizProgress(docid);
      } catch (e) {
        // console.log("error: " + e.message);
        // setAlert(true);
      }
    };

    const GetQuizProgress = async (docid) => {
      const docRef = doc(db, "account_list", docid);
      const docSnap = await getDoc(docRef);
      const values = docSnap.data();

      if (typeof values["dango"] !== "undefined") {
        //マガタマの数
        if (isMounted) {
          setmagatama_Count(values["dango"]);
        }
      }
      try {
        let quiz_values = "";
        const quizRef = doc(db, "account_list", docid, "quiz", "progress");

        const quizSnaps = await getDoc(quizRef);
        quiz_values = quizSnaps.data();

        console.log("quiz_values: " + JSON.stringify(quiz_values));
        if (typeof quiz_values !== "undefined") {
          if (isMounted) {
            SetQuizProgress(quiz_values);
          }
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    get_baseinfo();
    return () => {
      isMounted = false;
    };
  }, []);

  //クイズの進捗データをクエリ

  const GetQuizProgress = async () => {
    console.log("GetQuizProgress was called");
    const docRef = doc(db, "account_list", document_id);
    const docSnap = await getDoc(docRef);
    const values = docSnap.data();

    if (typeof values["dango"] !== "undefined") {
      //マガタマの数
      setmagatama_Count(values["dango"]);
    }
    try {
      let quiz_values = "";
      const quizRef = doc(db, "account_list", document_id, "quiz", "progress");

      const quizSnaps = await getDoc(quizRef);
      quiz_values = quizSnaps.data();

      console.log("quiz_values: " + JSON.stringify(quiz_values));
      SetQuizProgress(quiz_values);
    } catch (e) {
      console.log(e.message);
    }
  };

  const goKonRoom = () => [navigate("/room")];

  if (!loading) {
    return (
      <div>
        <Grid>
          <DenseAppbar
            text="神社クイズ"
            magatama={magatama_count}
          ></DenseAppbar>
          {/* <Box sx={{ height: 30 }}></Box>
        {user === null && <WelcomeCard></WelcomeCard>}
        <Box sx={{ height: 10 }}></Box>
        <MakePWA /> */}

          {/* <Box sx={{ height: 20 }}></Box>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            pl={2}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              sx={{
                backgroundColor: "#ece9e9f2",
                height: 44,
                width: 120,
                borderRadius: 2,
              }}
            >
              <IconButton onClick={() => goKonRoom()}>
                <ArrowBackIosNewOutlinedIcon
                  sx={{ color: "black", fontSize: 24 }}
                ></ArrowBackIosNewOutlinedIcon>
                <Typography sx={{ fontSize: 18, fontWeight: "bold", ml: 1 }}>
                  もどる
                </Typography>
              </IconButton>
            </Grid>
          </Grid> */}
          <Box sx={{ height: 40 }}></Box>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              textAlign={"left"}
            >
              {/* <Divider sx={{ width: "90%", mb: 2 }}></Divider> */}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                textAlign={"left"}
              ></Grid>
              <Card sx={{ width: "90%", backgroundColor: "#a2e7f159" }}>
                <CardContent
                  sx={{
                    "&:last-child": { pb: 2 },
                  }}
                >
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    textAlign="left"
                  >
                    <Typography
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                      mb={1}
                    >
                      初級 はじめてのおつかい
                    </Typography>
                    <Typography sx={{ fontSize: 16 }} mb={2}>
                      神社の基本を知ろう！
                    </Typography>
                    <Box mb={2}>
                      <img src={syokyu} width="100%"></img>
                    </Box>
                    {/* <Typography sx={{ fontSize: 16 }} mb={2}>
                  乞うご期待ください。
                </Typography> */}

                    <Grid container alignItems="center" textAlign="center">
                      <Grid item xs={12}>
                        <Button
                          sx={{
                            width: "60%",
                            height: 55,
                            backgroundColor: "#ffa94d",
                            color: "white",
                          }}
                          variant="contained"
                          onClick={() => toggleDrawer(true, 0)}
                        >
                          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                            おつかいに行く
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Box sx={{ height: 20 }}></Box>
              {/* <Card sx={{ width: "90%", backgroundColor: "#e6e3e3" }}>
              <CardContent
                sx={{
                  "&:last-child": { pb: 0 },
                }}
              >
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  textAlign="left"
                >
                  <Typography sx={{ fontSize: 20, fontWeight: "bold" }} mb={1}>
                    中級: 陽だまりの丘
                  </Typography>

                  <Typography sx={{ fontSize: 16 }} mb={2}>
                    参拝をもっと楽しみたい方
                  </Typography>
                  <Box mb={2}>
                    <img src={cyukyu} width="100%"></img>
                  </Box>

                  <Typography sx={{ fontSize: 16 }} mb={2}>
                    乞うご期待ください。
                  </Typography>
                </Grid>
                <Box sx={{ height: 30 }}></Box>
              </CardContent>
            </Card>
            <Box sx={{ height: 20 }}></Box>
            <Card sx={{ width: "90%", backgroundColor: "#e6e3e3" }}>
              <CardContent
                sx={{
                  "&:last-child": { pb: 0 },
                }}
              >
                <Grid
                  container
                  justify="center"
                  alignItems="flex-start"
                  textAlign="left"
                  direction={"column"}
                >
                  <Typography sx={{ fontSize: 20, fontWeight: "bold" }} mb={1}>
                    上級: 修行の山
                  </Typography>

                  <Typography sx={{ fontSize: 16 }} mb={2}>
                    神道を深く学びたい方
                  </Typography>
                  <Box mb={2}>
                    <img src={jyokyu} width="100%"></img>
                  </Box>

                  <Typography sx={{ fontSize: 16 }} mb={2}>
                    乞うご期待ください。
                  </Typography>
                </Grid>
                <Box sx={{ height: 30 }}></Box>
              </CardContent>
            </Card> */}
            </Grid>
            <Contact />
            <Box sx={{ height: 80 }}></Box>

            <Dialog
              fullScreen
              onClose={() => toggleDrawer(false, 0)}
              open={state}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                sx={{ height: "100%" }}
              >
                <DenseAppbar
                  text="神社クイズ"
                  magatama={magatama_count}
                ></DenseAppbar>
                <SelectPage
                  index={0}
                  toggleDrawer={() => toggleDrawer(false, 0)}
                  quiz_deta={quiz_deta}
                  quiz_progress={quiz_progress}
                  docid={document_id}
                  magatama_count={magatama_count}
                  GetQuizProgress={() => GetQuizProgress()}
                  db_deta={db_deta}
                />
                <BottomMenu value={2} />
              </Grid>
            </Dialog>
          </motion.div>

          <BottomMenu value={2} />
        </Grid>
      </div>
    );
  } else {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress></CircularProgress>
        <BottomMenu value={2} />
      </Box>
    );
  }
}
