import { React, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Fab from "@mui/material/Fab";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import { useNavigate, Navigate, Link } from "react-router-dom";
import nature from "../../images/nature.jpg";
import goukaku from "../../images/goukaku.jpg";
import anzan from "../../images/anzan.jpg";
import yaku from "../../images/yaku.jpg";
import kuruma from "../../images/kuruma.jpg";
import shichigosan from "../../images/shichigosan.jpg";
import syucho from "../../images/syucho.jpg";
import { useAuthContext } from "../../context/ AuthContext";

const KiganSelect = (props) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  // console.log("porps.contact_data: " + props.contact_data);

  const Check = () => {
    let err = false;
    //userがなかったら
    if (user == null) {
      props.openLoginD();
      err = true;
      return;
    }

    //accountがなかったら
    if (props.accounts == "") {
      props.openAccountD();
      err = true;
    }

    //連絡先が登録されていなかったら
    if (props.contact_data == "" || props.contact_data == undefined) {
      props.openAccountD();
      err = true;
    }

    return err;
  };

  const ToApply1 = (type_v) => {
    if (Check() === false) {
      props.setType(type_v);
      navigate("/apply1");
    }
  };

  const ToApply2 = (type_v) => {
    if (Check() === false) {
      props.setType(type_v);
      navigate("/apply2");
    }
  };
  const ToApply3 = (type_v) => {
    if (Check() === false) {
      props.setType(type_v);
      navigate("/apply3");
    }
  };

  const ToApply4 = (type_v) => {
    if (Check() === false) {
      props.setType(type_v);
      navigate("/apply4");
    }
  };

  const ToApply5 = (type_v) => {
    if (Check() === false) {
      props.setType(type_v);
      navigate("/apply5");
    }
  };

  //厄祓い祈願
  const ToApply6 = (type_v) => {
    if (Check() === false) {
      props.setType(type_v);
      navigate("/apply6");
    }
  };

  const ToPhoneD = (type_v) => {
    if (Check() === false) {
      props.setType(type_v);
      props.phoneDOpen();
    }
  };
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"center"}
      width="90%"
    >
      <Grid sx={{ width: "100%" }} mb={2}>
        <Grid container justify="flex-start" alignItems="flex-start" mb={1}>
          <Grid item xs={5.5}>
            <Card sx={{ height: 100 }}>
              <Grid style={{ backgroundColor: "white", width: "100%" }} p={1}>
                <CardActionArea
                  onClick={() => ToApply6("厄ばらい")}
                  component="a"
                >
                  <CardMedia sx={{ height: 60 }} image={yaku} />
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "bold" }}
                    mt={0.5}
                  >
                    厄除け 年祝い
                  </Typography>
                </CardActionArea>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5.5}>
            <Card sx={{ height: 100 }}>
              <Grid style={{ backgroundColor: "white", width: "100%" }} p={1}>
                <CardActionArea
                  onClick={() => ToApply2("安産祈願")}
                  component="a"
                >
                  <CardMedia sx={{ height: 60 }} image={anzan} />
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "bold" }}
                    mt={0.5}
                  >
                    安産祈願
                  </Typography>
                </CardActionArea>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ width: "100%" }} mb={2}>
        <Grid container justify="flex-start" alignItems="flex-start" mb={1}>
          <Grid item xs={5.5}>
            <Card sx={{ height: 100 }}>
              <Grid style={{ backgroundColor: "white", width: "100%" }} p={1}>
                <CardActionArea
                  onClick={() => ToApply1("一般祈願")}
                  component="a"
                >
                  <CardMedia sx={{ height: 60 }} image={nature} />
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "bold" }}
                    mt={0.5}
                  >
                    一般祈願
                  </Typography>
                </CardActionArea>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={5.5}>
            <Card sx={{ height: 100 }}>
              <Grid style={{ backgroundColor: "white", width: "100%" }} p={1}>
                <CardActionArea onClick={() => ToApply5()} component="a">
                  <CardMedia sx={{ height: 60 }} image={goukaku} />
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "bold" }}
                    mt={0.5}
                  >
                    必勝・合格祈願
                  </Typography>
                </CardActionArea>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justify="flex-start" alignItems="flex-start">
        <Grid item xs={5.5}>
          <Card sx={{ height: 100 }}>
            <Grid style={{ backgroundColor: "white", width: "100%" }} p={1}>
              <CardActionArea
                onClick={() => ToApply3("初宮詣り・七五三")}
                component="a"
              >
                <CardMedia sx={{ height: 60 }} image={shichigosan} />
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }} mt={0.5}>
                  初宮詣り・七五三
                </Typography>
              </CardActionArea>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={5.5}>
          <Card sx={{ height: 100 }}>
            <Grid style={{ backgroundColor: "white", width: "100%" }} p={1}>
              <CardActionArea
                onClick={() => ToApply4("車の祈願")}
                component="a"
              >
                <CardMedia sx={{ height: 60 }} image={kuruma} />
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }} mt={0.5}>
                  車の祈願
                </Typography>
              </CardActionArea>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KiganSelect;
