import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { FacebookAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDkp6qDgEV0QsbBr7c_CyANsjiEx0rGGx8",
  authDomain: "ookunitama-negai.site",
  projectId: "shrine-reservation-app",
  storageBucket: "shrine-reservation-app.appspot.com",
  messagingSenderId: "1055927438791",
  appId: "1:1055927438791:web:6818b0dc729103abff9de2",
};

export const app = firebase.initializeApp(firebaseConfig);

// console.log(firebase.auth);
// console.log(firebaseConfig);
var auth_obj = firebase.auth();

export default firebase;
export const auth = auth_obj;
export const provider = new firebase.auth.GoogleAuthProvider();
export const db = getFirestore();
export const storage = getStorage();
export const facebook_provider = new FacebookAuthProvider();
