import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import pic3 from "../../images/three.jpg";
import pic5 from "../../images/five.jpg";
import pic8 from "../../images/eight.jpg";
import pic1 from "../../images/one.jpg";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { OtherApplyData } from "./BaseOfAnzan.js";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import Negai from "../Sub/Negai.js";
import Ofuda from "../Sub/Ofuda.js";
import { useNavigate, Navigate, Link } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { string } from "yup";
import ScrollTop from "../../helper/ScrollTop";

const Other_1 = (props) => {
  const { currentState, setCurrentState } = useContext(OtherApplyData);
  const defaultCheckedValues = ["B"];
  const { register, control, handleSubmit, getValues, setValues, setValue } =
    useForm({ defaultValues: { num: "1", biko: "" } });
  const { fields, append, remove, replace, insert } = useFieldArray({
    name: "apply",
    control,
  });
  let check_arr = {};
  const [num, setNum] = useState("");
  const [select_type, setSelect_type] = useState("種目1");
  const [select_money, setselect_money] = useState("五千円（普通）");
  const [data, setData] = useState([""]);
  const [NegaiD, SetnegaiD] = useState(false);
  const [OfudaD, SetofudaD] = useState(false);
  const [retryD, SetretryD] = useState(false);
  const [input_pray1, setInputPray1] = useState("家内安全");
  const [input_pray2, setInputPray2] = useState("身体堅固");
  const [firsttime, setFirsttime] = useState(true);
  const accounts = props.accounts;

  useEffect(() => {}, [input_pray1, input_pray2, select_money]);

  let checked = false;

  const navigate = useNavigate();

  useLayoutEffect(() => {
    setNum(props.accounts.length);

    const values = [];
    console.log("num: " + props.accounts.length);
    const form_data = getValues();
    console.log("field value -> " + JSON.stringify(form_data));
    for (let i = 0; i < props.accounts.length; i++) {
      const value = { selected: false, index: i };
      values.push(value);
    }
    setData(values);
  }, []);

  console.log("--------------Rendered--------------------");

  useEffect(() => {
    if (props.accounts == "") {
      navigate("/");
    }
  }, []);

  const handleCheck = (e, index) => {
    console.log("index: " + index);

    data[index]["selected"] = e.target.checked;

    //空辞書を削除する
    // const unformated_data = getValues();
    // const formated_data = deleteEnpty(unformated_data.apply);
    // replace(formated_data);
    // console.log("format_data: " + JSON.stringify(formated_data));

    //選択した時
    if (e.target.checked) {
      checked = true;
      const before_data = getValues();
      console.log("before_data: " + JSON.stringify(before_data));

      if (index === 0) {
        insert(0, {
          index: index,
        });
      } else {
        insert(index, {
          index: index,
        });
      }

      console.log("追加しました");

      const after_data = getValues();
      console.log("after_data: " + JSON.stringify(after_data));
    } else {
      //非選択の時
      const before_data = getValues();
      console.log("before_data: " + JSON.stringify(before_data));
      checked = false;

      if (index === 0) {
        remove(0);
      } else {
        //indexで検索して、その辞書を削除
        let remove_index;
        //replaceで置き換え
        for (let i = 0; i < before_data.apply.length; i++) {
          if (index === before_data.apply[i].index) {
            console.log("index hit!");
            remove_index = i;
          }
        }
        before_data.apply.splice(remove_index, 1);
        console.log(remove_index + " 番目を削除");
        replace(before_data.apply);
      }
      console.log("削除しました");
      const after_data = getValues();
      console.log("after_data: " + JSON.stringify(after_data));
    }
  };

  const goHome = () => {
    navigate("/kigan");
  };

  const useStyles = makeStyles({
    startIcon: {
      position: "absolute",
      left: "1rem",
    },
  });

  const deleteEnpty = (dic) => {
    let format_data = [];
    console.log("dic: " + JSON.stringify(dic));
    console.log("dic: " + JSON.stringify(dic[0]));
    //空の配列を削除する
    if (dic != null && dic != undefined) {
      for (let i = 0; i < dic.length; i++) {
        if (typeof dic[i] === "object" && dic[i] !== null) {
          if (Object.keys(dic[i]).length === 4) {
            format_data.push(dic[i]);
            console.log("dic[i]: " + JSON.stringify(dic[i]));
            console.log("空要素がありませんでした");
          } else {
            console.log("空要素がありました");
          }
        }
      }
    }

    console.log("format_data: " + format_data);

    return format_data;
  };

  function isEmpty(obj) {
    return !Object.keys(obj).length;
  }

  const onSubmit = (action) => {
    //formデータとアカウント情報を紐付ける
    const num = fields.length;
    console.log("選択した人数-> " + num);
    const deta = getValues();
    console.log("form_data: " + JSON.stringify(deta));

    //もし選択してなかったら
    if (deta.apply.length > 0) {
      console.log("ある");
    } else {
      console.log("ない");
      console.log(checked);
      SetretryD(true);
      return;
    }

    const selected_index = [];

    //選択したアカウント分、紐付ける
    console.log("form_data.apply.length: " + deta.apply.length);
    for (let i = 0; i < deta.apply.length; i++) {
      selected_index.push(deta.apply[i].index);
    }

    console.log("selected index: " + selected_index);

    //currentStateに登録する情報
    let account_data;

    for (let i = 0; i < selected_index.length; i++) {
      //選択したアカウントの基本情報
      account_data = [accounts[selected_index[i]]];
      console.log("account_data->" + JSON.stringify(account_data));
    }

    console.log("deta.biko:" + deta.biko);
    let biko_text = "";
    if (deta.biko !== "") {
      biko_text = deta.biko;
    }

    setCurrentState({
      ...currentState,
      num: num,
      biko: biko_text,
      EasyApply1: [account_data],
    });

    if (action === "back") {
      props.goHome();
    } else if (action === "next") {
      props.handleNext();
    }
  };

  //説明用
  //願いの項目
  const negaiDOpen = () => {
    SetnegaiD(true);
  };
  const negaiDClose = () => {
    SetnegaiD(false);
  };
  //おふだ
  const ofudaDOpen = () => {
    SetofudaD(true);
  };
  const ofudaDClose = () => {
    SetofudaD(false);
  };

  const retryDClose = () => {
    SetretryD(false);
  };

  const classes = useStyles();
  const type = props.type;

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"left"}
    >
      <ScrollTop></ScrollTop>

      <Box sx={{ height: 10 }}></Box>

      {type == "安産祈願" && (
        <Card sx={{ width: "90%" }}>
          <CardContent>
            <Grid>
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                安産祈願
              </Typography>
              <Typography sx={{ mt: 1.1, fontSize: 15 }} variant="body2">
                ・安産祈願のお初穂料は1万円（おふだ・ご祈祷・記念品含む）となります。
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      )}

      <Box sx={{ height: 40 }}></Box>

      <Divider style={{ width: "100%" }} />
      <Box sx={{ height: 30 }}></Box>
      <Grid>
        <Grid container justify="flex-start" alignItems="flex-start">
          <CheckCircleOutlineIcon
            style={{ color: "#ff7043", fontSize: 30 }}
          ></CheckCircleOutlineIcon>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }} ml={1}>
            ご祈祷する人を選択してください
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ height: 15 }}></Box>
      <Typography sx={{ fontSize: 16 }}>家族でご参加できます</Typography>
      <Box sx={{ height: 15 }}></Box>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"left"}
        pl={3}
      >
        <form>
          {accounts != "" &&
            accounts.map((data, i) => (
              <Grid container key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      name={`apply[${i}]`}
                      onChange={(e) => handleCheck(e, i)}
                      style={{ paddingRight: "10px" }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                      {accounts[i]["name"] + " 様"}
                    </Typography>
                  }
                />
                <Box sx={{ height: 40 }}></Box>
              </Grid>
            ))}
          <Box sx={{ height: 40 }}></Box>
          <Grid>
            <Controller
              {...register(`biko`)}
              control={control}
              ref={null}
              render={({ field }) => (
                <div>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                    mb={1}
                  >
                    備考
                  </Typography>
                  <TextField
                    multiline
                    label="備考"
                    rows={4}
                    defaultValue=""
                    placeholder="何か神社に伝えておきたいことがあればご記入ください"
                    InputProps={{ fontSize: 16 }}
                    fullWidth
                    sx={{ width: 250 }}
                    onChange={(e) => {
                      console.log("biko onchange");
                      setValue(`biko`, e.target.value);
                    }}
                  ></TextField>
                </div>
              )}
            />
          </Grid>
        </form>
      </Grid>

      <Box sx={{ height: 40 }}></Box>

      <Divider style={{ width: "100%" }} />

      <Box sx={{ height: 50 }}></Box>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"center"}
      >
        <Button
          variant="contained"
          sx={{ width: "60%", height: 40 }}
          onClick={() => onSubmit("next")}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            日時の選択へ
          </Typography>
        </Button>
        <Box sx={{ height: 20 }}></Box>

        <Button
          variant="contained"
          sx={{
            width: "60%",
            height: 40,
            backgroundColor: "#ece9e9f2",
            color: "black",
          }}
          onClick={() => goHome()}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            もどる
          </Typography>
        </Button>
      </Grid>
      <Box sx={{ height: 150 }}></Box>

      <Dialog fullScreen onClose={negaiDClose} open={NegaiD}>
        <Negai handleCloseUpdate={() => negaiDClose()} />
      </Dialog>

      <Dialog fullScreen onClose={ofudaDClose} open={OfudaD}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"left"}
        >
          <Ofuda handleCloseUpdate={() => ofudaDClose()} />
        </Grid>
      </Dialog>

      <Dialog onClose={retryDClose} open={retryD}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"left"}
          p={3}
        >
          <DialogTitle>
            {" "}
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              選択されていません
            </Typography>
          </DialogTitle>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              color="inherit"
              sx={{
                width: "60%",
                height: 40,
                backgroundColor: "#ece9e9f2",
                color: "black",
              }}
              onClick={() => retryDClose()}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                もどる
              </Typography>
            </Button>
            <Box sx={{ height: 20 }}></Box>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default Other_1;
