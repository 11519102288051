import { createContext, useState, useContext, useEffect } from "react";
import { auth } from "../firebase";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Lottie from "lottie-react";
import animationData from "../movies/loading.json";
import Box from "@mui/material/Box";

const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  const value = {
    user,
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const style = {
    height: 120,
    width: 120,
  };

  //マウント時にサインイン・アウトを監視
  useEffect(() => {
    const unsubscribed = auth.onAuthStateChanged((user) => {
      // console.log(user);
      setUser(user);
      setLoading(false);
    });
    return () => {
      unsubscribed();
    };
  }, []);

  if (loading) {
    return (
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Box height={600}></Box>
        {/* <Lottie animationData={animationData} loop={true} style={style} /> */}
      </Grid>
    );
  } else {
    return (
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
    );
  }
}
