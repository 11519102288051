import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import pic3 from "../../images/three.jpg";
import pic5 from "../../images/five.jpg";
import pic8 from "../../images/eight.jpg";
import pic1 from "../../images/one.jpg";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import Negai from "../Sub/Negai.js";
import Ofuda from "../Sub/Ofuda.js";
import { useNavigate, Navigate, Link } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { string } from "yup";
import { OtherApplyData } from "./BaseOfYakubarai";
import ScrollTop from "../../helper/ScrollTop";
const Yakubarai_1 = (props) => {
  const { currentState, setCurrentState } = useContext(OtherApplyData);
  const defaultCheckedValues = ["B"];
  const { register, control, handleSubmit, getValues, setValues, setValue } =
    useForm({ defaultValues: { num: "1", biko: "" } });
  const { fields, append, remove, replace, insert, update } = useFieldArray({
    name: "apply",
    control,
  });
  let selected_index_arr = [];
  const [num, setNum] = useState("");
  const [select_type, setSelect_type] = useState("種目1");
  const [select_money, setselect_money] = useState("五千円（普通）");
  const [data, setData] = useState([""]);
  const [NegaiD, SetnegaiD] = useState(false);
  const [OfudaD, SetofudaD] = useState(false);
  const [retryD, SetretryD] = useState(false);
  const [type, setInputPray1] = useState("厄ばらい");
  const [detail, setDetail] = useState("本厄");

  const [firsttime, setFirsttime] = useState(true);
  const [yakuD, setYakuD] = useState(false);

  const accounts = props.accounts;

  const menuItems = [
    { label: "厄ばらい", value: "厄ばらい" },
    { label: "方位除け", value: "方位除け" },
    { label: "年祝い", value: "年祝い" },
  ];
  const menuItem2 = [
    { label: "選択しない", value: "選択しない" },
    { label: "前厄", value: "前厄" },
    { label: "本厄", value: "本厄" },
    { label: "後厄", value: "後厄" },
    { label: "病門", value: "病門" },
    { label: "鬼門", value: "鬼門" },
    { label: "八方塞", value: "八方塞" },
    { label: "還暦：61歳", value: "還暦：61歳" },
    { label: "古稀（こき）：70歳", value: "古稀（こき）：70歳" },
    { label: "喜寿（きじゅ）：77歳", value: "喜寿（きじゅ）：77歳" },
    { label: "傘寿（さんじゅ）：80歳", value: "傘寿（さんじゅ）：80歳" },
    { label: "米寿（べいじゅ）：88歳", value: "米寿（べいじゅ）：88歳" },
    { label: "卒寿（そつじゅ）：90歳", value: "卒寿（そつじゅ）：90歳" },
    { label: "白寿（はくじゅ）：99歳", value: "白寿（はくじゅ）：99歳" },
  ];

  const money_menu = [
    {
      label: "五千円（普通）",
      value: "五千円（普通）",
    },
    {
      label: "五千円（小）",
      value: "五千円（小）",
    },
    {
      label: "壱万円（普通）",
      value: "壱万円（普通）",
    },
    {
      label: "壱万円（小）",
      value: "壱万円（小）",
    },
  ];

  const isYaku = (year, sex) => {
    let yaku = "";
    //八方塞
    switch (year) {
      case "1925年":
        yaku = "八方塞";
        break;
      case "1934年":
        yaku = "八方塞";
        break;

      case "1943年":
        yaku = "八方塞";
        break;

      case "1952年":
        yaku = "八方塞";
        break;

      case "1961年":
        yaku = "八方塞";
        break;
      case "1970年":
        yaku = "八方塞";
        break;
      case "1979年":
        yaku = "八方塞";
        break;
      case "1988年":
        yaku = "八方塞";
        break;
      case "1997年":
        yaku = "八方塞";
        break;
      case "2006年":
        yaku = "八方塞";
        break;
      case "2015年":
        yaku = "八方塞";
        break;
      case "2024年":
        yaku = "八方塞";
        break;
      default:
        break;
    }
    //鬼門
    switch (year) {
      case "1922年":
        yaku = "鬼門";
        break;
      case "1931年":
        yaku = "鬼門";
        break;
      case "1940年":
        yaku = "鬼門";
        break;
      case "1949年":
        yaku = "鬼門";
        break;
      case "1958年":
        yaku = "鬼門";
        break;
      case "1967年":
        yaku = "鬼門";
        break;
      case "1976年":
        yaku = "鬼門";
        break;
      case "1985年":
        yaku = "鬼門";
        break;
      case "1994年":
        yaku = "鬼門";
        break;
      case "2003年":
        yaku = "鬼門";
        break;
      case "2012年":
        yaku = "鬼門";
        break;
      case "2021年":
        yaku = "鬼門";
        break;
      default:
        break;
    }
    //病門
    switch (year) {
      case "1919年":
        yaku = "病門";
        break;
      case "1928年":
        yaku = "病門";
        break;
      case "1937年":
        yaku = "病門";
        break;
      case "1946年":
        yaku = "病門";
        break;
      case "1955年":
        yaku = "病門";
        break;
      case "1964年":
        yaku = "病門";
        break;
      case "1973年":
        yaku = "病門";
        break;
      case "1982年":
        yaku = "病門";
        break;
      case "1991年":
        yaku = "病門";
        break;
      case "2000年":
        yaku = "病門";
        break;
      case "2009年":
        yaku = "病門";
        break;
      case "2018年":
        yaku = "病門";
        break;

      default:
        break;
    }
    //男性の厄年
    if (sex === "男性") {
      switch (year) {
        case "1923年":
          break;
        case "1924年":
          break;
        case "1925年":
          break;
        case "1926年":
          break;
        case "1927年":
          break;
        case "1928年":
          break;
        case "1929年":
          break;
        case "1930年":
          break;
        case "1931年":
          break;
        case "1932年":
          break;
        case "1933年":
          break;
        case "1934年":
          break;
        case "1935年":
          break;
        case "1936年":
          break;
        case "1937年":
          break;
        case "1938年":
          break;
        case "1939年":
          break;
        case "1940年":
          break;
        case "1941年":
          break;
        case "1942年":
          break;
        case "1943年":
          break;
        case "1944年":
          break;
        case "1945年":
          break;
        case "1946年":
          break;
        case "1947年":
          break;
        case "1948年":
          break;
        case "1949年":
          break;
        case "1950年":
          break;
        case "1951年":
          break;
        case "1952年":
          break;
        case "1953年":
          break;
        case "1954年":
          break;
        case "1955年":
          break;
        case "1956年":
          break;
        case "1957年":
          break;
        case "1958年":
          break;
        case "1959年":
          break;
        case "1960年":
          break;
        case "1961年":
          break;
        case "1962年":
          break;
        case "1963年":
          yaku = "後厄";
          break;
        case "1964年":
          yaku = "本厄";
          break;
        case "1965年":
          yaku = "前厄";
          break;
        case "1966年":
          break;
        case "1967年":
          break;
        case "1968年":
          break;
        case "1969年":
          break;
        case "1970年":
          break;
        case "1971年":
          break;
        case "1972年":
          break;
        case "1973年":
          break;
        case "1974年":
          break;
        case "1975年":
          break;
        case "1976年":
          break;
        case "1977年":
          break;
        case "1978年":
          break;
        case "1979年":
          break;
        case "1980年":
          break;
        case "1981年":
          break;
        case "1982年":
          yaku = "後厄";
          break;
        case "1983年":
          yaku = "本厄";
          break;
        case "1984年":
          yaku = "前厄";
          break;
        case "1985年":
          break;
        case "1986年":
          break;
        case "1987年":
          break;
        case "1988年":
          break;
        case "1989年":
          break;
        case "1990年":
          break;
        case "1991年":
          break;
        case "1992年":
          break;
        case "1993年":
          break;
        case "1994年":
          break;
        case "1995年":
          break;
        case "1996年":
          break;
        case "1997年":
          break;
        case "1998年":
          break;
        case "1999年":
          yaku = "後厄";
          break;
        case "2000年":
          yaku = "本厄";
          break;
        case "2001年":
          yaku = "前厄";
          break;
        case "2002年":
          break;
        case "2003年":
          break;
        case "2004年":
          break;
        case "2005年":
          break;
        case "2006年":
          break;
        case "2007年":
          break;
        case "2008年":
          break;
        case "2009年":
          break;
        case "2010年":
          break;
        case "2011年":
          break;
        case "2012年":
          break;
        case "2013年":
          break;
        case "2014年":
          break;
        case "2015年":
          break;
        case "2016年":
          break;
        case "2017年":
          break;
        case "2018年":
          break;
        case "2019年":
          break;
        case "2020年":
          break;
        case "2021年":
          break;
        case "2022年":
          break;
        case "2023年":
          break;

        default:
          break;
      }
    } else {
      //女性の厄年
      switch (year) {
        case "1923年":
          break;
        case "1924年":
          break;
        case "1925年":
          break;
        case "1926年":
          break;
        case "1927年":
          break;
        case "1928年":
          break;
        case "1929年":
          break;
        case "1930年":
          break;
        case "1931年":
          break;
        case "1932年":
          break;
        case "1933年":
          break;
        case "1934年":
          break;
        case "1935年":
          break;
        case "1936年":
          break;
        case "1937年":
          break;
        case "1938年":
          break;
        case "1939年":
          break;
        case "1940年":
          break;
        case "1941年":
          break;
        case "1942年":
          break;
        case "1943年":
          break;
        case "1944年":
          break;
        case "1945年":
          break;
        case "1946年":
          break;
        case "1947年":
          break;
        case "1948年":
          break;
        case "1949年":
          break;
        case "1950年":
          break;
        case "1951年":
          break;
        case "1952年":
          break;
        case "1953年":
          break;
        case "1954年":
          break;
        case "1955年":
          break;
        case "1956年":
          break;
        case "1957年":
          break;
        case "1958年":
          break;
        case "1959年":
          break;
        case "1960年":
          break;
        case "1961年":
          break;
        case "1962年":
          break;
        case "1963年":
          yaku = "後厄";
          break;
        case "1964年":
          yaku = "本厄";
          break;
        case "1965年":
          yaku = "前厄";
          break;
        case "1966年":
          break;
        case "1967年":
          break;
        case "1968年":
          break;
        case "1969年":
          break;
        case "1970年":
          break;
        case "1971年":
          break;
        case "1972年":
          break;
        case "1973年":
          break;
        case "1974年":
          break;
        case "1975年":
          break;
        case "1976年":
          break;
        case "1977年":
          break;
        case "1978年":
          break;
        case "1979年":
          break;
        case "1980年":
          break;
        case "1981年":
          break;
        case "1982年":
          break;
        case "1983年":
          break;
        case "1984年":
          break;
        case "1985年":
          break;
        case "1986年":
          break;
        case "1987年":
          yaku = "後厄";
          break;
        case "1988年":
          yaku = "本厄";
          break;
        case "1989年":
          yaku = "前厄";
          break;
        case "1990年":
          yaku = "後厄";
          break;
        case "1991年":
          yaku = "本厄";
          break;
        case "1992年":
          yaku = "前厄";
          break;
        case "1993年":
          break;
        case "1994年":
          break;
        case "1995年":
          break;
        case "1996年":
          break;
        case "1997年":
          break;
        case "1998年":
          break;
        case "1999年":
          break;
        case "2000年":
          break;
        case "2001年":
          break;
        case "2002年":
          break;
        case "2003年":
          break;
        case "2004年":
          break;
        case "2005年":
          yaku = "後厄";
          break;
        case "2006年":
          yaku = "本厄";
          break;
        case "2007年":
          yaku = "前厄";
          break;
        case "2008年":
          break;
        case "2009年":
          break;
        case "2010年":
          break;
        case "2011年":
          break;
        case "2012年":
          break;
        case "2013年":
          break;
        case "2014年":
          break;
        case "2015年":
          break;
        case "2016年":
          break;
        case "2017年":
          break;
        case "2018年":
          break;
        case "2019年":
          break;
        case "2020年":
          break;
        case "2021年":
          break;
        case "2022年":
          break;
        case "2023年":
          break;

        default:
          break;
      }
    }
    //年祝い
    switch (year) {
      case "1926年":
        yaku = "白寿";
        break;
      case "1935年":
        yaku = "卒寿";
        break;
      case "1937年":
        yaku = "米寿";
        break;
      case "1945年":
        yaku = "傘寿";
        break;
      case "1948年":
        yaku = "喜寿";
        break;
      case "1955年":
        yaku = "古稀";
        break;
      case "1964年":
        yaku = "還暦";
        break;
      default:
        break;
    }

    return yaku;
  };

  useEffect(() => {}, [type, select_money]);

  let checked = false;

  const navigate = useNavigate();

  useLayoutEffect(() => {
    setNum(props.accounts.length);
    const values = [];
    const form_data = getValues();
    console.log("field value -> " + JSON.stringify(form_data));
    for (let i = 0; i < props.accounts.length; i++) {
      const value = { selected: false, index: i };
      values.push(value);
    }
    setData(values);
  }, []);

  console.log("--------------Rendered--------------------");
  const value = getValues();
  console.log("value: " + JSON.stringify(value));

  useEffect(() => {
    if (props.accounts == "") {
      navigate("/");
    }
  }, []);

  const handleCheck = (e, index) => {
    console.log("index: " + index);
    data[index]["selected"] = e.target.checked;

    //空辞書を削除する
    const unformated_data = getValues();
    console.log("unformated_data: " + JSON.stringify(unformated_data));
    const formated_data = deleteEnpty(unformated_data.apply);
    replace(formated_data);
    console.log("format_data: " + JSON.stringify(formated_data));

    //選択した時
    if (e.target.checked) {
      checked = true;
      const before_data = getValues();
      console.log("before_data: " + JSON.stringify(before_data));

      if (index === 0) {
        insert(index, {
          index: index,
          type: type,
          detail: detail,
          money: select_money,
        });
      } else {
        insert(index, {
          index: index,
          type: type,
          detail: detail,
          money: select_money,
        });
      }

      console.log("追加しました");
    } else {
      //非選択の時
      const before_data = getValues();
      checked = false;

      if (index === 0) {
        remove(0);
      } else {
        //indexで検索して、その辞書を削除
        let remove_index;
        //replaceで置き換え
        for (let i = 0; i < before_data.apply.length; i++) {
          if (index === before_data.apply[i].index) {
            console.log("index hit!");
            remove_index = i;
          }
        }
        before_data.apply.splice(remove_index, 1);
        replace(before_data.apply);
      }
      console.log("削除しました");
      const after_data = getValues();
    }
  };

  const deleteEnpty = (dic) => {
    let format_data = [];
    //空の配列を削除する
    if (dic != null && dic != undefined) {
      for (let i = 0; i < dic.length; i++) {
        if (typeof dic[i] === "object" && dic[i] !== null) {
          if (Object.keys(dic[i]).length === 4) {
            format_data.push(dic[i]);
          } else {
          }
        }
      }
    }

    console.log("format_data: " + format_data);

    return format_data;
  };

  const onSubmit = (action) => {
    //formデータとアカウント情報を紐付ける
    console.log("selected_index:  " + selected_index_arr);
    const deta = getValues();
    const form_data = deleteEnpty(deta.apply);
    console.log("onSubmit deta: " + JSON.stringify(form_data));
    //もし選択してなかったら
    if (form_data.length > 0) {
      console.log("ある");
    } else {
      console.log("ない");
      console.log(checked);
      SetretryD(true);
      return;
    }

    const selected_index = [];

    //選択したアカウント分、紐付ける
    console.log("form_data.apply.length: " + form_data.length);
    for (let i = 0; i < form_data.length; i++) {
      selected_index.push(form_data[i].index);
    }

    console.log("selected index: " + selected_index);

    //currentStateに登録する情報
    let state_data;

    for (let i = 0; i < selected_index.length; i++) {
      //選択したアカウントの基本情報
      const account_data = [accounts[selected_index[i]]];
      console.log("account_data->" + JSON.stringify(account_data));
      account_data.push(form_data[i]);
      console.log("form_data: " + JSON.stringify(form_data[i]));
      if (i === 0) {
        state_data = [account_data];
      } else {
        state_data.push(account_data);
      }
    }

    console.log("statedata:" + JSON.stringify(state_data));
    let biko_text = "";
    if (deta.biko !== "") {
      biko_text = deta.biko;
    }

    setCurrentState({
      ...currentState,
      num: num,
      biko: biko_text,
      EasyApply1: state_data,
    });
    console.log("currentState => " + JSON.stringify(currentState));

    if (action === "back") {
      props.goHome();
    } else if (action === "next") {
      props.handleNext();
    }
  };

  const goHome = () => {
    navigate("/kigan");
  };

  const useStyles = makeStyles({
    startIcon: {
      position: "absolute",
      left: "1rem",
    },
    fab: {
      position: "fixed",
      margin: 0,
      bottom: "auto",
      right: 20,
      top: 20,
    },
  });

  //説明用
  //願いの項目
  const negaiDOpen = () => {
    SetnegaiD(true);
  };
  const negaiDClose = () => {
    SetnegaiD(false);
  };
  //おふだ
  const ofudaDOpen = () => {
    SetofudaD(true);
  };
  const ofudaDClose = () => {
    SetofudaD(false);
  };

  const retryDClose = () => {
    SetretryD(false);
  };

  const yakuDClose = () => {
    setYakuD(false);
  };
  const yakuDOpen = () => {
    setYakuD(true);
  };

  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"left"}
    >
      <Box sx={{ height: 10 }}></Box>
      <ScrollTop></ScrollTop>
      <Card sx={{ width: "90%" }}>
        <CardContent>
          <Grid>
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              厄ばらい・方位除け・年祝いのご祈祷
            </Typography>
            <Typography sx={{ mt: 1.1, fontSize: 15 }} variant="body2">
              ・厄や方位による災いがないよう祈願します
            </Typography>
            <Typography sx={{ mt: 1.1, fontSize: 15 }} variant="body2">
              ・お初穂料は五千円から（おふだ・ご祈祷・記念品含む）になります。
            </Typography>
            <Box sx={{ height: 10 }}></Box>
          </Grid>
        </CardContent>
      </Card>

      <Box sx={{ height: 40 }}></Box>

      <Divider style={{ width: "100%" }} />
      <Box sx={{ height: 30 }}></Box>
      <Grid>
        <Grid container justify="flex-start" alignItems="flex-start">
          <CheckCircleOutlineIcon
            style={{ color: "#ff7043", fontSize: 30 }}
          ></CheckCircleOutlineIcon>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }} ml={1}>
            ご祈祷する人を選択してください
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ height: 15 }}></Box>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"left"}
        pl={3}
      >
        <form>
          {accounts != "" &&
            accounts.map((data, i) => (
              <Grid container key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      name={`apply[${i}]`}
                      onChange={(e) => handleCheck(e, i)}
                      style={{ paddingRight: "10px" }}
                    />
                  }
                  label={
                    <Grid container justify="flex-start" alignItems="center">
                      <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                        {accounts[i]["name"] + " 様"}
                      </Typography>
                      {isYaku(accounts[i]["birthday"], accounts[i]["sex"]) !==
                        "" && (
                        <Grid>
                          <Typography
                            sx={{
                              fontSize: 16,
                              backgroundColor: "#f7ad6c",
                              fontWeight: "bold",
                            }}
                            omponent="div"
                            ml={1}
                          >
                            {"「" +
                              isYaku(
                                accounts[i]["birthday"],
                                accounts[i]["sex"]
                              ) +
                              "」の年　"}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  }
                />
                <Box sx={{ height: 40 }}></Box>
              </Grid>
            ))}
          <Box sx={{ height: 40 }}></Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            {accounts != "" &&
              accounts.map(
                (value, i) =>
                  //選択されていた時のみ表示させたい
                  data[0] !== "" &&
                  data[i]["selected"] == true && (
                    <Box key={i}>
                      <Grid>
                        <Box sx={{ backgroundColor: "#f7ad6c" }} p={1} mb={2}>
                          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                            {value["name"]}様
                          </Typography>
                        </Box>

                        <Typography sx={{ fontSize: 18 }}>種類</Typography>
                        <Grid>
                          <Controller
                            {...register(`apply.${i}.type`)}
                            control={control}
                            ref={null}
                            render={({ field }) => (
                              <div>
                                <TextField
                                  select
                                  defaultValue="厄ばらい"
                                  fullWidth
                                  sx={{ width: 250 }}
                                  onChange={(e) => {
                                    const unformated_data = getValues();
                                    console.log(
                                      "unformated_data: " +
                                        JSON.stringify(unformated_data)
                                    );
                                    const formated_data = deleteEnpty(
                                      unformated_data.apply
                                    );
                                    replace(formated_data);
                                    console.log(
                                      "format_data: " +
                                        JSON.stringify(formated_data)
                                    );
                                    setInputPray1(e.target.value);
                                    console.log("i: " + i);
                                    const before_data = getValues();

                                    checked = false;

                                    //indexで検索して、その辞書を更新したい
                                    let update_index;
                                    //replaceで置き換え
                                    for (
                                      let n = 0;
                                      n < before_data.apply.length;
                                      n++
                                    ) {
                                      if (i === before_data.apply[n].index) {
                                        console.log(
                                          "index hit!" + n + " 番目を更新"
                                        );
                                        update_index = n;
                                      }
                                    }

                                    update(update_index, {
                                      index: i,
                                      type: e.target.value,
                                      detail: detail,
                                      money: select_money,
                                    });

                                    setFirsttime(false);
                                    const v = getValues();
                                    console.log("v: " + JSON.stringify(v));
                                  }}
                                >
                                  {menuItems.map(({ label, value }, index) => (
                                    <MenuItem key={index} value={value}>
                                      <Typography
                                        sx={{
                                          fontSize: 16,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {" "}
                                        {label}
                                      </Typography>
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                            )}
                          />
                        </Grid>
                        <Box sx={{ height: 30 }}></Box>
                        <Typography sx={{ fontSize: 18 }}>詳細</Typography>
                        <Grid>
                          <Controller
                            {...register(`apply.${i}.detail`)}
                            control={control}
                            ref={null}
                            render={({ field }) => (
                              <div>
                                <TextField
                                  select
                                  defaultValue="本厄"
                                  fullWidth
                                  sx={{ width: 250 }}
                                  onChange={(e) => {
                                    const unformated_data = getValues();
                                    const formated_data = deleteEnpty(
                                      unformated_data.apply
                                    );
                                    replace(formated_data);
                                    setDetail(e.target.value);
                                    const before_data = getValues();

                                    checked = false;

                                    //indexで検索して、その辞書を更新したい
                                    let update_index;
                                    //replaceで置き換え
                                    for (
                                      let n = 0;
                                      n < before_data.apply.length;
                                      n++
                                    ) {
                                      if (i === before_data.apply[n].index) {
                                        console.log(
                                          "index hit!" + n + " 番目を更新"
                                        );
                                        update_index = n;
                                      }
                                    }

                                    update(update_index, {
                                      index: i,
                                      type: type,
                                      detail: e.target.value,
                                      money: select_money,
                                    });

                                    setFirsttime(false);
                                    const v = getValues();
                                    console.log("v: " + JSON.stringify(v));
                                  }}
                                >
                                  {menuItem2.map(({ label, value }, index) => (
                                    <MenuItem key={index} value={value}>
                                      <Typography
                                        sx={{
                                          fontSize: 16,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {label}
                                      </Typography>
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                            )}
                          />
                        </Grid>
                        <Box sx={{ height: 30 }}></Box>
                        <Typography sx={{ fontSize: 18 }}>
                          おふだの大きさ
                        </Typography>
                        <Grid>
                          <Controller
                            {...register(`apply.${i}.money`)}
                            control={control}
                            ref={null}
                            render={({ field }) => (
                              <div>
                                <TextField
                                  select
                                  defaultValue="五千円（普通）"
                                  fullWidth
                                  sx={{ width: 250 }}
                                  onChange={(e) => {
                                    setValue(
                                      `apply.${i}.money`,
                                      e.target.value
                                    );
                                    setselect_money(e.target.value);

                                    const unformated_data = getValues();
                                    const formated_data = deleteEnpty(
                                      unformated_data.apply
                                    );
                                    replace(formated_data);
                                    console.log("i: " + i);
                                    const before_data = getValues();
                                    checked = false;

                                    //indexで検索して、その辞書を更新したい
                                    let update_index;
                                    //replaceで置き換え
                                    for (
                                      let n = 0;
                                      n < before_data.apply.length;
                                      n++
                                    ) {
                                      if (i === before_data.apply[n].index) {
                                        console.log(
                                          "index hit!" + n + " 番目を更新"
                                        );
                                        update_index = n;
                                      }
                                    }

                                    update(update_index, {
                                      index: i,
                                      type: type,
                                      detail: detail,
                                      money: e.target.value,
                                    });
                                    setFirsttime(false);
                                    const v = getValues();
                                    console.log("v: " + JSON.stringify(v));
                                  }}
                                >
                                  {money_menu.map(({ label, value }, index) => (
                                    <MenuItem key={index} value={value}>
                                      <Typography
                                        sx={{
                                          fontSize: 16,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {label}
                                      </Typography>
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                            )}
                          />
                        </Grid>
                        <Box sx={{ height: 40 }}></Box>
                      </Grid>
                    </Box>
                  )
              )}
          </form>
          <Grid>
            <Controller
              {...register(`biko`)}
              control={control}
              ref={null}
              render={({ field }) => (
                <div>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                    mb={1}
                  >
                    備考
                  </Typography>
                  <TextField
                    multiline
                    rows={4}
                    defaultValue=""
                    placeholder="何か神社に伝えておきたいことがあればご記入ください"
                    InputProps={{ fontSize: 16 }}
                    fullWidth
                    sx={{ width: 250 }}
                    onChange={(e) => {
                      console.log("biko onchange");
                      setValue(`biko`, e.target.value);
                    }}
                  ></TextField>
                </div>
              )}
            />
          </Grid>
        </form>
      </Grid>

      <Box sx={{ height: 40 }}></Box>

      <Divider style={{ width: "100%" }} />

      <Box sx={{ height: 20 }}></Box>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"center"}
      >
        <Button
          sx={{ width: "60%", height: 40 }}
          variant="contained"
          onClick={() => onSubmit("next")}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            日時の選択へ
          </Typography>
        </Button>
        <Box sx={{ height: 20 }}></Box>

        <Button
          sx={{
            width: "60%",
            height: 40,
            backgroundColor: "#ece9e9f2",
            color: "black",
          }}
          variant="contained"
          onClick={() => goHome()}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            もどる
          </Typography>
        </Button>
      </Grid>
      <Box sx={{ height: 150 }}></Box>

      <Dialog fullScreen onClose={negaiDClose} open={NegaiD}>
        <Negai handleCloseUpdate={() => negaiDClose()} />
      </Dialog>

      <Dialog fullScreen onClose={ofudaDClose} open={OfudaD}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"left"}
        >
          <Ofuda handleCloseUpdate={() => ofudaDClose()} />
        </Grid>
      </Dialog>

      <Dialog onClose={retryDClose} open={retryD}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"left"}
          p={3}
        >
          <DialogTitle>
            {" "}
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              選択されていません
            </Typography>
          </DialogTitle>
          <Box sx={{ height: 20 }}></Box>

          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              color="inherit"
              sx={{
                width: "60%",
                height: 40,
                backgroundColor: "#ece9e9f2",
                color: "black",
              }}
              onClick={() => retryDClose()}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                もどる
              </Typography>
            </Button>
            <Box sx={{ height: 20 }}></Box>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        fullScreen
        onClose={yakuDClose}
        open={yakuD}
        sx={{ width: "100%" }}
      >
        <Grid sx={{ height: "90%" }}>
          <Box sx={{ height: 50 }}></Box>

          <Typography sx={{ fontSize: 17 }} mr={2} ml={2} mb={1} mt={3}>
            厄年（やくどし）
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={1}>
            災いが多く降りかかる年齢とされています。
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={3}>
            厄年にあたる「本厄」、前1年間の前兆が現れるとされる「前厄」、厄の恐れが薄らいでいくとされる「後厄」があります。
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={3}>
            ご祈祷の種類：一般祈願/厄除け
          </Typography>

          <Divider sx={{ width: "100%" }}></Divider>

          <Typography sx={{ fontSize: 17 }} mr={2} ml={2} mb={1} mt={2}>
            方位
          </Typography>

          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={2}>
            土地や家、年回りなどから来る災いです。私たちは建築や引越、旅行などによって知らず知らずに方位を犯しつつ生活をしています。
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={2}>
            鬼門：北東の方角
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={2}>
            病門：南西の方角
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={3}>
            八方塞：すべての方角
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={3}>
            ご祈祷の種類：一般祈願/方位除け
          </Typography>
          <Divider sx={{ width: "100%" }}></Divider>
          <Typography sx={{ fontSize: 17 }} mr={2} ml={2} mb={1} mt={3}>
            年祝い
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={3}>
            人生で大事な節目の年に達したことを祝い、その後の人生の無病息災・健康長寿を祈願する年です。
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={2}>
            還暦（かんれき）：61歳
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={2}>
            古稀（こき）：70歳
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={3}>
            喜寿（きじゅ）：77歳
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={3}>
            傘寿（さんじゅ）：80歳
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={3}>
            米寿（べいじゅ）：88歳
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={3}>
            卒寿（そつじゅ）：90歳
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={3}>
            白寿（はくじゅ）：99歳
          </Typography>
          <Typography sx={{ fontSize: 13 }} mr={2} ml={2} mb={3}>
            ご祈祷の種類：一般祈願/年祝い
          </Typography>
          <Divider sx={{ width: "100%" }}></Divider>

          <Box sx={{ height: 50 }}></Box>
          <Box className={classes.fab}>
            <Fab
              aria-label="close"
              onClick={() => yakuDClose()}
              color="primary"
            >
              <CloseIcon></CloseIcon>
            </Fab>
          </Box>

          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button variant="contained" color="inherit" onClick={yakuDClose}>
              戻る
            </Button>
            <Box sx={{ height: 60 }}></Box>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default Yakubarai_1;
