import React, {
  useState,
  useContext,
  useLayoutEffect,
  useEffect,
  useRef,
} from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import o_oonusa from "../../images/o_oonusa.png";
import oonusa from "../../images/oonusa.png";
import torii from "../../images/torii.png";
import o_torii from "../../images/o_torii.png";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";

import iphone_safari1 from "../../images/iphone_safari1.png";
import iphone_safari2 from "../../images/iphone_safari2.png";
import iphone_safari3 from "../../images/iphone_safari3.png";
import android_chrome1 from "../../images/android_chrome1.png";
import android_chrome2 from "../../images/android_chrome2.png";
import android_chrome3 from "../../images/android_chrome3.png";

const MakePWA = () => {
  const [pwa, set_pwa] = useState(false);
  const [isiphone, set_isiphone] = useState(false);
  const [isandroid, set_isandroid] = useState(false);
  const [issafari, set_issafari] = useState(false);
  const [ischrome, set_ischrome] = useState(false);
  const [issamsungbrowser, set_issamsungbrowser] = useState(false);
  const [isedge, set_isedge] = useState(false);
  const [isfirefox, set_isfirefox] = useState(false);
  const [isopera, set_isopera] = useState(false);

  const [pwaD, set_pwaD] = useState(false);

  useEffect(() => {}, [pwa]);

  useEffect(() => {
    var userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.indexOf("iphone") != -1) {
      // console.log("iPhoneをお使いですね");
      set_isiphone(true);
    } else if (userAgent.indexOf("ipad") != -1) {
      // console.log("iPadをお使いですね");
    } else if (userAgent.indexOf("android") != -1) {
      if (userAgent.indexOf("mobile") != -1) {
        // console.log("androidのスマホをお使いですね");
        set_isandroid(true);
      } else {
        // console.log("androidのタブレットをお使いですね");
      }
    }

    if (userAgent.indexOf("msie") != -1 || userAgent.indexOf("trident") != -1) {
      // console.log("Internet Explorerをお使いですね");
    } else if (userAgent.indexOf("edge") != -1) {
      // console.log("Edgeをお使いですね");
      set_isedge(true);
    } else if (userAgent.indexOf("chrome") != -1) {
      // console.log("Google Chromeをお使いですね");
      set_ischrome(true);
    } else if (userAgent.indexOf("safari") != -1) {
      // console.log("Safariをお使いですね");
      set_issafari(true);
    } else if (userAgent.indexOf("firefox") != -1) {
      // console.log("FireFoxをお使いですね");
    } else if (userAgent.indexOf("opera") != -1) {
      // console.log("Operaをお使いですね");
    } else if (userAgent.indexOf("samsungbrowser") != -1) {
      // console.log("samsungbrowserをお使いですね");
      set_issamsungbrowser(true);
    } else {
      // console.log("そんなブラウザは知らん");
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("installed") === "true") {
      //ローカルストレージ：　インストール済みかどうか
      set_pwa(true);
      console.log("installed info at local storage");

      return;
    }
    window.addEventListener("appinstalled", (evt) => {
      console.log("app installed");
      localStorage.setItem("installed", "true");
      set_pwa(true);
    });
    if (window.matchMedia("(display-mode: standalone)").matches) {
      set_pwa(true);

      // console.log("display-mode is standalone");
    }
    if (window.matchMedia("(display-mode: fullscreen)").matches) {
      // console.log("display-mode is fullscreen");
    }

    getPWADisplayMode();
  }, []);

  const PWADialogHandler = (open) => {
    set_pwaD(open);
  };

  const openSafari = () => {
    window.open("https://ookunitama-negai.site", "_self");
  };

  //クリップボードにコピー関数
  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText("https://ookunitama-negai.site");
  };

  function getPWADisplayMode() {
    const isStandalone = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;
    if (document.referrer.startsWith("android-app://")) {
      // console.log("twa");
    } else if (navigator.standalone || isStandalone) {
      set_pwa(true);
      // console.log("standalone");
    } else {
      // console.log("browser");
    }
  }

  if (!pwa) {
    return (
      <Grid>
        <Grid
          container
          textAlign={"left"}
          alignItems="center"
          justifyContent="center"
        >
          <Card sx={{ width: "90%" }}>
            <CardContent
              sx={{
                "&:last-child": { pb: 2 },
                p: 1,
              }}
            >
              <Grid container alignItems="center" justifyContent="flex-start">
                <Typography
                  gutterBottom
                  component="div"
                  sx={{ fontSize: 15 }}
                  m={1}
                  mb={1.5}
                >
                  アプリをお好きな時に楽しむ
                </Typography>
              </Grid>

              <Grid container alignItems="center" justifyContent="center">
                <Button
                  sx={{ backgroundColor: "#ece9e9f2", height: 44 }}
                  // variant="contained"
                  onClick={() => PWADialogHandler(true)}
                >
                  <Typography sx={{ fontSize: 15, color: "black", mx: 2 }}>
                    ホーム画面に追加するには
                  </Typography>
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Dialog
          onClose={() => PWADialogHandler(false)}
          open={pwaD}
          sx={{ width: "100%" }}
        >
          <Grid
            sx={{ height: "90%" }}
            container
            alignItems={"center"}
            justifyContent={"flex-start"}
            textAlign={"left"}
            p={2}
          >
            <Box sx={{ height: 50 }}></Box>
            <Typography sx={{ fontSize: 17, fontWeight: "bold" }} mb={4} mt={3}>
              ホーム画面に追加する方法
            </Typography>
            {isiphone && issafari && (
              <React.Fragment>
                <Typography sx={{ fontSize: 15, fontWeight: "bold" }} mb={2}>
                  ① サイトURL隣の「共有」ボタンをタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={iphone_safari1}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>
                <Typography
                  sx={{ fontSize: 15, fontWeight: "bold" }}
                  mt={3}
                  mb={2}
                >
                  ② 「ホーム画面に追加」をタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={iphone_safari2}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>

                <Typography
                  sx={{ fontSize: 15, fontWeight: "bold" }}
                  mt={3}
                  mb={2}
                >
                  ② 「追加」をタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={iphone_safari3}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>
              </React.Fragment>
            )}

            {isiphone && ischrome && (
              <React.Fragment>
                <Typography sx={{ fontSize: 15, fontWeight: "bold" }} mb={1}>
                  「Safari(サファリ)」で開く必要があります。
                </Typography>
                <Typography sx={{ fontSize: 15, fontWeight: "bold" }} mb={1}>
                  リンクをコピーしてSafariで開いて下さい。
                </Typography>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  mb={2}
                >
                  <Button
                    sx={{
                      backgroundColor: "#ffa94d",
                      color: "white",
                      height: 40,
                      width: "80%",
                    }}
                    variant="contained"
                    onClick={() => copyToClipboard()}
                  >
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      リンクをコピーする
                    </Typography>
                  </Button>
                </Grid>
                <Typography sx={{ fontSize: 15, fontWeight: "bold" }} mb={2}>
                  ① Safariで開き、サイトURL隣の「共有」ボタンをタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={iphone_safari1}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>
                <Typography
                  sx={{ fontSize: 15, fontWeight: "bold" }}
                  mt={3}
                  mb={2}
                >
                  ② 「ホーム画面に追加」をタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={iphone_safari2}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>

                <Typography
                  sx={{ fontSize: 15, fontWeight: "bold" }}
                  mt={3}
                  mb={2}
                >
                  ② 「追加」をタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={iphone_safari3}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>
              </React.Fragment>
            )}

            {isandroid && ischrome && (
              <React.Fragment>
                <Typography sx={{ fontSize: 15, fontWeight: "bold" }} mb={2}>
                  ① 画面右上のメニューボタンをタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={android_chrome1}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>
                <Typography
                  sx={{ fontSize: 15, fontWeight: "bold" }}
                  mt={3}
                  mb={2}
                >
                  ② 「ホーム画面に追加」をタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={android_chrome2}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>

                <Typography
                  sx={{ fontSize: 15, fontWeight: "bold" }}
                  mt={3}
                  mb={2}
                >
                  ② 「追加」をタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={android_chrome3}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>
              </React.Fragment>
            )}

            {isandroid && isedge && (
              <React.Fragment>
                <Typography sx={{ fontSize: 15, fontWeight: "bold" }} mb={2}>
                  ① 画面右上のメニューボタンをタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={android_chrome1}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>
                <Typography
                  sx={{ fontSize: 15, fontWeight: "bold" }}
                  mt={3}
                  mb={2}
                >
                  ② 「ホーム画面に追加」をタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={android_chrome2}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>

                <Typography
                  sx={{ fontSize: 15, fontWeight: "bold" }}
                  mt={3}
                  mb={2}
                >
                  ② 「追加」をタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={android_chrome3}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>
              </React.Fragment>
            )}

            {isandroid && issamsungbrowser && (
              <React.Fragment>
                <Typography sx={{ fontSize: 15, fontWeight: "bold" }} mb={2}>
                  ① 画面右上のメニューボタンをタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={android_chrome1}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>
                <Typography
                  sx={{ fontSize: 15, fontWeight: "bold" }}
                  mt={3}
                  mb={2}
                >
                  ② 「ホーム画面に追加」をタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={android_chrome2}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>

                <Typography
                  sx={{ fontSize: 15, fontWeight: "bold" }}
                  mt={3}
                  mb={2}
                >
                  ② 「追加」をタップ
                </Typography>
                <Grid item>
                  <img
                    width="100%"
                    src={android_chrome3}
                    style={{ border: "1px solid black" }}
                  ></img>
                </Grid>
              </React.Fragment>
            )}

            <Box sx={{ height: 50 }}></Box>

            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              textAlign={"center"}
              mt={3}
            >
              <Button
                variant="contained"
                color="inherit"
                onClick={() => PWADialogHandler(false)}
                sx={{ width: "70%", height: 40 }}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  もどる
                </Typography>
              </Button>
              <Box sx={{ height: 60 }}></Box>
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
    );
  } else {
    return <Grid></Grid>;
  }
};

export default MakePWA;
