import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LogList from "../ Component/LogList.js";
import Achieve from "../../AchieveComponent/Achieve.js";
import InputDialy from "../ Component/InputDialy.js";
import Contact from "../../ Component/Sub/Contact.js";
import { Divider, TextField } from "@mui/material";
import { getAuth } from "firebase/auth";
import {
  collection,
  query,
  getDocs,
  addDoc,
  where,
  orderBy,
  limit,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Unsei from "../ Component/Unsei.js";
import Koyomi from "../ Component/Koyomi.js";
import ScrollTop from "../../helper/ScrollTop.js";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import Fab, { fabClasses } from "@mui/material/Fab";
import pic from "../../images/shrine.png";
import IconButton from "@mui/material/IconButton";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@mui/styles";
import TukinamiGohei from "../../ Component/Sub/TukinamiGohei.js";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useAuthContext } from "../../context/ AuthContext.js";
import Kamidana from "../../ Component/Sub/Kamidana.js";
import DenseAppbar from "../../ Component/DenseAppbar.js";
import Drawer from "@mui/material/Drawer";
import fox from "../../images/fox.png";
import shelf from "../../images/shelf.png";
import torii from "../../images/torii.png";
import oonusa from "../../images/oonusa.png";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import WelcomeCard from "../../ Component/Sub/WelcomeCard.js";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import tukinamigohei from "../../images/tukinamigohei.jpg";
import CardActions from "@mui/material/CardActions";
import looking_fox from "../../images/looking_fox.png";
import LineComponent from "../../ Component/Sub/LineComponent.js";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import BottomMenu from "../../ Component/Sub/BottomMenu.js";

export default function DialyHome(props) {
  const { user } = useAuthContext();

  const navigate = useNavigate();
  const [user_id, setUser_id] = useState("");
  const [document_id, setDocumentId] = useState("");
  const [dialy_deta, setDialy_deta] = useState(""); //日記全体のデータ
  const [kanaetai_deta, setKanaetaiDeta] = useState(""); //叶えたいことのデータ
  const [haraitai_deta, setHaraitaiDeta] = useState(""); //祓いたいことのデータ

  const [ids_deta, setIds_deta] = useState("");
  const [reload, setReload] = useState(false);
  const [alert, setAlert] = useState(false);
  const [record_or_not, Setrecord_or_not] = useState(true);
  const [lockD, SetLockD] = useState(false); //パスワード作るダイアログ
  const [pass, SetPass] = useState(""); //初回パスワード
  const [passagain, SetPassAgain] = useState(""); //確認パスワード
  const [completeD, SetCppleteD] = useState(false); //登録したことを表示するダイアログ
  const [first_pass, SetFirstPass] = useState("");
  const [dialypass, Setdialypass] = useState(""); //DBからクエリしたパスワード
  const [unlockD, SetUnlockD] = useState(false); //パスワードを入力してアンロックするダイアログ
  const [lock, SetLock] = useState(false); //ロックしているかどうか
  const [query_end, SetQueryEnd] = useState(false);
  const [inputD, setInputD] = useState(false); //日記を書くダイアログ
  const [loginD, setLoginD] = useState(false);
  const [loading, setLoading] = useState(true);

  const [magatama_count, setmagatama_Count] = useState(null); //マガタマの数

  const [tab_value, setTabValue] = React.useState(0); //タブの値

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
    // console.log(newValue);
  };

  useEffect(() => {
    if (
      document_id !== ""
      // magatama_count !== 0 &&
      // dialy_deta !== "" &&
      // kanaetai_deta !== "" &&
      // haraitai_deta !== ""
    ) {
      setLoading(false);
    }

    if (user == null) {
      setLoading(false);
    }
  }, [record_or_not, kanaetai_deta, haraitai_deta, magatama_count, dialy_deta]);

  useLayoutEffect(() => {
    // 👇️ set isMounted to true
    let isMounted = true;
    const get_baseinfo = async () => {
      //アカウント情報を取得
      try {
        const auth = getAuth();
        const current_uid = auth.currentUser.uid;
        if (isMounted) {
          setUser_id(current_uid);
        }
        const userRef = collection(db, "account_list");
        let docid = "";
        const q = query(userRef, where("uid", "==", current_uid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          docid = doc.id;
          if (isMounted) {
            setDocumentId(doc.id);
          }
        });
        get_dialyinfo(userRef, docid);
      } catch (e) {
        // console.log("error: " + e.message);
        setAlert(true);
      }
    };

    const get_dialyinfo = async (userRef, docid) => {
      const docRef = doc(db, "account_list", docid);
      const docSnap = await getDoc(docRef);
      const values = docSnap.data();

      if (typeof values["dango"] !== "undefined") {
        //マガタマの数
        if (isMounted) {
          setmagatama_Count(values["dango"]);
        }
      } else {
        if (isMounted) {
          setmagatama_Count(0);
        }
      }
      //日記を取得

      try {
        let index = 0;
        let dialy_values = [""];
        let dialy_ids = [""];
        const accountsRef = collection(db, "account_list", docid, "dialy");

        const dialy_query = query(
          accountsRef,
          orderBy("timestamp", "desc")
          // limit(2)
        );

        const accountsSnaps = await getDocs(dialy_query);
        let harau_deta = [];
        let kanaeru_deta = [];

        accountsSnaps.forEach((d) => {
          dialy_values[index] = d.data();
          dialy_ids[index] = d.id;

          //祓う叶えるカテゴリが登録されていたら
          if (Object.keys(dialy_values[index]).length === 7) {
            //もし祓いたいことだったら
            if (dialy_values[index]["harau"]) {
              harau_deta.push(dialy_values[index]);
            }
            if (dialy_values[index]["kanaeru"]) {
              //もし叶えたいことだったら
              kanaeru_deta.push(dialy_values[index]);
            }
          }

          index += 1;
        });

        if (isMounted) {
          setDialy_deta(dialy_values);
          if (harau_deta.length > 0) {
            setHaraitaiDeta(harau_deta);
          }
          if (kanaeru_deta.length > 0) {
            setKanaetaiDeta(kanaeru_deta);
          }
        }

        // console.log("harau: " + harau_deta);
        // console.log("kanaeru: " + kanaeru_deta);

        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const date = today.getDate();
        var dayOfWeek = today.getDay();
        var dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][
          dayOfWeek
        ];
        const today_text =
          year + "年" + month + "月" + date + "日" + "(" + dayOfWeekStr + ")";

        let record_changed = false;
        for (let i = 0; i < dialy_values.length; i++) {
          if (dialy_values[i]["day"] === today_text) {
            if (isMounted) {
              //既に今日記録していたら
              Setrecord_or_not(false);
              record_changed = true;
            } else {
            }
          }
        }
        if (!record_changed) {
          Setrecord_or_not(true);
        }
        if (isMounted) {
          setIds_deta(dialy_ids);
        }
      } catch (e) {
        // console.log("error: " + e.message);
      }

      try {
        const docRef = doc(db, "account_list", docid);
        const docSnap = await getDoc(docRef);
        const values = docSnap.data();
        const dialy_pass = values["dialypass"];
        if (isMounted) {
          Setdialypass(dialy_pass);
        }
        if (typeof dialy_pass === "string") {
          // console.log("dialy_pass: " + dialy_pass);
          if (isMounted) {
            SetLock(true);
          }
        } else {
          // console.log("dialy_passが設定されていません");
        }

        // console.log("ロックしています");
        if (isMounted) {
          SetQueryEnd(true);
        }
      } catch (e) {
        // console.log(e);
      }
    };

    get_baseinfo();

    return () => {
      // 👇️ when the component unmounts, set isMounted to false
      isMounted = false;
    };
  }, []);

  const useStyles = makeStyles((theme) => ({
    fab: {
      position: "fixed",
      margin: 0,
      bottom: 80,
      right: 25,
      top: "auto",
    },
    tabLabel: {
      fontSize: 16,
      fontWeight: "bold",
    },
    tabPanelRoot: {
      padding: 0,
    },
    container: {},
  }));
  const classes = useStyles();

  useEffect(() => {}, [
    pass,
    first_pass,
    passagain,
    document_id,
    completeD,
    dialypass,
  ]);

  const toggleLockD = (open) => {
    //パスワード設定ダイアログ
    SetLockD(open);
  };

  const toggleUnlockD = (open) => {
    //パスワード入力ダイアログ
    SetUnlockD(open);
  };

  const toggleInputD = (open) => {
    setInputD(open);
  };

  const goSignup = () => {
    navigate("/signup");
  };

  const loginDopen = () => {
    setLoginD(true);
  };
  const loginDclose = () => {
    setLoginD(false);
  };

  const get_baseinfo = async () => {
    //アカウント情報を取得
    try {
      const auth = getAuth();
      const current_uid = auth.currentUser.uid;

      setUser_id(current_uid);
      const userRef = collection(db, "account_list");
      let docid = "";
      const q = query(userRef, where("uid", "==", current_uid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        docid = doc.id;
        setDocumentId(doc.id);
      });
      get_dialyinfo(userRef, docid);
    } catch (e) {
      // console.log("error: " + e.message);
      setAlert(true);
    }
  };

  const get_dialyinfo = async (userRef, docid) => {
    const docRef = doc(db, "account_list", docid);
    const docSnap = await getDoc(docRef);
    const values = docSnap.data();

    if (typeof values["dango"] !== "undefined") {
      //マガタマの数
      setmagatama_Count(values["dango"]);
    }
    //日記を取得

    try {
      let index = 0;
      let dialy_values = [""];
      let dialy_ids = [""];
      const accountsRef = collection(db, "account_list", docid, "dialy");

      const dialy_query = query(
        accountsRef,
        orderBy("timestamp", "desc")
        // limit(2)
      );
      const accountsSnaps = await getDocs(dialy_query);
      let harau_deta = [];
      let kanaeru_deta = [];

      accountsSnaps.forEach((d) => {
        dialy_values[index] = d.data();
        dialy_ids[index] = d.id;
        //祓う叶えるカテゴリが登録されていたら
        if (Object.keys(dialy_values[index]).length === 7) {
          // console.log("祓い・叶えたいことがある");
          //もし祓いたいことだったら
          if (dialy_values[index]["harau"]) {
            harau_deta.push(dialy_values[index]);
          } else if (dialy_values[index]["kanaeru"]) {
            //もし叶えたいことだったら
            kanaeru_deta.push(dialy_values[index]);
            // console.log("kanaeru_deta:" + JSON.stringify(dialy_values[index]));
          }
        }
        index += 1;
      });

      setDialy_deta(dialy_values);
      // console.log("harau_deta:" + JSON.stringify(harau_deta));
      // console.log("kanaeru_deta:" + JSON.stringify(kanaeru_deta));

      setHaraitaiDeta("");
      setKanaetaiDeta("");

      if (harau_deta.length > 0) {
        setHaraitaiDeta(harau_deta);
      }
      if (kanaeru_deta.length > 0) {
        setKanaetaiDeta(kanaeru_deta);
      }

      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const date = today.getDate();
      var dayOfWeek = today.getDay();
      var dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek];
      const today_text =
        year + "年" + month + "月" + date + "日" + "(" + dayOfWeekStr + ")";
      let record_changed = false;

      for (let i = 0; i < dialy_values.length; i++) {
        // console.log(dialy_values[i]["day"]);

        if (dialy_values[i]["day"] === today_text) {
          //既に今日記録していたら

          Setrecord_or_not(false);
        } else {
        }
      }
      if (!record_changed) {
        Setrecord_or_not(true);
      }
      setIds_deta(dialy_ids);
    } catch (e) {
      // console.log("error: " + e.message);
    }

    try {
      const docRef = doc(db, "account_list", docid);
      const docSnap = await getDoc(docRef);
      const values = docSnap.data();
      const dialy_pass = values["dialypass"];
      Setdialypass(dialy_pass);
      if (typeof dialy_pass === "string") {
        // console.log("dialy_pass: " + dialy_pass);
        SetLock(true);
      } else {
        // console.log("dialy_passが設定されていません");
      }

      // console.log("ロックしています");
      SetQueryEnd(true);
    } catch (e) {
      // console.log(e);
    }
  };

  const CheckPass = () => {
    //初回パスワードをチェック
    // console.log("pass: " + pass);
    if (pass.length === 4 && parseInt(pass)) {
      // console.log("パスワードとして使えます");
      SetFirstPass(pass);
    } else {
      // console.log("パスワードとして使えません");
      // console.log(parseInt(pass));
    }
  };

  const CheckUnlockPass = () => {
    if (String(pass) == dialypass) {
      // console.log("パスワードが合っています");
      SetLock(false);
      SetUnlockD(false);
    } else {
      // console.log("パスワードが間違っています");
    }
  };

  const CheckAgainPass = () => {
    //確認用パスワードをチェック
    // console.log("againpass: " + passagain);
    if (passagain.length === 4 && parseInt(passagain)) {
      // console.log("パスワードとして使えます");
      //ここでDBに登録
      SetPassDB();
    } else {
      // console.log("パスワードとして使えません");
      // console.log(parseInt(pass));
    }
  };

  const ClearText = () => {
    //Stateをクリア
    SetFirstPass("");
    SetPassAgain("");
    SetPass("");
  };

  const SetPassDB = async () => {
    //DBにパスワードを登録
    const passRef = doc(db, "account_list", document_id);
    const deta = { dialypass: passagain };
    await updateDoc(passRef, deta);
    // console.log("パスワードを登録しました");
    SetCppleteD(true);
    get_baseinfo();
    ClearText();
  };

  const EasyLock = () => {
    ClearText();
    //既にパスワードを登録している人で、ロックをかけたい方
    if (typeof dialypass !== "undefined") {
      SetLock(true);
    } else {
      //パスワードを登録するダイアログを表示
      toggleLockD(true);
    }
  };

  const HandleDialyOpen = () => {
    if (user == null) {
      loginDopen();
    } else {
      toggleInputD(true);
    }
  };

  const CustomisedBottomNavigationAction = (props) => (
    <BottomNavigationAction sx={{ minWidth: "70px" }} {...props} />
  );

  const goGokigan = () => {
    navigate("/kigan");
  };
  if (!loading) {
    return (
      <Grid>
        <ScrollTop></ScrollTop>
        <DenseAppbar text="まめ日記" magatama={magatama_count}></DenseAppbar>
        <Box sx={{ height: 40 }}></Box>
        {user !== null && <LineComponent></LineComponent>}
        {user === null && <WelcomeCard></WelcomeCard>}
        <Box sx={{ height: 40 }}></Box>

        {dialy_deta !== "" && (
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"left"}
          >
            <Divider sx={{ width: "90%", mb: 2 }}></Divider>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Typography sx={{ ml: 3, fontSize: 18, fontWeight: "bold" }}>
                  まめ日記
                </Typography>
              </Grid>
              <Grid item xs={5.5} ml={0.5}>
                {!lock && (
                  <Button
                    onClick={() => EasyLock()}
                    sx={{ backgroundColor: "#ece9e9f2", width: "90%" }}
                    mr={0.5}
                  >
                    <Typography sx={{ color: "black", fontSize: 14 }}>
                      鍵をかける
                    </Typography>
                    <LockOpenOutlinedIcon
                      sx={{ color: "black" }}
                    ></LockOpenOutlinedIcon>
                  </Button>
                )}
                {lock && (
                  <Button
                    onClick={() => toggleUnlockD(true)}
                    sx={{ backgroundColor: "#ece9e9f2", width: "90%" }}
                    variant="contained"
                  >
                    <Typography sx={{ color: "black", fontSize: 14 }} mr={0.5}>
                      鍵をあける
                    </Typography>
                    <LockOutlinedIcon
                      sx={{ color: "black" }}
                    ></LockOutlinedIcon>
                  </Button>
                )}
              </Grid>
            </Grid>

            <Box sx={{ height: 30 }}></Box>

            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Grid container justifyContent="center" textAlign="center">
                  <Grid item xs={6}>
                    <Button
                      sx={{ color: tab_value === 0 ? "#773dff" : "black" }}
                      onClick={() => handleTabChange(0)}
                    >
                      <Typography className={classes.tabLabel}>
                        祓いたいこと
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      sx={{ color: tab_value === 1 ? "#ecd020" : "black" }}
                      onClick={() => handleTabChange(1)}
                    >
                      <Typography className={classes.tabLabel}>
                        叶えたいこと
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ height: 10 }}></Box>

              {tab_value === 0 && haraitai_deta == "" && (
                <React.Fragment>
                  <Box sx={{ height: 20 }}></Box>

                  <Typography component={"div"} sx={{ fontSize: 16 }} ml={3}>
                    まだ祓いたいことがないようです。
                  </Typography>
                </React.Fragment>
              )}
              {tab_value === 0 && haraitai_deta !== "" && (
                <React.Fragment>
                  <LogList
                    dialy_deta={haraitai_deta}
                    ids_deta={ids_deta}
                    document_id={document_id}
                    get_baseinfo={() => get_baseinfo()}
                    dialypass={dialypass}
                    toggleUnlockD={() => toggleUnlockD(true)}
                    lock={lock}
                    query_end={query_end}
                    record_or_not={record_or_not}
                  />
                  <Grid
                    container
                    textAlign={"left"}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={3} textAlign={"center"}>
                      <img src={looking_fox} width="100%" />
                      <Typography
                        sx={{ fontSize: 14, fontWeight: "bold" }}
                        m={0.5}
                        ml={1}
                      >
                        コン
                      </Typography>
                    </Grid>
                    <Grid item xs={8} ml={1}>
                      <Card sx={{ width: "100%" }}>
                        <CardContent sx={{ "&:last-child": { pb: 2 } }}>
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{ fontSize: 14, fontWeight: "bold" }}
                            m={0.5}
                            ml={1}
                          >
                            祓いたいことがたまったら、
                          </Typography>
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{ fontSize: 14, fontWeight: "bold" }}
                            m={0.5}
                            ml={1}
                          >
                            神主さんにご祈祷してもらうコン。
                          </Typography>
                          <Grid
                            container
                            textAlign={"left"}
                            alignItems="center"
                            justifyContent="center"
                            mt={2}
                          >
                            <Button
                              size="small"
                              onClick={() => goGokigan()}
                              sx={{
                                backgroundColor: "#ffa94d",
                                width: "80%",
                                height: 40,
                              }}
                              variant="contained"
                            >
                              <Typography
                                sx={{
                                  color: "white",
                                  fontWeight: "bold",
                                  fontSize: 16,
                                }}
                              >
                                ご祈祷をする
                              </Typography>
                            </Button>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              {tab_value === 1 && kanaetai_deta == "" && (
                <React.Fragment>
                  <Box sx={{ height: 20 }}></Box>
                  <Typography component={"div"} sx={{ fontSize: 16 }} ml={3}>
                    まだ叶えたいことがないようです。
                  </Typography>
                </React.Fragment>
              )}
              {tab_value === 1 && kanaetai_deta !== "" && (
                <React.Fragment>
                  <LogList
                    dialy_deta={kanaetai_deta}
                    ids_deta={ids_deta}
                    document_id={document_id}
                    get_baseinfo={() => get_baseinfo()}
                    dialypass={dialypass}
                    toggleUnlockD={() => toggleUnlockD(true)}
                    lock={lock}
                    query_end={query_end}
                    record_or_not={record_or_not}
                  />
                  <Grid
                    container
                    textAlign={"left"}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={3} textAlign={"center"}>
                      <img src={looking_fox} width="100%" />
                      <Typography
                        sx={{ fontSize: 14, fontWeight: "bold" }}
                        m={0.5}
                        ml={1}
                      >
                        コン
                      </Typography>
                    </Grid>
                    <Grid item xs={8} ml={1}>
                      <Card sx={{ width: "100%" }}>
                        <CardContent sx={{ "&:last-child": { pb: 2 } }}>
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{ fontSize: 14, fontWeight: "bold" }}
                            m={0.5}
                            ml={1}
                          >
                            叶えたいことがたまったら、
                          </Typography>
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{ fontSize: 14, fontWeight: "bold" }}
                            m={0.5}
                            ml={1}
                          >
                            神主さんにご祈祷してもらうコン。
                          </Typography>
                          <Grid
                            container
                            textAlign={"left"}
                            alignItems="center"
                            justifyContent="center"
                            mt={2}
                          >
                            <Button
                              size="small"
                              onClick={() => goGokigan()}
                              sx={{
                                backgroundColor: "#ffa94d",
                                width: "80%",
                                height: 40,
                              }}
                              variant="contained"
                            >
                              <Typography
                                sx={{
                                  color: "white",
                                  fontWeight: "bold",
                                  fontSize: 16,
                                }}
                              >
                                ご祈祷をする
                              </Typography>
                            </Button>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Box>

            <Box sx={{ height: "60px" }}></Box>
            <Divider sx={{ width: "90%", mb: 2 }}></Divider>

            <Box sx={{ width: "100%" }}>
              <Typography sx={{ fontSize: 18, fontWeight: "bold", ml: 3 }}>
                すべての日記
              </Typography>

              <LogList
                dialy_deta={dialy_deta}
                ids_deta={ids_deta}
                document_id={document_id}
                get_baseinfo={() => get_baseinfo()}
                dialypass={dialypass}
                toggleUnlockD={() => toggleUnlockD(true)}
                lock={lock}
                query_end={query_end}
                record_or_not={record_or_not}
              />
            </Box>
          </Grid>
        )}
        {user !== null && dialy_deta === "" && (
          <Box sx={{ height: "402px" }}></Box>
        )}

        {user == null && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            textAlign={"left"}
          >
            <Typography sx={{ ml: 3, fontSize: 18, fontWeight: "bold" }}>
              まめ日記
            </Typography>
            <Typography sx={{ ml: 3, mt: 5, fontSize: 16 }}>
              まめ日記はまだ書いていません
            </Typography>
          </Grid>
        )}

        <Button
          onClick={() => HandleDialyOpen()}
          variant="contained"
          sx={{
            backgroundColor: "#ffa94d",
            color: "white",
            borderRadius: "16px",
            height: 50,
          }}
          className={classes.fab}
        >
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }} mr={0.5}>
            まめ日記を書く
          </Typography>
          <CreateOutlinedIcon sx={{ fontSize: 23 }}></CreateOutlinedIcon>
        </Button>

        <Drawer
          onClose={() => toggleInputD(false)}
          open={inputD}
          anchor={"bottom"}
        >
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <InputDialy
              document_id={document_id}
              get_baseinfo={() => get_baseinfo()}
              alert={alert}
              record_or_not={record_or_not}
              CloseD={() => toggleInputD(false)}
            ></InputDialy>
          </Grid>
        </Drawer>

        <Box sx={{ height: 240 }}></Box>
        {/* <Divider sx={{ width: "100%" }} />
      <Box sx={{ height: 30 }}></Box>
      <Contact></Contact>
      <Box sx={{ height: 100 }}></Box> */}

        <Dialog
          onClose={() => toggleLockD(false)}
          open={lockD}
          fullWidth={true}
        >
          <Box sx={{ height: 40 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            {!completeD && (
              <Grid>
                {first_pass === "" && (
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    direction="column"
                    textAlign={"center"}
                  >
                    <Typography
                      sx={{ fontSize: 18, fontWeight: "bold" }}
                      mt={2}
                      mr={2}
                      ml={2}
                    >
                      パスワードを作成する
                    </Typography>
                    <Typography
                      sx={{ fontSize: 16, fontWeight: "bold" }}
                      mt={2}
                      mr={2}
                      ml={2}
                    >
                      4桁の半角数字を入力してください
                    </Typography>
                    <Box sx={{ height: 40 }}></Box>
                    <Grid>
                      <TextField
                        inputProps={{
                          inputMode: "numeric",
                          maxLength: 4,
                        }}
                        required
                        value={pass}
                        onChange={(e) => {
                          const regex = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            regex.test(e.target.value)
                          ) {
                            SetPass(e.target.value);
                          }
                        }}
                      ></TextField>

                      <Box sx={{ height: 60 }}></Box>

                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#ffa94d",
                          color: "white",
                          width: "80%",
                          height: 40,
                        }}
                        onClick={() => CheckPass()}
                      >
                        {" "}
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          つぎへ
                        </Typography>
                      </Button>
                      <Box sx={{ height: 20 }}></Box>
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => toggleLockD(false)}
                        style={{ width: "80%", height: 40 }}
                      >
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          もどる
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                )}

                {first_pass !== "" && (
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    direction="column"
                    textAlign={"center"}
                  >
                    <Typography
                      sx={{ fontSize: 18, fontWeight: "bold" }}
                      mt={2}
                      mr={2}
                      ml={2}
                    >
                      パスワードを作成する
                    </Typography>
                    <Typography
                      sx={{ fontSize: 16, fontWeight: "bold" }}
                      mt={2}
                      mr={2}
                      ml={2}
                    >
                      もう一度入力して下さい
                    </Typography>
                    <Box sx={{ height: 40 }}></Box>
                    <Grid sx={{ width: "80%" }}>
                      <TextField
                        type="number"
                        required
                        value={passagain}
                        onChange={(e) => {
                          SetPassAgain(e.target.value);
                        }}
                      ></TextField>

                      <Box sx={{ height: 60 }}></Box>

                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#ffa94d",
                          color: "white",
                          width: "80%",
                          height: 40,
                        }}
                        sx={{ width: 100 }}
                        onClick={() => CheckAgainPass()}
                      >
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          設定する
                        </Typography>
                      </Button>
                      <Box sx={{ height: 20 }}></Box>
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => ClearText()}
                        style={{ width: "80%", height: 40 }}
                      >
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          もどる
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}

            {completeD && (
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                textAlign={"center"}
                sx={{ width: "100%" }}
              >
                <Typography
                  sx={{ fontSize: 18, fontWeight: "bold" }}
                  mt={2}
                  mr={2}
                  ml={2}
                >
                  パスワードを作成しました
                </Typography>
                <Box sx={{ height: 40 }}></Box>
                <Grid sx={{ width: "80%" }}>
                  <Box sx={{ height: 20 }}></Box>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => toggleLockD(false)}
                    style={{ width: "80%", height: 40 }}
                  >
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      もどる
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            )}

            <Box sx={{ height: 40 }}></Box>
          </Grid>
        </Dialog>

        {/* パスワード入力ダイアログ */}
        <Dialog
          onClose={() => toggleUnlockD(false)}
          open={unlockD}
          fullWidth={true}
        >
          <Grid
            // sx={{ width: "100%", height: 400 }}
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Box sx={{ height: 30 }}></Box>
            <Typography sx={{ fontSize: 18, fontWeight: "bold" }} mt={2}>
              パスワードを入力して
            </Typography>
            <Typography sx={{ fontSize: 18, fontWeight: "bold" }} mt={2}>
              まめ日記を表示
            </Typography>
            <Grid sx={{ width: "80%" }}>
              <Box sx={{ height: 30 }}></Box>

              <TextField
                type="number"
                required
                value={pass}
                onChange={(e) => {
                  SetPass(e.target.value);
                }}
              ></TextField>

              <Box sx={{ height: 40 }}></Box>

              <Button
                variant="contained"
                style={{
                  backgroundColor: "#ffa94d",
                  color: "white",
                  width: "80%",
                  height: 40,
                }}
                onClick={() => CheckUnlockPass()}
              >
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  つぎへ
                </Typography>
              </Button>
              <Box sx={{ height: 20 }}></Box>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => toggleUnlockD(false)}
                style={{ width: "80%", height: 40 }}
              >
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  もどる
                </Typography>
              </Button>
              <Box sx={{ height: 30 }}></Box>
            </Grid>
          </Grid>
        </Dialog>

        <Dialog onClose={loginDclose} open={loginD} sx={{ width: "100%" }}>
          <Box sx={{ height: 40 }}></Box>
          <Grid p={2}>
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold" }}
              mt={2}
              mr={2}
              ml={2}
            >
              ご利用には、新規登録・ログインが必要です
            </Typography>
            <Box sx={{ height: 40 }}></Box>
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              textAlign={"center"}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#ff7043",
                  color: "white",
                  width: "70%",
                  height: 40,
                }}
                onClick={() => goSignup()}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  登録・ログイン
                </Typography>
              </Button>
              <Box sx={{ height: 20 }}></Box>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => loginDclose()}
                style={{ width: "70%" }}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  もどる
                </Typography>
              </Button>
            </Grid>
            <Box sx={{ height: 40 }}></Box>
          </Grid>
        </Dialog>
        <BottomMenu value={8} />
      </Grid>
    );
  } else {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress></CircularProgress>
        <BottomMenu value={8} />
      </Box>
    );
  }
}
