import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { getAuth } from "firebase/auth";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { createContext } from "react";

//アニメーションを表示
//DialyHomeへ

export default function KamidanaLoad() {
  const navigate = useNavigate();

  const [user_id, setUser_id] = useState("");
  const [document_id, setDocumentId] = useState("");

  useLayoutEffect(() => {
    get_baseinfo();
  }, []);

  const goDialy = () => {
    navigate("/dialy");
  };

  const get_baseinfo = async () => {
    // console.log("get_baseinfo was called");
    try {
      const auth = getAuth();
      const current_uid = auth.currentUser.uid;
      // console.log("current_uid: " + current_uid);

      setUser_id(current_uid);
      const userRef = collection(db, "account_list");

      let docid = "";
      const q = query(userRef, where("uid", "==", current_uid));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setDocumentId(doc.id);
        // console.log("doc id: " + doc.id);
        docid = doc.id;
      });
      GetSanpaiDeta(userRef, docid);
    } catch (e) {
      console.log("error: " + e.message);
      // setAlert(true);
    }
  };

  const GetSanpaiDeta = async (userRef, docid) => {
    //ここで参拝した日をクエリ

    try {
      let index = 0;
      let sanpai_values = [{ day: "" }];
      const sanpaiRef = collection(db, "account_list", docid, "kamidana");

      const sanpaiSnaps = await getDocs(sanpaiRef);
      sanpaiSnaps.forEach((doc) => {
        sanpai_values[index] = doc.data();
        index += 1;
      });

      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const today_text = year + "年" + month + "月" + day + "日";
      const deta = { day: today_text };
      let add = false;
      console.log("sanpai_values: " + JSON.stringify(sanpai_values));

      for (let i = 0; i < sanpai_values.length; i++) {
        if (sanpai_values[i]["day"] !== today_text) {
          //まだ参拝していないなら
          add = true;
        }
      }

      console.log(add)

      if (add) {
        //今日の日付をDBに登録
        await addDoc(sanpaiRef, deta);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"center"}
    >
      <Box sx={{ height: 200 }}></Box>
      ここでアニメーションを表示
      <Box sx={{ height: 20 }}></Box>
      <Button onClick={() => goDialy()} variant="contained">
        アニメーションが終了、DialyHomeへ
      </Button>
    </Grid>
  );
}
