import React, {
  useState,
  useContext,
  useLayoutEffect,
  useEffect,
  useRef,
} from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Fab from "@mui/material/Fab";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import { Progress } from "semantic-ui-react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import tabibito_woman from "../images/tabibito_woman.jpg";
import tabibito_men from "../images/tabibito_men.jpg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AcroFormComboBox } from "jspdf";
import ShareComponent from "../ Component/Sub/ShareComponent";
import { QrReader } from "react-qr-reader";
import {
  BrowserQRCodeReader,
  NotFoundException,
  ChecksumException,
  FormatException,
} from "@zxing/library";

export default function Camera(props) {
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [code, setCode] = useState("");
  const [stop, setStop] = useState(false);
  const [videoInputDevices, setVideoInputDevices] = useState([]);
  const navigate = useNavigate();

  const codeReader = new BrowserQRCodeReader();
  const videoRef = useRef < HTMLVideoElement > null;

  console.log("ZXing code reader initialized");

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    codeReader
      .getVideoInputDevices()
      .then((videoInputDevices) => {
        setupDevices(videoInputDevices);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  function setupDevices(videoInputDevices) {
    const sourceSelect = document.getElementById("sourceSelect");

    // selects first device
    setSelectedDeviceId(videoInputDevices[0].deviceId);

    // setup devices dropdown
    if (videoInputDevices.length >= 1) {
      setVideoInputDevices(videoInputDevices);
    }
  }

  function resetClick() {
    setStop(true);
    codeReader.reset();
    setCode("");
    console.log("Reset.");
  }

  const backPage = () => {
    codeReader.reset();
    codeReader.stopContinuousDecode();
    codeReader.stopAsyncDecode();

    setStop(true);
    console.log("backPage");
    console.log(stop);
    props.back();
  };

  const handleGosyuin = (value) => {
    let text = "";
    switch (value) {
      case "5.1":
        text = "5.1";
        break;
      case "5.2":
        text = "5.2";
        break;
      case "5.3":
        text = "5.3";
        break;
      case "5.4":
        text = "5.4";
        break;
      case "5.5":
        text = "5.5";
        break;
      case "5.6":
        text = "5.6";
        break;
      case "5.7":
        text = "5.7";
        break;
      case "5.8":
        text = "5.8";
        break;
      case "5.9":
        text = "5.9";
        break;
      case "5.10":
        text = "5.10";
        break;
      case "5.11":
        text = "5.11";
        break;
      case "5.12":
        text = "5.12";
        break;
      default:
        break;
    }
    props.handleQRvalue(text);
    console.log("text at Camera: " + text);
  };

  function decodeContinuously(selectedDeviceId) {
    codeReader.decodeFromInputVideoDeviceContinuously(
      selectedDeviceId,
      "video",
      (result, err) => {
        if (result) {
          // properly decoded qr code
          console.log("Found QR code!", result);
          setCode(result.text);
          if (props.type === "gosyuin") {
            backPage();
            handleGosyuin(result.text);
          }
          if (result.text === "https://ookunitama-negai.site/load") {
            console.log("参拝を記録します");
            resetClick();
            setStop(true);
            navigate("/load");
          }
        }

        if (err) {
          setCode("");

          if (err instanceof NotFoundException) {
            console.log("No QR code found.");
          }

          if (err instanceof ChecksumException) {
            console.log("A code was found, but it's read value was not valid.");
          }

          if (err instanceof FormatException) {
            console.log("A code was found, but it was in a invalid format.");
          }
        }
      }
    );
  }

  useEffect(() => {
    if (!stop) {
      decodeContinuously(selectedDeviceId);
    }
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      textAlign={"left"}
    >
      <Box sx={{ height: 20 }}></Box>
      <Typography sx={{ fontSize: 18, fontWeight: "bold", mt: 3 }}>
        QRコードを中央に写してください
      </Typography>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign={"left"}
      >
        <section
          className="container"
          id="demo-content"
          style={{ marginTop: 3 }}
        >
          <div>
            <video id="video" width="300" height="300" />
          </div>
          <pre>
            <code id="result">{code}</code>
          </pre>
        </section>
      </Grid>

      <Button
        variant="contained"
        color="inherit"
        onClick={() => backPage()}
        sx={{ width: "60%", height: 40 }}
      >
        <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
          もどる
        </Typography>
      </Button>
      <Box sx={{ height: 50 }}></Box>
    </Grid>
  );
}
