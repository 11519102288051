import React, { useState, useLayoutEffect, useContext, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { auth, provider, app } from "../firebase";
import { useAuthContext } from "../context/ AuthContext";
import Button from "@mui/material/Button";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import magatama from "../images/magatama.png";
import foxface from "../images/foxface.png";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import ExpointComponent from "./Sub/ExpointComponent";
import GroupIcon from "@mui/icons-material/Group";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

function DenseAppbar(props) {
  const navigate = useNavigate();
  const title_text = props.text;
  const { user } = useAuthContext();
  // console.log("props.magatama_count" + props.magatama_count);

  const [open_value, setOpenValue] = useState(false);
  const [magatamaD, setmagatamaD] = useState(false);
  const [level, set_level] = useState(0);

  useLayoutEffect(() => {
    const n = props.ex_count;
    if (n < 200) {
      set_level(1);
    } else if (200 < n && n < 400) {
      set_level(2);
    } else if (400 < n && n < 600) {
      set_level(3);
    }
  }, []);

  const logout = () => {
    try {
      auth.signOut();
      console.log("Sign-out successful.");
      navigate("/logoutcomplete");
    } catch (err) {
      console.log(err.message);
    }
  };

  const togglemagatamaD = (open) => {
    setmagatamaD(open);
  };

  // const handleChange = (event) => {
  //   setAuth(event.target.checked);
  // };

  const goHome = (event) => {
    navigate("/");
  };

  const goLogin = (event) => {
    navigate("/login");
  };

  const goSetting = (event) => {
    navigate("/setting");
  };

  const goSignup = (event) => {
    navigate("/signup");
  };

  const handleMenu = () => {
    if (open_value === false) {
      setOpenValue(true);
    } else {
      setOpenValue(false);
    }
  };

  const handleClose = () => {
    setOpenValue(false);
  };

  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <AppBar position="static" sx={{ backgroundColor: "white" }}>
        <Toolbar variant="dense">
          {/* <img src={pic} alt="icon" style={{ width: 25 }} onClick={goHome} /> */}
          <Typography
            onClick={goHome}
            component="div"
            sx={{
              flexGrow: 1,
              fontSize: 18,
              fontWeight: "bold",
              color: "black",
              width: 150,
            }}
            ml={1}
          >
            {title_text}
          </Typography>

          {user !== null && typeof props.magatama_count !== "undefined" && (
            <React.Fragment>
              <Grid
                container
                direction={"row"}
                alignItems="center"
                justifyContent={"flex-start"}
              >
                {/* <Grid item mr={2}>
                  <Box onClick={() => togglemagatamaD(true)}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      <img
                        src={foxface}
                        height={"23"}
                        // style={{ backgroundColor: "blue", padding: 0 }}
                      ></img>
                      <Typography sx={{ fontSize: 16, color: "black", ml: 1 }}>
                        Lv {level}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid> */}

                <Grid item mr={2}>
                  <Box onClick={() => togglemagatamaD(true)}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      <img
                        src={magatama}
                        height={"25"}
                        // style={{ backgroundColor: "blue", padding: 0 }}
                      ></img>
                      <Typography sx={{ fontSize: 16, color: "black" }}>
                        {props.magatama}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item mr={1}>
                  <Box onClick={() => togglemagatamaD(true)}>
                    <ExpointComponent ex_count={props.ex_count} />
                  </Box>
                </Grid>
              </Grid>
              {/* <Grid>
                <IconButton size="midium" onClick={() => goSetting()}>
                  <SettingsOutlinedIcon sx={{ fontSize: 28 }} />
                </IconButton>
              </Grid> */}
            </React.Fragment>
          )}
          {user == null && (
            <Grid container alignItems="center" justifyContent="flex-end">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#ff7043",
                  color: "white",
                  height: "70%",
                  width: 140,
                }}
                onClick={() => goSignup()}
              >
                <Typography
                  sx={{ flexGrow: 1, fontSize: 14, fontWeight: "bold" }}
                >
                  登録・ログイン
                </Typography>
              </Button>
            </Grid>
          )}
          {user !== null && (
            <Grid item>
              <IconButton size="midium" onClick={() => goSetting()}>
                <AccountCircleOutlinedIcon
                  // style={{ color: "#fff" }}
                  sx={{ fontSize: 27 }}
                />
              </IconButton>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
      <Dialog
        onClose={() => togglemagatamaD(false)}
        open={magatamaD}
        sx={{ width: "100%" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
          textAlign={"left"}
          // sx={{ height: 300 }}
          p={1}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            direction="row"
            mt={3}
            p={1}
          >
            <Grid item alignItems="center" justifyContent="center">
              <img src={magatama} height={"35"}></img>
            </Grid>
            <Grid item alignItems="center" justifyContent="center">
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                勾玉(まがたま)を集めるには？
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ width: "100%" }}></Divider>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-start"
            textAlign={"left"}
            direction={"column"}
            p={1}
            mt={2}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold", mt: 3 }}>
              1 参拝日数を記録する
            </Typography>
            <Typography sx={{ fontSize: 14, mt: 1 }}>
              　神社に参拝して、QRコードを読み込む
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: "bold", mt: 3 }}>
              ２神社クイズに挑戦する
            </Typography>
            <Typography sx={{ fontSize: 14, mt: 1 }}>
              　クイズページから神社クイズを解く
            </Typography>
            {/* <Typography sx={{ fontSize: 16, fontWeight: "bold", mt: 3 }}>
              ３実績を達成する
            </Typography>
            <Typography sx={{ fontSize: 14, mt: 1 }}>
              　実績を確認して、おマガタマを受け取る
            </Typography> */}

            <Box sx={{ height: 40 }}></Box>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              direction="row"
            >
              <Grid item alignItems="center" justifyContent="center">
                <img src={magatama} height={"35"}></img>
              </Grid>
              <Grid item alignItems="center" justifyContent="center">
                <Typography sx={{ fontSize: 16, fontWeight: "bold", mt: 3 }}>
                  勾玉(まがたま)と引換券
                </Typography>
              </Grid>
              <Divider sx={{ width: "100%" }}></Divider>

              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                textAlign={"left"}
                direction={"column"}
                p={1}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold", mt: 3 }}>
                  様々な特典と引き換え
                </Typography>
                <Typography sx={{ fontSize: 14, mt: 1 }}>
                  お名前入り御朱印などの特典と引き換えできます。
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{ height: 40 }}></Box>

            <Grid
              container
              alignItems="center"
              justifyContent="center"
              textAlign={"left"}
              direction={"column"}
            >
              <Button
                variant="contained"
                color="inherit"
                onClick={() => togglemagatamaD(false, 0)}
                sx={{ width: "70%", height: 40 }}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  もどる
                </Typography>
              </Button>
            </Grid>
            <Box sx={{ height: 40 }}></Box>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
}

export default DenseAppbar;
