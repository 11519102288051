import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import magatama from "../../images/magatama.png";

export default function ShopItems() {
  const [tab_value, setTabValue] = React.useState(0); //タブの値

  const shopitems = [
    { name: "アイテム1", img: magatama },
    { name: "アイテム2", img: magatama },
    { name: "アイテム3", img: magatama },
    { name: "アイテム4", img: magatama },
    { name: "アイテム5", img: magatama },
    { name: "アイテム6", img: magatama },
  ];

  const wallpapers = [
    { name: "壁紙1", img: magatama },
    { name: "壁紙2", img: magatama },
    { name: "壁紙3", img: magatama },
    { name: "壁紙4", img: magatama },
  ];

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const useStyles = makeStyles((theme) => ({
    fab: {
      position: "fixed",
      margin: 0,
      bottom: 80,
      right: 25,
      top: "auto",
    },
    tabLabel: {
      fontSize: 16,
      fontWeight: "bold",
    },
    tabPanelRoot: {
      padding: 0,
    },
    container: {},
  }));
  const classes = useStyles();

  return (
    <Grid
      container
      textAlign={"center"}
      alignItems="center"
      justifyContent="flex-start"
      direction={"column"}
      sx={{ height: 300 }}
    >
      <Box sx={{ width: "100%" }}>
        <Box>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: tab_value === 0 ? "#ffa94d" : "#ece9e9f2",
                  color: tab_value === 0 ? "white" : "black",
                  width: "100%",
                  height: 40,
                  border: 1,
                  borderColor: "#8f8e8ef2",
                }}
                onClick={() => handleTabChange(0)}
              >
                <Typography className={classes.tabLabel}>小物</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: tab_value === 1 ? "#ffa94d" : "#ece9e9f2",
                  color: tab_value === 1 ? "white" : "black",
                  width: "100%",
                  height: 40,
                  border: 1,
                  borderColor: "#8f8e8ef2",
                }}
                onClick={() => handleTabChange(1)}
              >
                <Typography className={classes.tabLabel}>壁紙</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {tab_value === 0 && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            mt={1}
          >
            {shopitems.map((deta, i) => (
              <Grid item xs={5} key={i}>
                <Grid container justifyContent="center" alignItems="center">
                  <Card sx={{ width: "100%" }}>
                    <CardActionArea>
                      <CardContent>
                        <img src={deta.img} width="40" height="40"></img>
                        <Typography>{deta.name}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}

        {tab_value === 1 && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            mt={1}
          >
            {wallpapers.map((deta, i) => (
              <Grid item xs={5} key={i}>
                <Grid container justifyContent="center" alignItems="center">
                  <Card sx={{ width: "100%" }}>
                    <CardActionArea>
                      <CardContent>
                        <img src={deta.img} width="40" height="40"></img>
                        <Typography>{deta.name}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}

        <Box sx={{ height: 10 }}></Box>
      </Box>
      {/* <Box sx={{ height: 30 }}></Box> */}
      {/* <Card sx={{ width: "90%" }}>
        <CardMedia sx={{ height: 90 }} image={wall1} />
        <CardActionArea onClick={() => toggleDrawer()}></CardActionArea>
      </Card> */}
    </Grid>
  );
}
