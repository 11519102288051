import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Typography from "@mui/material/Typography";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Fab from "@mui/material/Fab";
import { getAuth } from "firebase/auth";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
  clearIndexedDbPersistence,
} from "firebase/firestore";
import { db } from "../../firebase";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Drawer from "@mui/material/Drawer";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Camera from "../../AchieveComponent/Camera.js";
import five_1 from "../../images/five_1.jpg";
import five_2 from "../../images/five_2.jpg";
import five_3 from "../../images/five_3.jpg";
import five_4 from "../../images/five_4.jpg";
import five_5 from "../../images/five_5.jpg";
import five_6 from "../../images/five_6.jpg";
import w_five_1 from "../../images/w_five_1.jpg";
import w_five_2 from "../../images/w_five_2.jpg";
import w_five_3 from "../../images/w_five_3.jpg";
import w_five_4 from "../../images/w_five_4.jpg";
import w_five_5 from "../../images/w_five_5.jpg";
import w_five_6 from "../../images/w_five_6.jpg";

//拝受した御朱印を表示する
//カメラでQRコードを読み込むと、その月の御朱印が反映される
//タップすると拡大

export default function MyGosyuin(props) {
  const navigate = useNavigate();

  const [imgD, setImgD] = useState(false);
  const [img_index, setImg_index] = useState(0);
  const [openCameraD, set_openCameraD] = useState(false);
  const [cameraD, set_cameraD] = useState(false);
  const [QR_text, set_QR_text] = useState("");
  const [gosyuins, set_gosyuins] = useState([
    { text: "1月「睦月」", img: w_five_1 },
    { text: "2月「如月」", img: w_five_2 },
    { text: "3月「弥生」", img: w_five_3 },
    { text: "4月「卯月」", img: w_five_4 },
    { text: "5月「皐月」", img: w_five_5 },
    { text: "6月「水無月」", img: w_five_6 },
    // { text: "「7月」御朱印", img: w_five_5 },
    // { text: "「8月」御朱印", img: w_five_5 },
    // { text: "「9月」御朱印", img: w_five_5 },
    // { text: "「10月」御朱印", img: w_five_5 },
    // { text: "「11月」御朱印", img: w_five_5 },
    // { text: "「12月」御朱印", img: w_five_5 },
  ]);

  const [user_gosyuin, Set_user_gosyuin] = useState([]);
  const [explainD, set_explainD] = useState(false); //御朱印の色付けをするための説明
  const [alert, set_alert] = useState(false);

  useEffect(() => {}, [img_index, QR_text, gosyuins]);
  useLayoutEffect(() => {
    GetGosyuinDeta("");
  }, []);

  // const handleQRvalue = (text) => {
  //   // console.log(text + "の御朱印コードを読み込みました");
  //   // set_QR_text(text);
  //   //TODO 新しく取得した御朱印をDBに登録する

  //   GetGosyuinDeta(text);
  // };

  const handleIndex = (index) => {
    GetGosyuinDeta(index);
  };

  const Handle_alertD = (open) => {
    set_alert(open);
  };

  const goSignup = () => {
    navigate("/signup");
  };

  const ResetGOsyuinDeta = async (index) => {
    //TODO - 獲得した御朱印を更新する場合
    let new_dic = user_gosyuin;
    let reseted_dic = [];
    if (new_dic.length > 0) {
      for (let i = 0; i < new_dic.length; i++) {
        if (new_dic[i] !== index) {
          reseted_dic.push(new_dic[i]);
        }
      }
    }
    console.log("reseted_dic: " + reseted_dic);
    const deta = { gosyuin: reseted_dic };
    const gosyuinRef = doc(db, "account_list", props.document_id);

    await updateDoc(gosyuinRef, deta); //DBに登録
    ConvertToDisplay(reseted_dic); //その内容を反映
    Set_user_gosyuin(reseted_dic);
    handleimgD(false);
  };

  const GetGosyuinDeta = async (day_text) => {
    //TODO - 配列としてDBに保存
    console.log("----- day_text: " + day_text);
    try {
      let index = 0;
      let gosyuin_values = [];
      const gosyuinRef = doc(db, "account_list", props.document_id);

      const gosyuinSnaps = await getDoc(gosyuinRef);
      const values = gosyuinSnaps.data();
      if (typeof values["gosyuin"] !== "undefined") {
        gosyuin_values = values["gosyuin"];
        Set_user_gosyuin(gosyuin_values);
      }

      console.log("gosyuin_values: " + gosyuin_values);

      //TODO - 獲得した御朱印を更新する場合

      if (day_text !== "" && !gosyuin_values.includes(day_text)) {
        let new_dic = gosyuin_values;
        if (new_dic.length > 0) {
          new_dic.push(day_text);
        } else {
          new_dic = [day_text];
        }

        // console.log("new_dic: " + new_dic);
        const deta = { gosyuin: new_dic };

        await updateDoc(gosyuinRef, deta); //DBに登録
        ConvertToDisplay(new_dic); //その内容を反映
      } else {
        //TODO - 更新しない場合
        ConvertToDisplay(gosyuin_values);
      }
    } catch (e) {
      console.log(e.message);
    }
    handleimgD(false);
  };

  const ConvertToDisplay = (deta) => {
    //TODO - 値があったら、該当する月の御朱印の画像を変更する
    let default_dic = [
      { text: "1月「睦月」", img: w_five_1 },
      { text: "2月「如月」", img: w_five_2 },
      { text: "3月「弥生」", img: w_five_3 },
      { text: "4月「卯月」", img: w_five_4 },
      { text: "5月「皐月」", img: w_five_5 },
      { text: "6月「水無月」", img: w_five_6 },
      // { text: "「7月」御朱印", img: w_five_5 },
      // { text: "「8月」御朱印", img: w_five_5 },
      // { text: "「9月」御朱印", img: w_five_5 },
      // { text: "「10月」御朱印", img: w_five_5 },
      // { text: "「11月」御朱印", img: w_five_5 },
      // { text: "「12月」御朱印", img: w_five_5 },
    ];

    // console.log("DB gosyuin: " + deta);
    for (let i = 0; i < deta.length; i++) {
      switch (deta[i]) {
        case 0:
          default_dic[0]["img"] = five_1;
          break;
        case 1:
          default_dic[1]["img"] = five_2;
          break;
        case 2:
          default_dic[2]["img"] = five_3;
          break;
        case 3:
          default_dic[3]["img"] = five_4;
          break;
        case 4:
          default_dic[4]["img"] = five_5;
          break;
        case 5:
          default_dic[5]["img"] = five_6;
          break;
        case 6:
          default_dic[6]["img"] = five_6;
          break;
        case 7:
          default_dic[7]["img"] = five_6;
          break;
        case 8:
          default_dic[8]["img"] = five_6;
          break;
        case 9:
          default_dic[9]["img"] = five_6;
          break;
        default:
          break;
      }
    }
    set_gosyuins(default_dic);
  };

  const cameraDHandler = (open) => {
    set_cameraD(open);
  };

  const opencameraDHandler = (open) => {
    set_openCameraD(open);
  };

  const handleimgD = (open, i) => {
    setImgD(open);
    setImg_index(i);
  };

  const Handle_explainD = (open) => {
    set_explainD(open);
  };

  const useStyles = makeStyles((theme) => ({
    fab: {
      position: "fixed",
      margin: 0,
      bottom: 80,
      right: 25,
      top: "auto",
    },
  }));
  const classes = useStyles();

  const openCamera = () => {
    if (props.document_id == "") {
      //アラートを表示
      Handle_alertD(true);
      return;
    }

    opencameraDHandler(true);
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"left"}
    >
      <Divider sx={{ width: "90%", mb: 2 }}></Divider>
      <Box sx={{ height: 20 }}></Box>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        textAlign={"left"}
        mb={2}
      >
        <Grid item xs={6}>
          <Typography sx={{ ml: 3, fontSize: 18, fontWeight: "bold" }}>
            あなたの御朱印
          </Typography>
        </Grid>
        {/* <Grid item xs={6}>
          <Button
            onClick={() => Handle_explainD(true)}
            sx={{ backgroundColor: "#ece9e9f2", width: "90%" }}
            mr={0.5}
          >
            <Typography sx={{ color: "black", fontSize: 14 }}>
              色を付けるには？
            </Typography>
            <HelpOutlineIcon sx={{ color: "black" }}></HelpOutlineIcon>
          </Button>
        </Grid> */}

        {/* <Typography sx={{ ml: 3, mt: 2, fontSize: 15 }}>
          未拝受は白黒で表示されています
        </Typography> */}
        <Typography sx={{ ml: 3, mt: 2, fontSize: 15 }}>
          授与された御朱印はタップして色をつけましょう
        </Typography>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          {/* <Box sx={{ height: 20 }}></Box> */}
          {/* <Button
            onClick={() => openCamera()}
            variant="contained"
            sx={{
              backgroundColor: "#ffa94d",
              color: "white",
              // borderRadius: "16px",
              height: 40,
              width: "80%",
            }}
            // className={classes.fab}
          >
            <Typography sx={{ fontSize: 18, fontWeight: "bold" }} mr={0.5}>
              カメラを開く
            </Typography>
            <CameraAltOutlinedIcon
              sx={{ fontSize: 23 }}
            ></CameraAltOutlinedIcon>
          </Button> */}
        </Grid>
        {/* <Typography sx={{ ml: 3, mt: 2, fontSize: 15 }}>
            御朱印お渡し時に配布するQRコードを読み込んで、色をつけましょう。
          </Typography> */}
      </Grid>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="row"
        textAlign={"left"}
        spacing={2}
        p={2}
      >
        {gosyuins.map((value, i) => (
          <Grid item xs={6} key={i}>
            <Card>
              <CardActionArea onClick={() => handleimgD(true, i)}>
                <CardContent sx={{ p: 2, "&:last-child": { pb: 1 } }}>
                  <Box textAlign={"center"}>
                    <img src={gosyuins[i]["img"]} width="100%"></img>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: "bold" }}
                      mt={1}
                    >
                      {gosyuins[i]["text"]}
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* <Box sx={{ height: 20 }}></Box> */}
      <Dialog onClose={() => handleimgD(false, 0)} open={imgD}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          textAlign={"center"}
          p={1}
          pt={3}
          pb={3}
        >
          {user_gosyuin.includes(img_index) && imgD && (
            <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
              この御朱印の色をリセットしますか？
            </Typography>
          )}

          {!user_gosyuin.includes(img_index) && imgD && (
            <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
              この御朱印に色をつけますか？
            </Typography>
          )}
          <Grid container alignItems="center" justifyContent="center" mt={3}>
            {!user_gosyuin.includes(img_index) && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#ffa94d",
                  color: "white",
                  height: 55,
                  width: "60%",
                }}
                onClick={() => handleIndex(img_index)}
              >
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  色をつける
                </Typography>
              </Button>
            )}
            {user_gosyuin.includes(img_index) && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#ffa94d",
                  color: "white",
                  height: 55,
                  width: "60%",
                }}
                onClick={() => ResetGOsyuinDeta(img_index)}
              >
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  リセットする
                </Typography>
              </Button>
            )}
          </Grid>
          <Box sx={{ height: 40 }}></Box>

          <Grid container alignItems="center" justifyContent="center" mt={2}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#ece9e9f2",
                color: "black",
                height: 55,
                width: "60%",
              }}
              onClick={() => handleimgD(false, 0)}
            >
              <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                もどる
              </Typography>
            </Button>
          </Grid>

          <Box sx={{ height: 40 }}></Box>
        </Grid>
      </Dialog>
      {/* <Drawer
        onClose={() => opencameraDHandler(false)}
        open={openCameraD}
        anchor={"bottom"}
      >
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
          height={500}
        >
          <Camera
            back={() => opencameraDHandler(false)}
            type="gosyuin"
            handleQRvalue={(value) => handleQRvalue(value)}
          ></Camera>
        </Grid>
      </Drawer> */}
      <Dialog onClose={() => Handle_explainD(false)} open={explainD}>
        <Grid container alignItems="center" textAlign="left" p={3}>
          <Typography sx={{ fontSize: 15, fontWeight: "bold", mt: 2 }}>
            御朱印と一緒に、QRコードが記載された紙をお渡しします。
          </Typography>
          <Typography sx={{ fontSize: 15, fontWeight: "bold", mt: 2 }}>
            このページにある、「カメラを開く」をタップして読み込んで下さい。
          </Typography>
          <Typography sx={{ fontSize: 15, mt: 2 }}>
            ＊過去の御朱印の色をつけたい方は、係にお聞き下さい。
          </Typography>
          <Grid container alignItems="center" justifyContent={"center"} mt={3}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#ece9e9f2",
                color: "black",
                height: 40,
                width: "60%",
              }}
              onClick={() => Handle_explainD(false)}
            >
              <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                もどる
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        onClose={() => Handle_alertD(false)}
        open={alert}
        sx={{ width: "100%" }}
      >
        <Box sx={{ height: 40 }}></Box>
        <Grid p={2}>
          <Typography
            sx={{ fontSize: 16, fontWeight: "bold" }}
            mt={2}
            mr={2}
            ml={2}
          >
            ご利用には、新規登録・ログインが必要です
          </Typography>
          <Box sx={{ height: 40 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#ff7043",
                color: "white",
                width: "70%",
                height: 40,
              }}
              onClick={() => goSignup()}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                登録・ログイン
              </Typography>
            </Button>
            <Box sx={{ height: 20 }}></Box>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => Handle_alertD(false)}
              style={{ width: "70%" }}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                もどる
              </Typography>
            </Button>
          </Grid>
          <Box sx={{ height: 40 }}></Box>
        </Grid>
      </Dialog>
    </Grid>
  );
}
