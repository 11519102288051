import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import { auth, provider, app } from "../../firebase";
import { useAuthContext } from "../../context/ AuthContext";
import Family from "../../ Component/Family";
import {
  getAuth,
  onAuthStateChanged,
  deleteUser,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { Link as RouterLink } from "react-router-dom";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import oonusa from "../../images/oonusa.png";
import torii from "../../images/torii.png";
import CircularProgress from "@mui/material/CircularProgress";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

import {
  collection,
  addDoc,
  doc,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  deleteDoc,
  getDoc,
  deleteField,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import DenseAppbar from "../../ Component/DenseAppbar";
import ScrollTop from "../../helper/ScrollTop";
import Skeleton from "@mui/material/Skeleton";
import BottomMenu from "../../ Component/Sub/BottomMenu";
import { motion } from "framer-motion";

export default function SettingHome() {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const [loginD, setLoginD] = useState(false);
  const [logoutD, setLogoutD] = useState(false);
  const [document_id, setDocumentId] = useState("");
  const [accounts, setAccounts] = useState("");
  const [passresetD, SetpassresetD] = useState(false);
  const [dialy_password, setdialy_password] = useState(null);

  const [delete_account_D, setDeleteAccountD] = useState(false);
  const [delete_complete_D, setDeleteCompleteD] = useState(false);
  const [loading, setLoading] = useState(true);

  //magatama
  const [magatama_count, setmagatama_Count] = useState(null);

  useEffect(() => {
    get_info();
  }, []);

  useEffect(() => {
    if (magatama_count !== null) {
      setLoading(false);
    }

    if (user == null) {
      setLoading(false);
    }
  }, [magatama_count]);

  const get_info = async () => {
    if (user != null) {
      try {
        let docId = "";
        const auth = getAuth();
        const current_uid = auth.currentUser.uid;
        // console.log("Current userID: " + current_uid);
        const userRef = collection(db, "account_list");
        //コレクションから条件クエリし、refを取得
        const q = query(userRef, where("uid", "==", current_uid));
        await getDocs(q).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            docId = doc.id;
            setDocumentId(doc.id);
          });
          if (docId === "") {
            const current_uid = auth.currentUser.uid;
            // console.log("Adding uid to DB");
            const ref = collection(db, "account_list");
            const documentRef = addDoc(ref, {
              uid: current_uid,
            });
            // console.log(documentRef);
          }
        });

        get_info2(userRef, docId);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("user does not exist");
    }
  };
  const get_info2 = async (userRef, docid) => {
    const docRef = doc(db, "account_list", docid);
    const docSnap = await getDoc(docRef);
    const values = docSnap.data();

    if (typeof values["dango"] !== "undefined") {
      //マガタマの数
      setmagatama_Count(values["dango"]);
    } else {
      setmagatama_Count(0);
    }
    try {
      //アカウント一覧があるsubcollectionのRef
      let index = 0;
      let accounts_values = [""];
      const accountsRef = collection(db, "account_list", docid, "accounts");
      const accountsSnaps = await getDocs(accountsRef);
      accountsSnaps.forEach((d) => {
        accounts_values[index] = d.data();
        index += 1;
      });
      setAccounts(accounts_values);

      //日記パスワードを取得
      const docRef = doc(db, "account_list", docid);
      const docSnap = await getDoc(docRef);
      const values = docSnap.data();
      const dialy_pass = values["dialypass"];
      setdialy_password(dialy_pass);
      // console.log(dialy_pass);
      if (typeof dialy_pass === "string") {
        // console.log("dialy_pass: " + dialy_pass);
      } else {
        // console.log("dialy_passが設定されていません");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goAccounts = () => {
    if (user !== null) {
      navigate("/accounts");
    } else {
      loginDopen();
    }
  };

  const ToggleAccountDeleteDialog = (open) => {
    setDeleteAccountD(open);
  };

  const ToggleAccountDeleteComplete = (open) => {
    setDeleteCompleteD(open);
  };

  /**
   * ログアウト
   */
  const logout = () => {
    try {
      auth.signOut();
      console.log("Sign-out successful.");
      navigate("/logoutcomplete");
    } catch (err) {
      console.log(err.message);
    }
  };

  const goTerms = () => {
    navigate("/terms");
  };

  /**
   * 「封」のパスワードをリセットする
   */
  const deletePassword = async () => {
    const passRef = doc(db, "account_list", document_id);
    await updateDoc(passRef, {
      dialypass: deleteField(),
    });
    passtogglereset(true);
  };

  const deleteDocumentRecursively = async (docRef) => {
    const collections = await docRef.listCollections();

    if (collections.length > 0) {
      for (const collection of collections) {
        const snapshot = await collection.get();
        for (const doc of snapshot.docs) {
          await deleteDocumentRecursively(doc.ref);
        }
      }
    }

    await docRef.delete();
  };

  /**
   * アカウントを削除する
   */
  const DeleteAccount = async () => {
    const passRef = doc(db, "account_list", document_id);
    await deleteDoc(passRef);

    // サブコレクション含め再帰的に削除する
    await deleteDocumentRecursively(
      doc("account_list", document_id, "accounts")
    );

    const auth = getAuth();
    const user = auth.currentUser;
    console.log(auth.currentUser.email);
    //ダイアログを表示してユーザーにパスワードを入力してもらう
    const credential = await EmailAuthProvider.credential(
      auth.currentUser.email
      // userProvidedPassword
    );

    deleteUser(user)
      .then(() => {
        console.log("user deleted");
      })
      .catch((error) => {
        console.log(error);

        // An error ocurred
        // TODO(you): prompt the user to re-provide their sign-in credentials

        reauthenticateWithCredential(user, credential)
          .then(() => {
            // User re-authenticated.
            console.log("user re-authenticated");
          })
          .catch((error) => {
            // An error ocurred
            console.log(error);

            // ...
          });
      });

    ToggleAccountDeleteComplete(true);
  };

  const passtogglereset = (open) => {
    SetpassresetD(open);
  };
  const loginDopen = () => {
    setLoginD(true);
  };

  const loginDclose = () => {
    setLoginD(false);
  };

  const logoutDopen = () => {
    setLogoutD(true);
  };

  const logoutDclose = () => {
    setLogoutD(false);
  };

  const goSignup = () => {
    navigate("/signup");
  };

  const Endreset = () => {
    passtogglereset(false);
    ToggleAccountDeleteDialog(false);
    ToggleAccountDeleteComplete(false);
    get_info();
  };
  if (!loading) {
    return (
      <Grid>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <ScrollTop></ScrollTop>
          <DenseAppbar text="設定" magatama={magatama_count}></DenseAppbar>
          <Box sx={{ height: 40 }}></Box>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            textAlign={"center"}
            sx={{ width: "100%" }}
          >
            {accounts !== "" && (
              <React.Fragment>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  textAlign={"left"}
                >
                  <Grid item xs={6}>
                    <Typography
                      sx={{ ml: 3, fontSize: 20, fontWeight: "bold" }}
                    >
                      アカウント一覧
                    </Typography>
                  </Grid>
                </Grid>

                <Box sx={{ height: 20 }}></Box>

                <Card sx={{ width: "90%" }}>
                  <CardContent>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign={"center"}
                    >
                      <Family accounts={accounts}></Family>
                      <Box sx={{ height: 20 }}></Box>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#ffa94d",
                          color: "white",
                          minWidth: "200px",
                          minHeight: "40px",
                          width: "60%",
                          height: 55,
                        }}
                        onClick={() => goAccounts()}
                      >
                        <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                          追加・編集
                        </Typography>
                      </Button>
                    </Grid>
                  </CardContent>
                </Card>
                {/* {accounts == "" && (
            <Skeleton variant="rounded" width={"90%"} height={240} />
          )} */}

                <Divider sx={{ width: "90%", mb: 3 }}></Divider>
              </React.Fragment>
            )}
          </Grid>

          <Box sx={{ height: 20 }}></Box>

          {/* {typeof dialy_password == "string" && (
          <React.Fragment>
            <Divider sx={{ width: "90%", mb: 2 }}></Divider>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              textAlign={"left"}
            >
              <Grid item xs={12}>
                <Typography sx={{ ml: 3, fontSize: 18, fontWeight: "bold" }}>
                  「まめ日記」のパスワードをリセット
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ height: 20 }}></Box>
            <Button
              variant="contained"
              onClick={() => deletePassword()}
              sx={{ backgroundColor: "lightgray", width: "70%" }}
            >
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
              >
                リセットする
              </Typography>
            </Button>
          </React.Fragment>
        )} */}

          <Grid container alignItems="center" justifyContent="center">
            <Card sx={{ width: "90%", mb: 2 }}>
              <CardContent sx={{ "&:last-child": { pb: 1 } }}>
                <Grid container textAlign={"center"}>
                  {/* <Grid item mb={1}>
                <Button sx={{ width: "100%", height: 20 }}>
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
                  >
                    よくある質問
                  </Typography>
                </Button>
              </Grid>
              <Divider sx={{ width: "100%" }}></Divider> */}

                  <Grid item mb={1} mt={1}>
                    <Button
                      sx={{ width: "100%", height: 20 }}
                      onClick={() => goTerms()}
                    >
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        利用規約
                      </Typography>
                    </Button>
                  </Grid>
                  <Divider sx={{ width: "100%" }}></Divider>
                  <Grid item mb={1} mt={2}>
                    <Button
                      sx={{ width: "100%", height: 20 }}
                      onClick={() => goTerms()}
                    >
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        プライバシーポリシー
                      </Typography>
                    </Button>
                  </Grid>
                  {/* <Divider sx={{ width: "100%" }}></Divider> */}
                </Grid>
              </CardContent>
            </Card>
            <Box sx={{ height: 20 }}></Box>
            {user !== null && (
              <Card sx={{ width: "90%" }}>
                <CardContent sx={{ "&:last-child": { pb: 1 } }}>
                  <Grid container textAlign={"center"}>
                    <Grid item mb={1}>
                      <Button
                        sx={{ width: "100%", height: 20 }}
                        onClick={() => logoutDopen()}
                      >
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          ログアウト
                        </Typography>
                      </Button>
                    </Grid>
                    <Divider sx={{ width: "100%" }}></Divider>
                    <Grid item mb={1} mt={2}>
                      <Button
                        sx={{ width: "100%", height: 20 }}
                        onClick={() => ToggleAccountDeleteDialog(true)}
                      >
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          アカウント削除
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
            {user == null && (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                textAlign={"center"}
              >
                <Box sx={{ height: 40 }}></Box>

                <Button
                  sx={{
                    width: "70%",
                    height: 55,
                    backgroundColor: "#ff7043",
                  }}
                  onClick={() => goSignup()}
                  variant="contained"
                >
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "bold", color: "white" }}
                  >
                    新規登録・ログイン
                  </Typography>
                </Button>
              </Grid>
            )}
            <Box sx={{ height: 40 }}></Box>
            <Typography sx={{ mt: 1.1, fontSize: 15, mb: 4 }} variant="body2">
              ©2023 神なび
            </Typography>
            <Box sx={{ height: 120, mb: 10 }}></Box>
          </Grid>

          <Dialog
            onClose={() => ToggleAccountDeleteDialog(false)}
            open={delete_account_D}
            sx={{ width: "100%" }}
          >
            <Box sx={{ height: 40 }}></Box>
            <Grid p={2}>
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }} m={2}>
                アカウントを削除してもよろしいですか？
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: "bold" }} m={2}>
                登録されている情報は失われます。
              </Typography>
              <Box sx={{ height: 40 }}></Box>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                textAlign={"center"}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    width: "70%",
                    height: 55,
                  }}
                  onClick={() => DeleteAccount()}
                >
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    完全に削除する
                  </Typography>
                </Button>
                <Box sx={{ height: 20 }}></Box>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => ToggleAccountDeleteDialog(false)}
                  style={{ width: "70%", height: 55 }}
                >
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    キャンセル
                  </Typography>
                </Button>
              </Grid>
              <Box sx={{ height: 40 }}></Box>
            </Grid>
          </Dialog>

          <Dialog onClose={loginDclose} open={loginD} sx={{ width: "100%" }}>
            <Box sx={{ height: 40 }}></Box>
            <Grid p={2}>
              <Typography sx={{ fontSize: 14 }} mt={2} mr={2} ml={2}>
                ご利用には、新規登録・ログインが必要です
              </Typography>
              <Box sx={{ height: 40 }}></Box>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                textAlign={"center"}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#ff7043",
                    color: "white",
                    width: "70%",
                    height: 55,
                  }}
                  onClick={() => goSignup()}
                >
                  登録・ログイン
                </Button>
                <Box sx={{ height: 20 }}></Box>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => loginDclose()}
                  style={{ width: "70%", height: 55 }}
                >
                  キャンセル
                </Button>
              </Grid>
              <Box sx={{ height: 40 }}></Box>
            </Grid>
          </Dialog>
          <Dialog onClose={logoutDclose} open={logoutD} sx={{ width: "100%" }}>
            <Box sx={{ height: 40 }}></Box>
            <Grid p={2}>
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold" }}
                mt={2}
                mr={2}
                ml={2}
              >
                ログアウトしてもよろしいですか？
              </Typography>
              <Box sx={{ height: 40 }}></Box>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                textAlign={"center"}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#ff7043",
                    color: "white",
                    width: "70%",
                    height: 55,
                  }}
                  onClick={() => logout()}
                >
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    ログアウトする
                  </Typography>
                </Button>
                <Box sx={{ height: 20 }}></Box>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => logoutDclose()}
                  style={{ width: "70%", height: 55 }}
                >
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    キャンセル
                  </Typography>
                </Button>
              </Grid>
              <Box sx={{ height: 40 }}></Box>
            </Grid>
          </Dialog>

          <Dialog
            onClose={() => passtogglereset(false)}
            open={passresetD}
            sx={{ width: "100%" }}
          >
            <Box sx={{ height: 40 }}></Box>
            <Grid p={2}>
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold" }}
                mt={2}
                mr={2}
                ml={2}
              >
                「まめ日記」のパスワードをリセットしました
              </Typography>
              <Box sx={{ height: 40 }}></Box>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                textAlign={"center"}
              >
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => Endreset()}
                  style={{ width: "70%" }}
                >
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    もどる
                  </Typography>
                </Button>
              </Grid>
              <Box sx={{ height: 40 }}></Box>
            </Grid>
          </Dialog>
          <Dialog
            onClose={() => ToggleAccountDeleteComplete(false)}
            open={delete_complete_D}
            sx={{ width: "100%" }}
          >
            <Box sx={{ height: 40 }}></Box>
            <Grid p={2}>
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold" }}
                mt={2}
                mr={2}
                ml={2}
              >
                アカウントを削除しました
              </Typography>
              <Box sx={{ height: 40 }}></Box>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                textAlign={"center"}
              >
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => Endreset()}
                  style={{ width: "70%" }}
                >
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    もどる
                  </Typography>
                </Button>
              </Grid>
              <Box sx={{ height: 40 }}></Box>
            </Grid>
          </Dialog>
        </motion.div>

        <BottomMenu />
      </Grid>
    );
  } else {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress></CircularProgress>
        <BottomMenu />
      </Box>
    );
  }
}
