import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import miyagata from "../../images/miyagata.png";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";

export default function Kamidana() {
  const [state, setState] = useState(false);

  const toggleDrawer = () => {
    setState(!state);
  };

  return (
    <Grid
      container
      textAlign={"left"}
      alignItems="center"
      justifyContent="center"
    >
      <Card sx={{ width: "90%" }}>
        <Typography
          gutterBottom
          component="div"
          sx={{ fontSize: 15, fontWeight: "bold" }}
          m={0.5}
          ml={1}
        >
          無償でお配りしている「簡易宮型」
        </Typography>
        {state && (
          <Grid m={1}>
            <Box mt={2} mb={2}>
              <img src={miyagata} width={"100%"}></img>
            </Box>
            <Typography sx={{ fontSize: 14 }}>
              　日本の総氏神とされます天照大神（あまてらすおおかみ）様のおふだである、「神宮大麻（じんぐうたいま）」をおまつりしてご家庭の平安・生業の発展をお祈りすることができます。
            </Typography>
            <Typography sx={{ mt: 1, fontSize: 14 }}>
              なお「神宮大麻」は地元の神社、または近隣の神社さんで受けることができます。そして年末年始にはあたらしいお力をいただいた「神宮大麻」と交換いたします。
            </Typography>
            <Typography sx={{ mt: 1, fontSize: 14 }}>
              ご希望の方は、授与所(お守りのお配り場所)にてお声がけください。
            </Typography>
          </Grid>
        )}
        {!state && (
          <Grid mt={1} mr={1} ml={1}>
            <Typography sx={{ fontSize: 14 }}>
              置くだけでなく柱や壁に掛けることもできる神棚を無償でお配りしています。
            </Typography>
          </Grid>
        )}
        {state && (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            pr={1}
            pb={1}
          >
            <Button size="small" onClick={() => toggleDrawer()} mb={2}>
              <Typography sx={{ color: "#ffa94d", fontWeight: "bold" }}>
                とじる
              </Typography>
            </Button>
          </Grid>
        )}
        {!state && (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            pr={1}
            pb={1}
          >
            <Button size="small" onClick={() => toggleDrawer()} mb={2}>
              <Typography sx={{ color: "#ffa94d", fontWeight: "bold" }}>
                詳細をみる
              </Typography>
            </Button>
          </Grid>
        )}
      </Card>
    </Grid>
  );
}
